import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { FormLabel } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);
const Question = ({
    name,
    question,
    subtitle,
    value,
    handleChange,
    control,
    inputProps
}) => {
    const classes = useStyles();

    return (
        <GridContainer>
            <GridItem xs={12} sm={12}>
                <h4 className={classes.cardTitle}>{question}</h4>
                {subtitle && (
                    <FormLabel className={classes.labelHorizontal}>
                        {subtitle}
                    </FormLabel>
                )}
            </GridItem>
            <GridItem xs={12} sm={12}>
                <CustomInput
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={inputProps}
                    name={name}
                    showCounter={true}
                    value={value}
                    handleChange={handleChange}
                    control={control}
                />
            </GridItem>
        </GridContainer>
    );
};

export default Question;
