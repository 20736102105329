// eslint-disable prettier/prettier //
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import List from "@material-ui/core/List";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";
import { ListItem } from "@material-ui/core";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
    const classes = useStyles();
    return (
        <div className={classes.services}>
            <GridContainer>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textCenter
                    )}
                >
                    <h2 className={classes.title}>How does it work?</h2>
                    <List>
                        <ListItem>
                            1) You may be assigned to between 1-2 student teams.
                        </ListItem>
                        <ListItem>
                            2) Each team will have between 6-8 students.
                        </ListItem>
                        <ListItem>
                            3) The students are local and international students
                            – from all disciplines and year levels. They range
                            from undergraduate students to Phd students.
                        </ListItem>
                        <ListItem>
                            4) Some students may also be participating from
                            outside Australia and this may impact meeting
                            scheduling and the meeting/communication platforms
                            you and your team use to communicate.
                        </ListItem>
                    </List>
                    <h3 className={classes.title}>
                        How will you be introduced to your teams?
                    </h3>
                    <List>
                        <ListItem>
                            1) We will introduce you to your teams via email
                            approximately 1.5 weeks prior to the start of the
                            Challenge program itself.
                        </ListItem>
                        <ListItem>
                            2) We ask that you get in touch with your team(s) to
                            introduce yourself at the earliest opportunity.
                        </ListItem>
                    </List>
                    <h3 className={classes.title}>
                        How much time should you commit to mentoring?
                    </h3>
                    <List>
                        <ListItem>
                            We ask that you are able to commit between 5 - 7
                            hours of mentoring for the duration of the program.
                            The program will last approximately 2.5 weeks.
                        </ListItem>
                        <ListItem>
                            You may find that you spend most of these mentoring
                            hours when the Challenge proper commences (which is
                            an intense 2.5 week period between July 22nd -
                            August 3rd 2023) as that's when students are most
                            available.
                        </ListItem>
                    </List>
                    <h3 className={classes.title}>
                        Is there a recommended mentoring schedule?
                    </h3>
                    <List>
                        <ListItem>
                            You and your team(s) can decide the best mentoring
                            schedules that work for you but see below for a
                            recommended schedule:
                        </ListItem>
                        <List>
                            <ListItem>
                                1) July 22nd - 29th - Meet at least once during
                                this week and set a project plan in place.
                                Establish your meeting schedule and select your
                                preferred communication platform.
                            </ListItem>
                            <ListItem>
                                2) July 30th - August 3rd Meet once or twice
                                with your team/finalize tasks/prepare for a
                                pitch presentation.
                            </ListItem>
                        </List>
                    </List>
                    <h3 className={classes.title}>
                        How will you communicate with your team?
                    </h3>
                    <List>
                        <ListItem>
                            You and your team will decide amongst yourselves
                            what the preferred platforms and tools are; we leave
                            this up to you to decide.
                        </ListItem>
                        <ListItem>
                            Mentoring can be 100% remote/virtual or include
                            face-to-face meetings. If you do have face-to-face
                            meetings, you are responsible for ensuring Covid-19
                            safety protocols are followed.
                        </ListItem>
                        <ListItem>Platforms to consider include:</ListItem>
                        <List>
                            <ListItem>
                                1) Zoom/Microsoft Teams/Skype/Google Hangouts
                                for meetings
                            </ListItem>
                            <ListItem>
                                2) Slack/Microsoft Teams/Whatsapp/Facebook
                                Messenger for Messaging/Communication
                            </ListItem>
                            <ListItem>
                                3) Google Docs/Trello/Miro/Mural for project
                                management/templates
                            </ListItem>
                            <ListItem>
                                3) Cisco Webex/WeChat are good platforms to use
                                if any of your students are in China
                            </ListItem>
                        </List>
                        <h3 className={classes.title}>
                            What are your responsibilities as a mentor?
                        </h3>
                        <List>
                            <ListItem>
                                1) Your role as a mentor is to guide the
                                students (especially those who may be new to
                                entrepreneurship) and be available for advice.
                                We recommend against "doing the work" for your
                                teams as it takes away from their learning.
                            </ListItem>
                            <ListItem>
                                2) Keep your team(s) focused and on-track and
                                aware of deadlines.
                            </ListItem>
                            <ListItem>
                                3) Meaningfully challenge their ideas and
                                assumptions from your experience.
                            </ListItem>
                            <ListItem>
                                4) Encourage all members of the team to
                                contribute and participate.
                            </ListItem>
                            <ListItem>
                                5) Ensure ideas they come up with are realistic
                                and practical.
                            </ListItem>
                            <ListItem>
                                6) We will ask that you take attendance at any
                                team meetings you attend with your team.
                            </ListItem>
                        </List>
                        <h3 className={classes.title}>
                            Will you get training as a mentor?
                        </h3>
                        <List>
                            <ListItem>
                                You will have access to a mentoring webinar and
                                there will be a virtual mentoring orientation.
                            </ListItem>
                        </List>
                        <h3 className={classes.title}>
                            What challenges can you expect as a mentor?
                        </h3>
                        <List>
                            <ListItem>
                                1) You may find that each team you guide is very
                                different to each other and has a unique team
                                dynamic that you will need to address.
                            </ListItem>
                            <ListItem>
                                2) There will be different levels of engagement
                                from students in teams; with some not as engaged
                                as others.
                            </ListItem>
                            <ListItem>
                                3) Working as a team remotely also is
                                challenging; you can guide the team as to the
                                best ways and tools that work for them to keep
                                communicating and performing virtually.
                            </ListItem>
                            <ListItem>
                                4) For some students, this may be their first
                                experience with entrepreneurship, business and
                                start-ups and there will be varying levels of
                                confidence amongst students; try to encourage
                                participation from everyone.
                            </ListItem>
                        </List>
                        <h3 className={classes.title}>
                            Can mentors attend the summits and presentation
                            opportunities?
                        </h3>
                        <List>
                            <ListItem>
                                Yes you are most welcome to. This is not however
                                compulsory for mentors to attend.
                            </ListItem>
                        </List>
                        <h3 className={classes.title}>
                            Our recommendations for mentors when setting up
                            meetings:
                        </h3>
                        <List>
                            <ListItem>
                                1) Record all meetings so that any team members
                                who missed the meeting can review and catch-up.
                            </ListItem>
                            <ListItem>
                                2) Email an agenda of what you expect from the
                                team at least 24 hours before each meeting so
                                they know to come prepared
                            </ListItem>
                        </List>
                    </List>
                </GridItem>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textCenter
                    )}
                >
                    <h2 className={classes.title}>
                        Innovation Challenge Themes
                    </h2>
                    <h5 className={classes.description}>
                        Here are the
                        <a
                            href="https://docs.google.com/document/d/1XnuHxqN0Xa5qeUyQwi2ssChFE5-OgVbAjKRr1wGQVZU/edit?usp=sharing"
                            target="new window"
                        >
                            {" "}2023 Challenge Themes.{" "}
                        </a>
                    </h5>
                </GridItem>
            </GridContainer>
            <GridContainer>
                {/* <GridItem md={3} sm={12}>
          <InfoArea
            title="1. Building Successful Businesses in Joondalup in the COVID- Safe Economy"
            description={
              <span>
                <p>
                The Joondalup economy has been hit hard by Coronavirus (COVID-19). Gross Regional Product fell by -12.4% in the June Quarter 2020. The top 3 Sectors impacted include: Accommodation and Food Services; Retail Trade and Education and Training. As Australia recovers from the health and economic impact of COVID-19, pandemic-hit businesses in Joondalup must adjust to operating in a completely new environment. In Joondalup, 42% of people work in businesses impacted by COVID-19 & 47% of businesses in the area say they have been impacted by the virus.
{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Gesture}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="2. Skilling the Future Workforce of Joondalup"
            description={
              <span>
                <p>
                  {" "}
                  By 2030, millions of today’s jobs will be lost to automation according to McKinsey Global Institute.  However, there will also be a vast array of new jobs available to university graduates - many related to innovation and digital technologies. Directly due to COVID-19, local Joondalup jobs are forecast to fall by -8.3% in the June Quarter 2020. This equates to a fall of 4,362 local jobs.{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Build}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="3. International Tourism in a post-COVID World"
            description={
              <span>
                <p>
                Tourism is an important economic driver for Western Australia and will play an important role in the State’s recovery post COVID-19. As background, in 2017/2018 there were 2.7 million visitors in the City of Joondalup alone. But only 5.48% of total tourists to Perth, visit the Joondalup region and there is a lot of opportunity to attract more of these visitors. Of the visitors coming to Joondalup, 48.9% are international visitors staying overnight, 38.5% are domestic day trippers and 15.3% are domestic visitors staying overnight. With intra and inter-state borders closed to travellers for much of 2020, visitors to WA’s key tourist hotspots have dropped significantly. 
{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="3. Building Cyber Skills in the Economy"
            description={
              <span>
                <p>
                Strong cyber security is a fundamental element of our growth and prosperity in a global economy. Joondalup has emerged as the hub of cyber security in WA. The Cyber Security cooperative research centre (CRC) was set up in 2018 with $50 million in Federal funding. Joonadalup has a core focus relating to cybersecurity, and this is to encourage and attract students into the region to study in this rapidly evolving field and to be able to support these students with programs that enhance their  employability skills and connect them to future employment.{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem> */}
            </GridContainer>
        </div>
    );
}
