const CaseStudyStyles = {
    summaryContainer: {
        marginBottom: "50px"
    },
    questionContainer: {
        marginTop: "50px"
    }
};

export default CaseStudyStyles;
