import axios from "../axiosInstance";

export const registerUser = user =>
  axios.post("/user/register", user).then(res => res.data);

export const login = credentials =>
  axios.post("/user/login", credentials).then(res => res.data);

export const forgetPassword = data =>
  axios.post("/user/forgotPassword", data).then(res => res.data);

export const resetPassword = (data, token) =>
  axios.post(`/user/resetPassword/${token}`, data).then(res => res.data);
