import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
    const classes = useStyles();
    return (
        <div className={classes.services}>
            <GridContainer>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textCenter
                    )}
                >
                    <h2 className={classes.title}>This is what we do.</h2>
                    <h5 className={classes.description}>
                        Find out a little more about INTERCHANGE.
                    </h5>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={4} sm={4}>
                    <InfoArea
                        title="1. Project Background"
                        description={
                            <span>
                                <p>
                                    In a globally competitive world, many
                                    international students take an investment
                                    approach to studying abroad and the ability
                                    to build employability skills whilst
                                    studying in Australia is considered very
                                    valuable. INTERCHANGE provides a platform
                                    for for international and local students to
                                    develop these crucial employability skills
                                    and networks while studying in Australia.
                                    INTERCHANGE is part of the Work Integrated
                                    Learning journey and provides students a
                                    chance to work on real-world problems in a
                                    fast paced and engaging manner. INTERCHANGE
                                    combats the problem of social isolation as
                                    inter-institute teams work closely together
                                    for a month, making new networks and
                                    friends.{" "}
                                </p>
                                {/* <a href="#pablo">Find more...</a> */}
                            </span>
                        }
                        icon={Gesture}
                        iconColor="warning"
                    />
                </GridItem>
                <GridItem md={4} sm={4}>
                    <InfoArea
                        title="2. Project Outcomes"
                        description={
                            <span>
                                <p>
                                    {" "}
                                    INTERCHANGE enables students to build
                                    employability skills such as teamwork,
                                    communication, problem solving and creative
                                    thinking skills. This program encourages
                                    students to network and build local
                                    Australian and international networks by
                                    exposing them to the NSW start-up and
                                    entrepreneurship eco-system including
                                    incubators and co-working spaces; entirely
                                    virtually. INTERCHANGE additionally improves
                                    the employability skills of international
                                    students including their English language
                                    skills and gives them ‘real-world’
                                    Australian experience.{" "}
                                </p>
                                {/* <a href="#pablo">Find more...</a> */}
                            </span>
                        }
                        icon={Build}
                        iconColor="warning"
                    />
                </GridItem>
                <GridItem md={4} sm={4}>
                    <InfoArea
                        title="3. Project Deliverables"
                        description={
                            <span>
                                <p>
                                    The project deliverables include the
                                    involvement of 150 students as well as peer
                                    advisors and industry mentors, along with the
                                    collaboration of 7 educational institutes .
                                    Students prepare themselves for the future
                                    of work by learning how to rapidly make
                                    decisions and deploy creative, technology
                                    based-solutions. They can build their
                                    confidence and capability in digital
                                    literacy and critical technology skills by
                                    attending In-person and Virtual Summits and
                                    Virtual Master Classes and by preparing
                                    video pitches using the latest technology
                                    platforms.{" "}
                                </p>
                                {/* <a href="#pablo">Find more...</a> */}
                            </span>
                        }
                        icon="mode_edit"
                        iconColor="warning"
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textCenter
                    )}
                >
                    <h2 className={classes.title}>
                        Hear from past INTERCHANGERS
                    </h2>
                    <div
                        style={{
                            display: "inline-block",
                            width: "100%",
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0
                        }}
                    >
                        <iframe
                            src="https://www.youtube.com/embed/2crjtO04FJs"
                            width="100%"
                            height={500}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                        ></iframe>
                    </div>
                </GridItem>
                <GridItem md={6} sm={6}>
                    <div
                        style={{
                            display: "inline-block",
                            width: "100%",
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0
                        }}
                    >
                        <iframe
                            src="https://www.youtube.com/embed/72AvUi4uUqE"
                            width="100%"
                            height={500}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "5px solid black"
                            }}
                        ></iframe>
                    </div>
                </GridItem>
                <GridItem md={6} sm={6}>
                    <div
                        style={{
                            display: "inline-block",
                            width: "100%",
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0
                        }}
                    >
                        <iframe
                            src="https://www.youtube.com/embed/GWwEnpEUwRA"
                            width="100%"
                            height={500}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "5px solid black"
                            }}
                        ></iframe>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}
