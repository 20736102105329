import React from "react";
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";
import { MentorsData } from "./MentorsData";
const useStyles = makeStyles(testimonialsStyle);

export default function MentorPage({ ...rest }) {
    const possibleYears = ["2020", "2021", "2022"];
    const years = possibleYears.includes(rest.match.params.year)
        ? rest.match.params.year
        : 2020;
    const classes = useStyles();
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false
    // };
    return (
        <div className="cd-section" {...rest}>
            <div>
                <Header
                    // brand="Material Kit PRO React"
                    links={<HeaderLinks dropdownHoverColor="info" />}
                    fixed
                    color="info"
                    changeColorOnScroll={{
                        height: 300,
                        color: "info"
                    }}
                />
            </div>
            {/* Testimonials 3 START */}
            <div className={classes.testimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Meet Some of our Amazing {years} Mentors
                            </h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        {MentorsData[years].map((mentor, index) => (
                            <GridItem xs={12} sm={4} md={4} key={index}>
                                <Card testimonial plain>
                                    <CardAvatar testimonial plain>
                                        <img
                                            src={mentor.imageURL}
                                            alt={mentor.name}
                                        />
                                    </CardAvatar>
                                    <CardBody plain>
                                        <h4 className={classes.title}>
                                            {mentor.name}
                                        </h4>
                                        <Muted>
                                            <h6>{mentor.mutedText}</h6>
                                        </Muted>
                                        <h5 className={classes.cardDescription}>
                                            {mentor.description}
                                        </h5>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        ))}
                    </GridContainer>
                </div>
            </div>
            {/* Testimonials 3 END */}
            <Footer
                content={
                    <div>
                        <div className={classes.left}>
                            {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{" "}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/teams" className={classes.block}>
                    Teams
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List> */}
                        </div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} Made by{" "}
                            <a href="http://www.franked.io" target="_blank">
                                franked.io
                            </a>{" "}
                            for a kinder web.
                        </div>
                    </div>
                }
            />
        </div>
    );
}
