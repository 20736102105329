import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/bg7.jpg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ResetPasswordSubmitted } from "./ResetPasswordSubmitted";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgetPassword } from "api/AuthService";

const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

const useStyles = makeStyles(signupPageStyle);

export default function ResetPasswordPage({ ...rest }) {
  const [submitted, setSubmitted] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(resetPasswordSchema)
  });

  const submitResetPasswordForm = async data => {
    try {
      await forgetPassword(data);
      setSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const isFormValid = () => {
    return true;
  };

  const classes = useStyles();
  return (
    <div>
      <Header absolute color="transparent" brand="INTERCHANGE" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">

            {submitted ? (
              <GridItem xs={12} sm={10} md={10}>
                <Card>
                  <ResetPasswordSubmitted />
                </Card>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={5} md={5}>
                <Card>
                  <h3 className={classes.cardTitle}>Reset Password</h3>
                  <p className={classes.textCenter}>Enter your registered email to reset password.</p>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <form
                          onSubmit={handleSubmit(submitResetPasswordForm)}
                          className={classes.form}
                        >
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            name="email"
                            control={control}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "Email"
                            }}
                          />
                          {errors.email?.message && (
                            <span className={classes.labelError}>
                              {errors.email?.message}
                            </span>
                          )}
                          <div className={classes.textCenter}>
                            <Button
                              round
                              color="primary"
                              disabled={!isFormValid()}
                              type="submit"
                            >
                              Submit
                              </Button>
                          </div>

                          <div
                            className={classNames(
                              classes.signInLink,
                              classes.textCenter
                            )}
                          >
                            or{" "}
                            <Link
                              to="/signin"
                              className={classes.textUnderline}
                            >
                              Sign In
                              </Link>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            )}
          </GridContainer>
        </div>

      </div>
    </div>
  );
}
