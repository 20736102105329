import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CASE_STUDY_QUESTIONS } from "../Questions";
import styles from "../CaseStudyStyles";
import { makeStyles } from "@material-ui/core/styles";
import { getCaseStudy } from "api/UserService";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);
export function CaseStudySummary({ caseStudyId, switchMode }) {
    const classes = useStyles();
    const [answerList, setAnswerList] = useState([]);

    const loadCaseStudy = async () => {
        try {
            const answers = await getCaseStudy(caseStudyId);
            const result = [];
            for (let key in answers) {
                result.push(answers[key]);
            }
            setAnswerList(result);
        } catch (error) {}
    };

    useEffect(() => {
        loadCaseStudy();

        // eslint-disable-next-line
    }, []);

    const goBack = () => {
        switchMode("list");
    };

    return (
        <>
            <div className={classes.summaryContainer}>
                <h2>Case Study Summary</h2>

                {answerList.map((item, index) => (
                    <div className={classes.questionContainer} key={index}>
                        <h4>{CASE_STUDY_QUESTIONS[index].title}</h4>
                        <p>{item}</p>
                    </div>
                ))}
            </div>
            <Button onClick={goBack} className={classes.button}>
                Back
            </Button>
        </>
    );
}

CaseStudySummary.propTypes = {
    answers: PropTypes.object
};

export default CaseStudySummary;
