export const TeamsData = {
    2020: [
        {
            id: 1,
            name: "Thought Collective",
            motto: "Change begins with a thought",
            image: require("assets/img/teams/thumbnails/Team 1- Thought Collective.png"),
            url: "https://youtu.be/sMoDbbh-FO4"
        },
        {
            id: 2,
            name: "Interchange 3A",
            motto: "Active Action Alliance",
            image: require("assets/img/teams/thumbnails/Team 2- Interchange 3A.png"),
            url: "https://youtu.be/PmQ2JdLITS0"
        },
        {
            id: 3,
            name: "TEAM HAPPY",
            motto: "Changing The World With Your Smile",
            image: require("assets/img/teams/thumbnails/Team 3 - TEAM HAPPY.png"),
            url: "https://youtu.be/f3_O1q1Gh0Y"
        },
        {
            id: 4,
            name: "Going The (Social) Distance",
            motto: "Connections at 6ft Apart",
            image: require("assets/img/teams/thumbnails/Team 4 - Going The (Social) Distance.png"),
            url: "https://youtu.be/cEYyNK2JSEk"
        },
        {
            id: 5,
            name: "Ecolution",
            motto: "Sustainability is the New Sexy",
            image: require("assets/img/teams/thumbnails/Team 5 - Ecolution.png"),
            url: "https://bit.ly/3aeAzEu"
        },
        {
            id: 6,
            name: "Eco Stability",
            motto: "Connecting, inspiring, saving",
            image: require("assets/img/teams/thumbnails/Team 6 - Ecostability.png"),
            url: "https://youtu.be/KB6_lhYnPZs"
        },
        {
            id: 7,
            name: "WASTE SAVERS",
            motto: "Make Haste Not Waste",
            image: require("assets/img/teams/thumbnails/Team 7 - Waste Savers.png"),
            url: "https://youtu.be/6KmwoyBMR3Q"
        },
        {
            id: 8,
            name: "Quaranteam",
            motto: "Unity ▪︎ Faith ▪︎ Diversity",
            image: require("assets/img/teams/thumbnails/Team 8 - Quaranteam.png"),
            url: "https://youtu.be/tfD_pHTxwcw"
        },
        {
            id: 9,
            name: "FRANKenstein",
            motto: "Beware; for we are fearless, and therefore powerful.",
            image: require("assets/img/teams/thumbnails/Team 9 - FRANKenstein.png"),
            url: "https://youtu.be/DqgTROrIHtE"
        },
        {
            id: 10,
            name: "LOGOS",
            motto: "Future loading...",
            image: require("assets/img/teams/thumbnails/Team 10 - Logos.png"),
            url: "https://bit.ly/2CkNvfM"
        },
        {
            id: 11,
            name: "Carbon Switch",
            motto: "Change yourself to change the world",
            image: require("assets/img/teams/thumbnails/Team 11 - Carbon Switch.png"),
            url: "https://youtu.be/Til3C5fKeqE"
        },
        {
            id: 12,
            name: "Mini Minds",
            motto: "Unlock your superpower",
            image: require("assets/img/teams/thumbnails/Team 12 - Mini Minds.png"),
            url: "https://www.youtube.com/watch?v=1po0XexhY3I"
        },
        {
            id: 13,
            name: "Intertwined",
            motto: "Connected solutions for a sustainable future",
            image: require("assets/img/teams/thumbnails/Team 13 - Intertwined.png"),
            url: "https://bit.ly/3gLkXLr"
        },
        {
            id: 14,
            name: "Soap House",
            motto: "Sustainably Made",
            image: require("assets/img/teams/thumbnails/Team 14 - Soap House.png"),
            url: "https://youtu.be/r1V6T3z5sGY"
        },
        {
            id: 15,
            name: "Wizards of Oz",
            motto: "Agents of Change for the Future",
            image: require("assets/img/teams/thumbnails/Team 15 - Wizards of Oz.png"),
            url: "https://youtu.be/DFCoeUrUGBE"
        },
        {
            id: 16,
            name: "Savage Not Average",
            motto: "",
            image: require("assets/img/teams/thumbnails/Team 16 - Savage Not Average.png"),
            url: "https://youtu.be/Ril4U-qlBmg"
        },
        {
            id: 17,
            name: "4Sydney",
            motto: "The day after tomorrow",
            image: require("assets/img/teams/thumbnails/Team 17 - 4Sydney.png"),
            url: "https://youtu.be/gXELrRxJAhA"
        },
        {
            id: 18,
            name: "GreenTech",
            motto: "Interact, Interchange, Integrate.",
            image: require("assets/img/teams/thumbnails/Team 18 - GreenTech.png"),
            url: "https://youtu.be/UE5jRdFNBDg"
        },
        {
            id: 19,
            name: "UNI-ty",
            motto: "Let's do this!",
            image: require("assets/img/teams/thumbnails/Team 19 - UNI-ty.png"),
            url: "https://youtu.be/2miH0tLrEKo"
        },
        {
            id: 20,
            name: "Team 20 - Orcas 2020",
            motto: "We are diverse, Yet we are one",
            image: require("assets/img/teams/thumbnails/Team 20 - Orcas 2020.png"),
            url: "https://youtu.be/09-Qe2Senxo"
        },
        {
            id: 21,
            name: "The Innov8ors",
            motto: "Create to Innovate",
            image: require("assets/img/teams/thumbnails/Team 21 - The Innov8ors.png"),
            url: "https://youtu.be/ByZtRItMqa8"
        },
        {
            id: 22,
            name: "Orana",
            motto: "Caring for the past, crafting the future",
            image: require("assets/img/teams/thumbnails/Team 22 - Orana.png"),
            url: "https://youtu.be/BQMa5oUIpM0"
        },
        {
            id: 23,
            name: "Alpha Centauri Co.",
            motto: "Reach for the Stars",
            image: require("assets/img/teams/thumbnails/Team 23 - Alpha Centauri Co..png"),
            url: "https://youtu.be/FeZWp5dbfks"
        },
        {
            id: 24,
            name: "upSKILL",
            motto: "Connect. Collaborate. Recruit",
            image: require("assets/img/teams/thumbnails/Team 24 - upskill.png"),
            url: "https://youtu.be/7QoTUD0L1oU"
        },
        {
            id: 25,
            name: "Smart Farmers",
            motto: "Be in Control",
            image: require("assets/img/teams/thumbnails/Team 25 - Smart Farmers.png"),
            url: "https://youtu.be/ZLpasylWbIY"
        },
        {
            id: 26,
            name: "Eleos",
            motto: "Good vibes",
            image: require("assets/img/teams/thumbnails/Team 26 - Eleos.png"),
            url: "https://youtu.be/ZGmzHcOeMQQ"
        },
        {
            id: 27,
            name: "Beacon",
            motto: "Illuminating the paths of all youth. ",
            image: require("assets/img/teams/thumbnails/Team 27 - Beacon.png"),
            url: "https://www.youtube.com/watch?v=pxGLBwxzXfg"
        },
        {
            id: 28,
            name: "CELLVATION",
            motto: "Helping the planet one cell at a time",
            image: require("assets/img/teams/thumbnails/Team 28 - CELLVATION.png"),
            url: "https://bit.ly/3gGQkGR"
        },
        {
            id: 29,
            name: "NUCATE",
            motto: "Smart Profession forever",
            image: require("assets/img/teams/thumbnails/Team 29 - NUCATE.png"),
            url: "https://youtu.be/CswrRhBGDBE"
        },
        {
            id: 30,
            name: "Innovation Geeks",
            motto: "The whole is greater than the sum of its parts",
            image: require("assets/img/teams/thumbnails/Team 30 - Innovation Geeks.png"),
            url: "https://www.youtube.com/watch?v=rzJR-F_vfuk&feature=youtu.be"
        },
        {
            id: 31,
            name: "Easy Traffic",
            motto: "Smart solution to your daily routine",
            image: require("assets/img/teams/thumbnails/Team 31 - Easy Traffic.png"),
            url: "https://www.youtube.com/watch?v=-IGKMicoOKI"
        },
        {
            id: 32,
            name: "Hi-Tech",
            motto: "Tech in touch with tomorrow",
            image: require("assets/img/teams/thumbnails/Team 32 - Hi-Tech.png"),
            url: "https://youtu.be/pFhOOZJXixQ"
        },
        {
            id: 33,
            name: "Vikings' Voyage ",
            motto: "Veni, vidi, vici",
            image: require("assets/img/teams/thumbnails/Team 33 - Vikings_Voyage.png"),
            url: "https://youtu.be/vnKGMdSvTTY"
        },
        {
            id: 34,
            name: "Interchange Explorers",
            motto: "A clear vision for a better tomorrow",
            image: require("assets/img/teams/thumbnails/Team 34 - Interchange Explorers.png"),
            url: "https://www.youtube.com/watch?v=jd5-2e1JjC0&feature=youtu.be"
        },
        {
            id: 35,
            name: "The Hive",
            motto: "Grow. Build. Thrive",
            image: require("assets/img/teams/thumbnails/Team 35 - The Hive.png"),
            url: "https://youtu.be/4Ck9eCmM3nQ"
        },
        {
            id: 36,
            name: "The Pathfinders",
            motto: "Leading the way forward",
            image: require("assets/img/teams/thumbnails/Team 36 - The Pathfinders.png"),
            url: "https://youtu.be/KPtk0DlWZRc"
        },
        {
            id: 37,
            name: "Full of Food",
            motto: "Make a ‘full-filling’ difference in people’s lives",
            image: require("assets/img/teams/thumbnails/Team 37 - Full of Food.png"),
            url:
                "https://youtu.be/bSwCA-qWZ3Q?fbclid=IwAR2F0qUXXXVKFtoQDwibkRDRD8HNkHFQZRY6E8P6lAUgCUPGDANbGrVT8t4"
        },
        {
            id: 38,
            name: "Cre8",
            motto: "Rethink the impossible - I’m possible",
            image: require("assets/img/teams/thumbnails/Team 38 - Cre8.png"),
            url: "https://youtu.be/ixpWoB2tlxo"
        },
        {
            id: 39,
            name: "Route 39",
            motto: "Teamwork Makes the Dream Work",
            image: require("assets/img/teams/thumbnails/Team 39 - Route 39.png"),
            url: "https://youtu.be/m36WF0cK37c"
        },
        {
            id: 40,
            name: "HexaEfficient",
            motto: "Schedule your dreams today",
            image: require("assets/img/teams/thumbnails/Team 40 - HexaEfficient.png"),
            url: "https://youtu.be/s_wLPoWoRug"
        },
        {
            id: 41,
            name: "Surfer 6",
            motto: "Just keep surfing",
            image: require("assets/img/teams/thumbnails/Team 41 - Surfer 6.png"),
            url: "https://youtu.be/BbhxGTLaQk4"
        },
        {
            id: 42,
            name: "Our Place",
            motto: "A Better Earth",
            image: require("assets/img/teams/thumbnails/Team 42 - Our Place.png"),
            url: "https://www.youtube.com/watch?v=0TVas6SWdro&rel=0"
        }
    ],
    2021: [
        {
            id: 19,
            name: "COULOMB",
            motto: "2021 WINNER",
            image: require("assets/img/teams/thumbnails/Team 19 - COULOMB.jpg"),
            url: "https://www.youtube.com/watch?v=_5Oek456u40"
        },
        {
            id: 29,
            name: "Rafiki",
            motto: "2021 1ST RUNNER UP",
            image: require("assets/img/teams/thumbnails/Team 29 - Rafiki.png"),
            url: "https://youtu.be/uD9l5AZEm0o"
        },
        {
            id: 9,
            name: "OZistant",
            motto: "2021 2ND RUNNER UP",
            image: require("assets/img/teams/thumbnails/Team 9 - OZistant.png"),
            url: "https://youtu.be/DLwy4bLZYME"
        },
        {
            id: 11,
            name: "Comrades",
            motto: "Top 6",
            image: require("assets/img/teams/thumbnails/Team 11 - Comrades.jpg"),
            url: "https://youtu.be/WFpVO92BDVU"
        },
        {
            id: 13,
            name: "EatUp",
            motto: "Top 6",
            image: require("assets/img/teams/thumbnails/Team 13 - Eatup.png"),
            url: "https://www.youtube.com/watch?v=sYuUhF38yMc"
        },
        {
            id: 34,
            name: "Cuppa Grounds",
            motto: "Top 6",
            image: require("assets/img/teams/thumbnails/Team 34 - Cuppa Grounds.png"),
            url: "https://www.youtube.com/watch?v=rtuqNcLBJxg"
        },
        {
            id: 1,
            name: "Novus",
            image: require("assets/img/teams/thumbnails/Team 1 - Novus.jpeg"),
            url: "https://youtu.be/CEaqPUYk0mY"
        },
        {
            id: 2,
            name: "WomenConnect",
            image: require("assets/img/teams/thumbnails/Team 2 - Women Connect.jpg"),
            url: "https://www.youtube.com/watch?v=-3Ts7ond2mw"
        },
        {
            id: 3,
            name: "The Green Junkyard",
            image: require("assets/img/teams/thumbnails/Team 3 - The Green Junkyard.jpeg"),
            url: "https://www.youtube.com/watch?v=PiXmq_ldyJc"
        },
        {
            id: 4,
            name: "6+1= +∞",
            image: require("assets/img/teams/thumbnails/Team 4.png"),
            url: "https://www.youtube.com/watch?v=48ovAo92kqw"
        },
        {
            id: 5,
            name: "Breakthrough",
            image: require("assets/img/teams/thumbnails/Team 5 - Breakthrough.jpeg"),
            url: "https://youtu.be/0aUf2AAlo5o"
        },
        {
            id: 6,
            name: "Mate Gate",
            image: require("assets/img/teams/thumbnails/Team 6 - Mate Gate.png"),
            url: "https://www.youtube.com/watch?v=nxIPTktmaO0"
        },
        {
            id: 7,
            name: "Furiously United",
            image: require("assets/img/teams/thumbnails/Team 7 - Furiously United.PNG"),
            url: "https://youtu.be/iF3It-kp8SU"
        },

        {
            id: 10,
            name: "TechnoPack",
            image: require("assets/img/teams/thumbnails/Team 10 - TechnoPack.jpeg"),
            url: "https://www.youtube.com/watch?v=I17jwB0BvY4"
        },
        {
            id: 12,
            name: "Herotech",
            image: require("assets/img/teams/thumbnails/Team 12 - Herotech.png"),
            url: "https://youtu.be/ZFGU0Y3ozX8"
        },
        {
            id: 14,
            name: "Workspace Clinic",
            image: require("assets/img/teams/thumbnails/Team 14 - Workspace Clinic.png"),
            url: "https://www.youtube.com/watch?v=PNy65QQlR5w"
        },
        {
            id: 16,
            name: "Move 78",
            image: require("assets/img/teams/thumbnails/Team 16 - Move 78.png"),
            url: "https://youtu.be/9rZFGUdMGuc"
        },
        {
            id: 17,
            name: "Spiral",
            image: require("assets/img/teams/thumbnails/Team 17 - Spiral.jpg"),
            url: "https://www.youtube.com/watch?v=viwV1TOVtkU"
        },
        {
            id: 18,
            name: "The Collaborators",
            image: require("assets/img/teams/thumbnails/Team 18 - The Collaborators.png"),
            url: "https://youtu.be/H_xLLpIcjNw"
        },
        {
            id: 20,
            name: "Goal Diggers",
            image: require("assets/img/teams/thumbnails/Team 20 - Goal Diggers.png"),
            url: "https://www.powtoon.com/ws/cCKDRhPE6eU/"
        },
        {
            id: 21,
            name: "Health Guardians",
            image: require("assets/img/teams/thumbnails/Team 21 - Health Guardians.png"),
            url: "https://youtu.be/HCdrDOn0n-c"
        },
        {
            id: 22,
            name: "The Elite Group",
            image: require("assets/img/teams/thumbnails/Team 22 - The Elite Group.jpg"),
            url: "https://youtu.be/45HGFfEcCb8"
        },
        {
            id: 23,
            name: "Unmask",
            image: require("assets/img/teams/thumbnails/Team 23 - Unmask.png"),
            url: "https://www.youtube.com/watch?v=ODSVQVnyqig"
        },
        {
            id: 25,
            name: "Aquagreen",
            image: require("assets/img/teams/thumbnails/Team 25 - Aquagreen.png"),
            url: "https://www.youtube.com/watch?v=gxZ8-AAdLfs"
        },
        {
            id: 26,
            name: "Green Nextdoor",
            image: require("assets/img/teams/thumbnails/Team 26 - Green Nextdoor.JPG"),
            url: "https://www.youtube.com/watch?v=Rc_IOu3RTQ0"
        },
        {
            id: 27,
            name: "IGNITE",
            image: require("assets/img/teams/thumbnails/Team 27 - IGNITE.gif"),
            url: "https://www.youtube.com/watch?v=Egzci3XigUQ"
        },
        {
            id: 28,
            name: "Recycle4MarineLife",
            image: require("assets/img/teams/thumbnails/Team 28 - Recycle4MarineLife.png"),
            url: "https://www.youtube.com/watch?v=_RZrZZXkPa0"
        },

        {
            id: 30,
            name: "Infinity",
            image: require("assets/img/teams/thumbnails/Team 30 - Infinity.png"),
            url: "https://youtu.be/zbuP_zjsxyM"
        },
        {
            id: 31,
            name: "IngredAble",
            image: require("assets/img/teams/thumbnails/Team 31 - IngredAble.png"),
            url:
                "https://www.youtube.com/watch?v=H5_jmUCxUe0&ab_channel=AlishaN"
        },
        {
            id: 32,
            name: "Smart Jams",
            image: require("assets/img/teams/thumbnails/Team 32 - SmartJams.jpg"),
            url: "https://youtu.be/Z_SPtKR5sKg"
        },
        {
            id: 33,
            name: "Future Connect",
            image: require("assets/img/teams/thumbnails/Team 33 - Future Connect.jpg"),
            url: "https://www.youtube.com/watch?v=f5OHx_Pmjyw"
        }
    ],
    2022: [
        {
            motto: "1st place",
            id: 17,
            name: "Wake me Up",
            url: "https://youtu.be/vq91KK9wJiI",
            image: require("assets/img/teams/thumbnails-2022/Team 17 Wake Me Up Team Logo - Igor Ferreira.png")
        },
        {
            motto: "2nd Place",
            id: 7,
            name: "Keeper AI",
            url: "https://www.youtube.com/watch?v=8t9JGhLxXbY",
            image: require("assets/img/teams/thumbnails-2022/Team 7 Keeper AI Logo - Naemat Brar.png")
        },
        {
            motto: "3rd place",
            id: 26,
            name: "Smart Sydney",
            url: "https://youtu.be/xI94mWAVVbg",
            image: require("assets/img/teams/thumbnails-2022/Team 26 Smart Sydney 8D005FBE-EAB5-4E49-A309-4E775B445D82 - Pavyashneha Muralitharan.jpeg")
        },
        {
            motto: "finalist",
            id: 2,
            name: "Womies",
            url: "https://www.youtube.com/watch?v=J-UHxkm0mOI&feature=youtu.be",
            image: require("assets/img/teams/thumbnails-2022/Team 2 Womies - Sheersh Chetal.jpg")
        },
        {
            motto: "finalist",
            id: 27,
            name: "Pi",
            url: "https://youtu.be/JN-91D8enM8",
            image: require("assets/img/teams/thumbnails-2022/Team 27 Pi 289099339_1320072415483443_1106134415495793056_n - Jerica Cabanit.jpg")
        },
        {
            motto: "finalist",
            id: 28,
            name: "Gaia",
            url: "https://www.youtube.com/watch?v=Sb4e-gJrEKw",
            image: require("assets/img/teams/thumbnails-2022/Team 28 Gaia - Tanya Mudge.png")
        },
        {
            motto: "",
            id: 1,
            name: "WELLEE",
            url: "https://youtu.be/m0uudCHBgRI",
            image: require("assets/img/teams/thumbnails-2022/Team 1 Wellee  Eric Zhang.png")
        },
        {
            motto: "",
            id: 4,
            name: "Refashion",
            url: "https://youtu.be/wTB8_zQIwUo",
            image: require("assets/img/teams/thumbnails-2022/Team 4 Refashion - Udinov Alexandra.png")
        },
        {
            motto: "",
            id: 6,
            name: "EcoSix",
            url: "https://www.youtube.com/watch?v=kW8EL6S7pjQ",
            image: require("assets/img/teams/thumbnails-2022/Team 6 EcoSix Logo - Akeem Perry.png")
        },
        {
            motto: "",
            id: 9,
            name: "Hirec",
            url: "https://youtu.be/pRcCgKBp1zg",
            image: require("assets/img/teams/thumbnails-2022/Team 9 Logo - Srika Shah.png")
        },
        {
            motto: "",
            id: 13,
            name: "Team 13",
            url: "https://youtu.be/OfpWbMmk6AI",
            image: require("assets/img/teams/thumbnails-2022/Team 13 logo - Frances Morris.png")
        },
        {
            motto: "",
            id: 14,
            name: "CLINK ",
            url: "https://youtu.be/oITTpnLzP7E",
            image: require("assets/img/teams/thumbnails-2022/Team 14 Logo - Zhi Zhu.jpeg")
        },
        {
            motto: "",
            id: 15,
            name: "Gone Virtual",
            url: "https://youtu.be/QIA_H_ONrdU",
            image: require("assets/img/teams/thumbnails-2022/Team 15 Gone Virtual Logo - Cecilia Ruiz Davila.png")
        },
        {
            motto: "",
            id: 16,
            name: "Friend^Friend",
            url: "https://youtu.be/TxLg3We6P44",
            image: require("assets/img/teams/thumbnails-2022/Team 16 Friend 截屏2022-07-14 上午11.08.50 - T Go.png")
        },
        {
            motto: "",
            id: 18,
            name: "Stranger Thinks - [re]chaping the world",
            url: "https://youtu.be/oJ2HjnGqFms",
            image: require("assets/img/teams/thumbnails-2022/Team 18 Stranger Thinks -logos_transparent - Caio Portella.png")
        },
        {
            motto: "",
            id: 19,
            name: "FOOD2U",
            url: "https://www.youtube.com/watch?v=bJBmJfhZ_YI&authuser=0",
            image: require("assets/img/teams/thumbnails-2022/Team 19 LOGO - Ivy Xu.jpg")
        },
        {
            motto: "",
            id: 20,
            name: "Whats Next?",
            url: "https://www.youtube.com/watch?v=BVRlUy9cn6A",
            image: require("assets/img/teams/thumbnails-2022/Team 20 What Next_ - Antonia Amato.png")
        },
        {
            motto: "",
            id: 21,
            name: "BirthCo",
            url: "https://www.youtube.com/watch?v=bTAedFZIoxU",
            image: require("assets/img/teams/thumbnails-2022/Team 21 Birthco - Alvina Hasty.png")
        },
        {
            motto: "",
            id: 22,
            name: "Upzee",
            url: "https://youtu.be/tAq89DJJWAg",
            image: require("assets/img/teams/thumbnails-2022/Team 22 Upzee Interchange Logo - Ankit Singh.png")
        },
        {
            motto: "",
            id: 23,
            name: "Environ",
            url: "https://youtu.be/Arhf46b8fZ8",
            image: require("assets/img/teams/thumbnails-2022/Team 23 Environ LOGO - Zac Zhuo.png")
        },
        {
            motto: "",
            id: 25,
            name: "Octopus",
            url: "https://youtu.be/DVp1zsjveRY",
            image: require("assets/img/teams/thumbnails-2022/Team 25 - Octopus - Boon Mys.png")
        },
        {
            motto: "",
            id: 29,
            name: "Fusion cuisine",
            url: "https://www.youtube.com/watch?v=1KPwhUcPGFM",
            image: require("assets/img/teams/thumbnails-2022/Team 29 Fusion Cuisine 1210748763 - Haopeng Lu.jpg")
        },
        {
            motto: "",
            id: 30,
            name: "Multi Buddies",
            url: "https://www.youtube.com/watch?v=BUtnu6Haf2I",
            image: require("assets/img/teams/thumbnails-2022/Team 30 - Logo - Đăng Khoa Lê.jpeg")
        },
        {
            motto: "",
            id: 31,
            name: "Waste Matters",
            url: "https://youtu.be/mtDRkBWV0LM ",
            image: require("assets/img/teams/thumbnails-2022/Team 31 Waste Matters - krisha sakhrani.png")
        },
        {
            motto: "",
            id: 33,
            name: "Powered Up",
            url: "https://youtu.be/u_D-N3FZNdg",
            image: require("assets/img/teams/thumbnails-2022/Team 33 Powered Up da2fe205-a9a3-4eae-9f3c-031649ea0fdc - Eva Lees.JPG")
        },
        {
            motto: "",
            id: 34,
            name: "Metropolitan Dynamics",
            url: "https://youtu.be/GqCx4KNrx4w",
            image: require("assets/img/teams/thumbnails-2022/Team 34 Metropolitan Dynamics.png")
        }
    ]
};
