import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Quote from "components/Typography/Quote.js";
import PropTypes from "prop-types";
// core components
import "./SectionText.scss";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";
const HtmlToReactParser = require("html-to-react").Parser;
const useStyles = makeStyles(sectionTextStyle);
const htmlToReactParser = new HtmlToReactParser();

export default function SectionText(props) {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  const { title, paragraphs, quote, author } = props;
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>{title}</h2>
          <div>
            <div className="image-text-wrapper">
              <div className="image-container">
                <img
                  src={props.authorImage}
                  alt="..."
                  className={imgClasses}
                  style={{ flex: 1 }}
                />
              </div>

              <p>
                {paragraphs.map((paragraph, index) => {
                  return (
                    <span key={index}>
                      {htmlToReactParser.parse(paragraph)}
                      <br />
                      <br />
                    </span>
                  );
                })}
              </p>
            </div>
          </div>
          <br />
          <Quote
            textClassName={classes.quoteText}
            text={`${quote}`}
            author={author}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

SectionText.propTypes = {
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorImage: PropTypes.any.isRequired
};
