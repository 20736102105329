import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import MentorAmy from "assets/img/faces/Mentors/MentorAmy.jpg";
import MentorJohn from "assets/img/faces/Mentors/MentorJohn.jpg";
import MentorMary from "assets/img/faces/Mentors/MentorMary.jpg";
import MentorNatalie from "assets/img/faces/Mentors/MentorNatalie.jpg";
import MentorTrevor from "assets/img/faces/Mentors/MentorTrevor.jpg";
import MentorDiego from "assets/img/faces/Mentors/MentorDiego.jpg";
import MentorClaire from "assets/img/faces/Mentors/MentorClaire.png";
import MentorFilipa from "assets/img/faces/Mentors/MentorFilipa.png";
// import MentorJane from "assets/img/faces/Mentors/MentorJane.jpeg";
import MentorAoife from "assets/img/faces/Mentors/MentorAoife.png";
import MentorCheryl from "assets/img/faces/Mentors/MentorCheryl.jpg";
import MentorJulia from "assets/img/faces/Mentors/MentorJulia.jpg";
import MentorDanielle from "assets/img/faces/Mentors/MentorDanielle.jpg";
import MentorHannah from "assets/img/faces/Mentors/MentorHannah.jpg";
// import MentorAvatar from "assets/img/faces/Mentors/MentorAvatar.png";
// import MentorMaleAvatar from "assets/img/faces/Mentors/MentorMaleAvatar.jpeg";
import MentorJacqueline from "assets/img/faces/Mentors/MentorJacqueline.jpg";
import MentorBetty from "assets/img/faces/Mentors/MentorBetty.jpg";
import MentorJane from "assets/img/faces/Mentors/MentorJane.jpg";
import MentorSheilah from "assets/img/faces/Mentors/MentorSheilah.jpg";
import MentorLisa from "assets/img/faces/Mentors/MentorLisa.jpg";
import MentorEmily from "assets/img/faces/Mentors/MentorEmily.jpg";
import MentorLiam from "assets/img/faces/Mentors/MentorLiam.jpg";

const useStyles = makeStyles(testimonialsStyle);

export default function Mentors2021Page({ ...rest }) {
    const classes = useStyles();
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false
    // };
    return (
        <div className="cd-section" {...rest}>
            <div>
                <Header
                    // brand="Material Kit PRO React"
                    links={<HeaderLinks dropdownHoverColor="info" />}
                    fixed
                    color="info"
                    changeColorOnScroll={{
                        height: 300,
                        color: "info"
                    }}
                />
            </div>
            {/* Testimonials 3 START */}
            <div className={classes.testimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Meet Some of our Amazing 2021 Mentors
                            </h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorSheilah} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Sheilah Hogan Casey
                                    </h4>
                                    <Muted>
                                        <h6>Founder @ BX Collective</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Sheliah has a global career spanning
                                        20+ years owning and running businesses
                                        in the US, UK and Australia. She was
                                        Ernst & Young Entrepreneur of the Year
                                        Award Winner in 2007. Her extensive
                                        experience and passions include
                                        coaching, mentoring and consulting with
                                        business leaders on clarifying,
                                        simplifying and achieving their vision,
                                        identifying greatest growth
                                        opportunities and helping companies
                                        realise the ROI of Customer Experience
                                        Excellence.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorAmy} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Amy Cheung
                                    </h4>
                                    <Muted>
                                        <h6>CEO @ HALLS GLOBAL</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Amy is an experienced senior
                                        executive with extensive experiences in
                                        Innovation, AI, IoT, Strategic
                                        Management, Digital Marketing, Strategic
                                        Sales, Channel Development, Change
                                        Management, Business Transformation,
                                        Entrepreneurship, Communications,
                                        Strategic Partnerships and General
                                        Management. Amy co-founded Halls Global
                                        Limited and Con Moto, which focuses on
                                        helping start-ups, entrepreneurs and
                                        businesses grow in the digital age
                                        through the latest digital strategies,
                                        business growth strategies, change
                                        management methodology and university
                                        validated communication strategies.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorDiego} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Diego Trigo
                                    </h4>
                                    <Muted>
                                        <h6>FOUNDER @ HELLO AGAIN</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Diego has worked as CTO, Managing
                                        Partner, Founder, Technical Director,
                                        Coder and always as problem solver. He's
                                        had the opportunity to apply his
                                        expertise to iconic brands such as Audi,
                                        Telstra, eBay, Google, VW, Telstra,
                                        Virgin for the past 10 years working in
                                        Melbourne and Sydney. He has always
                                        enjoyed building and leading teams,
                                        creating new businesses in uncharted
                                        territories and working along-side
                                        founders and entrepreneurs, advising on
                                        technology strategy, ideation and
                                        product roadmap for start-ups, scale-ups
                                        or established business.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorClaire} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Claire Bath
                                    </h4>
                                    <Muted>
                                        <h6>IT EXECUTIVE</h6>
                                        {'"'}
                                        <h6>
                                            WOMAN LEADING TECH 2020 FINALIST
                                        </h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Claire Bath is a high-calibre female
                                        leader in the tech industry, uniquely
                                        based in regional Australia. She is
                                        analytical, encouraging, forward
                                        thinking, and a superior coach and
                                        mentor. An early adopter of change, she
                                        worked remotely before it became
                                        mainstream; negotiated part time hours
                                        from full time positions. She has
                                        inspired business leaders, IT graduates,
                                        trainees, GIS specialists, project
                                        managers, mid-tier IT leaders,
                                        programmers, analysts, operations staff
                                        of all ages, genders and backgrounds.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}Claire has maintained her networks
                                        through attending industry mentoring
                                        programs and continues to give back and
                                        coach others. She always kept abreast of
                                        industry developments, has a passion for
                                        continuous learning, offering
                                        opportunities to her staff and
                                        professional excellence. Following her
                                        passion as a mentor she joined The
                                        Opportunity Collective to inspire local
                                        women to reach their potential through
                                        the Hunter Women’s Leadership Program.
                                        Claire was a finalist in the 2020 B&T
                                        Women leading Tech awards under the
                                        mentor category
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorLisa} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Lisa Penson
                                    </h4>
                                    <Muted>
                                        <h6>BUSINESS EDUCATOR & FACILITATOR</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}After a successful career in
                                        business development both locally and
                                        internationally, Lisa decided to leave
                                        the corporate world to launch her own
                                        business - Solopreneur Success. She
                                        works with micro and small business
                                        owners (Solopreneurs) and helps them
                                        develop the skills, systems, foundation
                                        and strategies to build a successful
                                        business they love. She is also an
                                        advisor for NSW Department of Industry’s
                                        Business Connect advisory service,
                                        funded by the NSW Government.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorFilipa} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Filipa Araujo
                                    </h4>
                                    <Muted>
                                        <h6>PROGRAM DIRECTOR @ BLUECHILLI</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Filipa is a strong advocate for
                                        women tech founders, entrepreneurship as
                                        a development driver and improving youth
                                        employment opportunities. Her work
                                        focuses on supporting entrepreneurs as
                                        they start and scale their companies and
                                        increasing diversity and inclusivity in
                                        startups.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}Prior to BlueChilli, Filipa worked
                                        with founders and startups in Europe,
                                        Africa, and Southeast Asia. She ran the
                                        first country-wide startup competition
                                        in Guinea Bissau, funded by the World
                                        Bank, training over 500 young
                                        entrepreneurs.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}Filipa is an international
                                        development and entrepreneurship
                                        consultant, a mentor for the DFAT-funded
                                        Women Leadership Initiative, a jury for
                                        the Cartier Women's Initiative, a
                                        business coach for Global Sisters, and
                                        pitch coach for various incubators and
                                        accelerators in Australia and New
                                        Zealand. She holds a bachelor’s degree
                                        in Economics and a double Master's in
                                        International Business and Social
                                        Entrepreneurship from CEMS and Nova
                                        School of Business and Economics.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorJane} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Jane Laverty
                                    </h4>
                                    <Muted>
                                        <h6>
                                            REGIONAL MANAGER @ NSW BUSINESS
                                            CHAMBER
                                        </h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Jane Laverty is a self-confessed
                                        regionalist with a personal mission and
                                        passion for sustainable economic
                                        development and meaningful business and
                                        community engagement. Based in the
                                        Northern Rivers of NSW, Jane is the
                                        Regional Manager for Business NSW, the
                                        peak body for business. With a depth of
                                        experience working in professional
                                        services marketing, economic
                                        development, strategic planning and
                                        engagement and a passion for making
                                        things happen she is best described as a
                                        blank page specialist. Jane advocates on
                                        behalf of regional communities, business
                                        and industry for improved business
                                        conditions and works closely with local
                                        business leaders and all levels of
                                        government to create jobs and attract
                                        regional investment.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorAoife} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Aoife O'Connell
                                    </h4>
                                    <Muted>
                                        <h6>FOUNDER & YARNLY AI</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        Aoife O’Connell is the Founder &
                                        Director of Yarnly.AI leading the AI
                                        smart team (an award-winning Chatbot &
                                        Voice Agency) and the COO of Women In
                                        Voice a non for profit built to
                                        celebrate, amplify, and empower women
                                        and diverse people in the voice
                                        technology field. My fuel is to inspire,
                                        encourage, support women on re-entering
                                        the workforce and have a career in the
                                        future of technology.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorBetty} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Betty Andrews
                                    </h4>
                                    <Muted>
                                        <h6>FOUNDER @ HUSTLE & HEART</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        Having found her big purpose in life,
                                        Betty now helps other women not only
                                        find their purpose, but create their
                                        dream lives and businesses. Using data
                                        and strategy, she loves to help people
                                        create more meaning for themselves. With
                                        15 years experience in marketing,
                                        creative and brand strategy, Betty has
                                        worked on marketing campaigns for
                                        Destination New South Wales, LVMH, ASUS,
                                        Peugeot just to name a few. She is also
                                        currently the Expert in Residence at
                                        Fishburners, one of Australia's best
                                        tech startup community.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorJohn} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        John Groarke
                                    </h4>
                                    <Muted>
                                        <h6>CONSULTANT</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        John Groarke is a mentor to Consultants
                                        and Services-Centric enterprises,
                                        creating relevant, distinctive, focused,
                                        sustainable & profitable enterprises
                                        since 2001. John is also a mentor to
                                        Early Stage Start-Ups, shaping their
                                        prosperous futures since 2006.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        And besides mentoring for INTERCHANGE,
                                        he mentors for the CSIRO's ON Prime
                                        program, the Small Business Mentoring
                                        Service, The University of Sydney) and
                                        The University of Sydney Business School
                                        - Genesis Program.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorCheryl} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Cheryl Royle
                                    </h4>
                                    <Muted>
                                        <h6>
                                            ENTREPRENEURSHIP FACILITATOR @ FINER
                                            LINE
                                        </h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        Cheryl is a Hunter-based business coach,
                                        educator, consultant, and founder of The
                                        Finer Line, eMindshift Learning and
                                        Chair of the Young Business Minds. She
                                        has owned and operated six businesses
                                        over the past 25 years and understands
                                        the challenges of starting, growing and
                                        exiting a business. As an educator, she
                                        has supported and coached 100’s of
                                        business owners, freelancers,
                                        consultants, artists and entrepreneurs
                                        across Australia. Cheryl is committed to
                                        helping the small business owners and
                                        entrepreneurs unlock their potential,
                                        develop an entrepreneurial mindset and
                                        create the business of their dreams. For
                                        the past 5 years Cheryl has served as
                                        the Hunter Entrepreneurship Facilitator,
                                        a role with the aim to inspire, educate,
                                        mentor, and connect future entrepreneurs
                                        to start or build a sustainable
                                        business.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorJacqueline} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Jacqueline Garrett
                                    </h4>
                                    <Muted>
                                        <h6>FOUNDER @ GGWP ACADEMY</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        A passionate and experienced leader in
                                        operations and strategy, Jacqueline
                                        drives results with empowerment, culture
                                        and innovation. Her mission is to
                                        deliver education and opportunity across
                                        esports and gaming through technology.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorMary} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Mary Phillipou
                                    </h4>
                                    <Muted>
                                        <h6>
                                            BUSINESS MENTOR @ SHOW ME THE MONEY
                                        </h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        Mary Philippou is one of the best
                                        business strategic minds in the
                                        entrepreneurial space today. With her
                                        business experience now covering over 30
                                        years including raising a family while
                                        having a life of balance, dancing and
                                        fun.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        With her knowledge, skills and expertise
                                        in sales, communication, people,
                                        networks, negotiation, she now turns her
                                        attention to helping others achieve
                                        financial success in their product based
                                        business.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorJulia} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Julia Webber
                                    </h4>
                                    <Muted>
                                        <h6>
                                            BUSINESS CONTINUITY SPECIALIST @
                                            NEWCASTLE PERMANENT BUILDING SOCIETY
                                        </h6>
                                        <h6>
                                            NSW Young Woman of the Year 2020
                                            Nominee
                                        </h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Julia Weber has always dedicated
                                        herself to contributing to the
                                        community, with the publication of her
                                        self-help book for teenage girls at age
                                        14, anti-bullying campaigning throughout
                                        her high school years and becoming a
                                        financial literacy ambassador at
                                        university.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}She is the Treasurer at Animal
                                        Therapies Limited, a not-for-profit
                                        organisation connecting those in need
                                        with animal-assisted services to help
                                        prevent or manage disability, illness
                                        and suffering. Her work in the financial
                                        literacy space saw her become a finalist
                                        for the NSW Young Woman of the Year
                                        award in 2020. She recently graduated
                                        from the University of Newcastle with a
                                        Bachelor of Commerce (Accounting) and
                                        Bachelor of Business (Leadership and
                                        Management). Julia works for the
                                        Newcastle Permanent Building Society,
                                        building organisational resilience and
                                        providing insights in the IT space.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorNatalie} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Natalie Salamat
                                    </h4>
                                    <Muted>
                                        <h6>CEO @ INDUSTRY EVENTS</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Natalie is the Co-Founder of the
                                        tech startup Industry Events, a
                                        professional platform showcasing the
                                        world's top business events, courses and
                                        industry news across all sectors.
                                        Without funding from an investor,
                                        Natalie and her fellow Co-Founders have
                                        managed to build Industry Events from
                                        the ground up. Today Industry Events has
                                        become a global platform that attracts
                                        200K professionals per month.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}Industry Events has also become a
                                        leading media partner for some of the
                                        world's most renowned conferences and
                                        expos taking place in all regions of the
                                        world.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorDanielle} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Danielle Owen Whitford
                                    </h4>
                                    <Muted>
                                        <h6>CEO @ PIONEERA</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Danielle, a former corporate
                                        executive, founded start up Pioneera in
                                        2018 to improve our mental health by
                                        tackling the crippling issue of
                                        workplace stress and burnout. Pioneera’s
                                        AI platform combines technology with
                                        psychology to prevent workplace stress
                                        whilst promoting positive productive
                                        cultures and is gaining strong interest
                                        across both corporate Australia and
                                        small businesses.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}Danielle speaks regularly on
                                        wellbeing, AI, women in technology,
                                        leadership; and preventing burnout. She
                                        is also a guest lecturer at Sydney
                                        University on People and Leadership.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorLiam} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Liam Manning
                                    </h4>
                                    <Muted>
                                        <h6>COO @ SAPHI ENGINEERING</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}As the COO of SAPHI engineering,
                                        Liam helps lead a business whose focus
                                        centres around IoT development and
                                        systems integration for companies in the
                                        Water, Smart City, Energy, Agriculture,
                                        Waste and Facility Management sectors.
                                        He is also the COO & Founder of
                                        CYFERLINX, a HR recruitment tool
                                        designed to help tech recruiters hire
                                        coders faster and more reliably.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorEmily} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Emily Bobis
                                    </h4>
                                    <Muted>
                                        <h6>CO-FOUNDER @ COMPASS IOT</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Emily is the co-founder of Compass
                                        IoT, a multi-award winning tech startup.
                                        She has over five years of experience
                                        working within the Sydney startup
                                        community and is passionate about
                                        mentorship, user-centric design, and the
                                        power of data to make a difference.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorHannah} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Hannah Beder
                                    </h4>
                                    <Muted>
                                        <h6>TECHNOLOGY LEAD @ CREATABLE</h6>
                                        <h6>NSW YOUNG WOMAN OF THE YEAR</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {""}Hannah is a computer scientist and
                                        the 2020 NSW Young Woman of the Year.
                                        She is passionate about addressing the
                                        historical gender disparity in
                                        technology, and her visibility in the
                                        tech industry helps to combat the notion
                                        that ‘you can’t be what you can’t see’.
                                        She enables growth for others by
                                        mentoring young women to be successful
                                        while pursuing careers in technology.
                                        Hannah has worked as a Software Engineer
                                        at both Google and Macquarie Group, and
                                        an educator with UNSW, Code Like A Girl,
                                        and Girls’ Programming Network.
                                        Currently she’s combined her passion for
                                        programming and education in her role as
                                        a Creative Technologist with Creatable.
                                        Here, she develops and teaches technical
                                        projects to high school girls, which
                                        provide a creative and real world
                                        context for STEM skills.
                                    </h5>
                                    <h5 className={classes.cardDescription}>
                                        {""}She has been awarded by B&T, NSW
                                        Women, Rotary the Darlings, and UNSW,
                                        for her contribution to society in terms
                                        of the benefits of computing for women.
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card testimonial plain>
                                <CardAvatar testimonial plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={MentorTrevor} alt="..." />
                                    {/* </a> */}
                                </CardAvatar>
                                <CardBody plain>
                                    <h4 className={classes.title}>
                                        Trevor Weeding
                                    </h4>
                                    <Muted>
                                        <h6>OWNER @ DEVELOPMENT PATHWAYS</h6>
                                    </Muted>
                                    <h5 className={classes.cardDescription}>
                                        {'"'}Trevor enjoyed a 35 year career in
                                        the media industry - advertising sales
                                        and newspaper management - before
                                        leaving the corporate world to start
                                        Development Pathways. An enthusiastic
                                        networker, Trevor is a firm believer in
                                        The Law of Reciprocity and a "Giving
                                        Back" philosophy. Specialties: HR for
                                        small businesses, Customer Service
                                        training and education.{'"'}
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            {/* Testimonials 3 END */}
            <Footer
                content={
                    <div>
                        <div className={classes.left}>
                            {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{" "}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/teams" className={classes.block}>
                    Teams
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List> */}
                        </div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} Made by{" "}
                            <a href="http://www.franked.io" target="_blank">
                                franked.io
                            </a>{" "}
                            for a kinder web.
                        </div>
                    </div>
                }
            />
        </div>
    );
}
