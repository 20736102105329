import { makeStyles } from "@material-ui/core";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle";
import classNames from "classnames";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Header from "components/Header/Header";
import React from "react";
import { Link } from "react-router-dom";
import image from "assets/img/bg7.jpg";
const useStyles = makeStyles(signupPageStyle);

export function VerifySuccessPage({ ...rest }) {
  const classes = useStyles();
  return (
    <div>
      <Header absolute color="transparent" brand="INTERCHANGE" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={10}>
                      <p
                        className={classNames(
                          classes.description,
                          classes.textCenter
                        )}
                      >
                        Thank you for signing up. Your profile has been verified
                        successfully.
                      </p>
                      <br />
                      <p
                        className={classNames(
                          classes.description,
                          classes.textCenter
                        )}
                      >
                        Click <Link to="/signin">here</Link> to go to Sign in
                        page.
                      </p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
