/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import SectionContent from "views/PresentationPage/Sections/SectionContent.js";
import SectionSections from "views/PresentationPage/Sections/SectionSections.js";
import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";
import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";
import SectionSpeakers from "views/PresentationPage/Sections/SectionSpeakers.js";
import SectionGuestPresenters from "views/PresentationPage/Sections/SectionGuestPresenters.js";
import SectionFacilitators from "views/PresentationPage/Sections/SectionFacilitators.js";
import SectionPrizes from "views/PresentationPage/Sections/SectionPrizes.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "./PresentationPage.scss";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                // brand="Material Kit PRO React"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 400,
                    color: "info"
                }}
            />
            <Parallax
                image={require("assets/img/Homepage.jpg")}
                className={classes.parallax}
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand + " brand"}>
                                <h1>
                                    INTERCHANGE
                                    <span className="pro-badge">20.23</span>
                                </h1>
                                <h3 className={classes.title}>
                                    Virtual Employability & Entrepreneurship
                                    Challenge.
                                </h3>
                                {/* <span className={classes.title + " banner"}>
                                    2023 Information Coming Soon.
                                </span> */}
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <SectionDescription />
                <SectionSpeakers />
                {/* <SectionGuestPresenters /> */}
                <SectionPrizes />
                <SectionFacilitators />
                <SectionContent />
                <SectionOverview />
                {/* <SectionComponents /> */}
                {/* <SectionCards /> */}
                {/* <SectionContent /> */}
                {/* <SectionSections /> */}
                {/* <SectionExamples /> */}
                {/* <SectionFreeDemo /> */}
                {/* <SectionOverview /> */}
            </div>
            {/* <SectionPricing /> */}
            <Footer
                theme="white"
                content={
                    <div>
                        <div className={classes.left}>
                            <a
                                href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-presentation"
                                target="_blank"
                                className={classes.footerBrand}
                            >
                                franked.io
                            </a>
                        </div>
                        <div className={classes.pullCenter}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <Link
                                        to="/about-us"
                                        className={classes.block}
                                    >
                                        About Us
                                    </Link>
                                </ListItem>{" "}
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/teams" className={classes.block}>
                                        Teams
                                    </Link>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <Link
                                        to="/blog-posts"
                                        className={classes.block}
                                    >
                                        Blogs
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.rightLinks}>
                            <ul>
                                <li>
                                    <Button
                                        href="https://www.facebook.com/interchangers"
                                        target="_blank"
                                        color="facebook"
                                        justIcon
                                        simple
                                    >
                                        <i className="fab fa-facebook" />
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        href="https://www.linkedin.com/company/the-frank-team"
                                        target="_blank"
                                        color="linkedin"
                                        justIcon
                                        simple
                                    >
                                        <i className="fab fa-linkedin" />
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            />
        </div>
    );
}
