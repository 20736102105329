import axios from "../axiosInstance";

export const uploadProfileImage = data =>
    axios
        .post("/user/upload-profile-image", data, {
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`
            }
        })
        .then(res => res.data);

export const updateUserProfile = data =>
    axios.put("/user", data).then(res => res.data);

export const getUserProfile = () =>
    axios.get(`/user/profile`).then(res => res.data);

export const getUserTimelineQuestion = order =>
    axios.get(`/user/timeline-question`, { params: { order } });

export const saveUserTimelineQuestion = (order, question, answer) =>
    axios.post(`/user/timeline-question`, { order, question, answer });

export const getUserSkillRatings = () =>
    axios.get(`/user/skill-rating`).then(res => res.data);

export const addUserSkillRating = (rating, skillId) =>
    axios.post(`/user/skill-rating`, { rating, skillId }).then(res => res.data);

export const saveCaseStudy = (caseStudyId, formData) =>
    axios.put(`/case-study/${caseStudyId}`, formData).then(res => res.data);

export const getCaseStudy = caseStudyId =>
    axios.get(`/case-study/${caseStudyId}`).then(res => res.data);

export const getCaseStudies = () =>
    axios.get(`/case-study`).then(res => res.data);
