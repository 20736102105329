import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
// import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles(profilePageStyle);

const timelineQuestionSchema = yup.object().shape({
    answer: yup.string().required()
});

const TimelineQuestion = props => {
    const { buttonColor, getData, saveData } = props;
    const [counter, setCounter] = useState(0);
    const classes = useStyles();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(timelineQuestionSchema)
    });

    const submitTimelineQuestionForm = async data => {
        const { answer } = data;
        if (answer) {
            await saveData(answer);
            toast.success("Saved answer Successfully!");
        }
    };

    useEffect(() => {
        (async () => {
            if (getData) {
                const { data } = await getData();
                setValue("answer", data.answer, {
                    shouldValidate: true
                });
                setCounter(data?.answer?.length);
            }
        })();

        // eslint-disable-next-line
    }, []);

    const handleInputChange = e => {
        setCounter(e?.target?.value?.length);
    };
    return (
        <form onSubmit={handleSubmit(submitTimelineQuestionForm)}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {/* <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel> */}
                    <CustomInput
                        labelText="Tip: share something you learnt that was new, insightful or challenging"
                        id="about-me"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            multiline: true,
                            rows: 8,
                            type: "text",
                            maxLength: 450
                        }}
                        name="answer"
                        control={control}
                        handleChange={handleInputChange}
                    />
                    <span className={classes.textCounter}>{counter}/450</span>
                    {errors.institution?.message && (
                        <span className={classes.labelError}>
                            {errors.institution?.message}
                        </span>
                    )}
                    <Button color={buttonColor} type="submit">
                        Save
                    </Button>
                </GridItem>
            </GridContainer>
        </form>
    );
};

TimelineQuestion.propTypes = {
    getData: PropTypes.func,
    saveData: PropTypes.func,
    buttonColor: PropTypes.oneOf([
        "primary",
        "secondary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ])
};

export default TimelineQuestion;
