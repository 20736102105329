export const MentorsData = {
    2020: [
        {
            id: 1,
            name: "Abbie Wildin",
            imageURL: require("assets/img/faces/Mentors/MentorAbbie.jpg"),
            mutedText: "CEO @ One Extra Zero",
            description: ""
        },
        {
            id: 2,
            name: "Amy Cheung",
            imageURL: require("assets/img/faces/Mentors/MentorAmy.jpg"),
            mutedText: "CEO @ HALLS GLOBAL",
            description: ""
        },
        {
            id: 3,
            name: "Anthony Pinto",
            imageURL: require("assets/img/faces/Mentors/MentorAnthony.jpg"),
            mutedText: "CEO @ ENLIGHTENED BUSINESS SOLUTIONS",
            description: ""
        },
        {
            id: 4,
            name: "Bernard Kassab",
            imageURL: require("assets/img/faces/Mentors/MentorBernard.jpg"),
            mutedText: "DESIGNER @ UCIDITY",
            description: ""
        },
        {
            id: 5,
            name: "Daniel Guevara",
            imageURL: require("assets/img/faces/Mentors/MentorDaniel.jpg"),
            mutedText: "PROJECT MANAGER @ UNIVERSITY OF SYDNEY",
            description: ""
        },
        {
            id: 6,
            name: "Elizabeth Pain",
            imageURL: require("assets/img/faces/Mentors/MentorElizabeth.jpg"),
            mutedText:
                "ORGANISATIONAL DEVELOPMENT STRATEGIST @ LEARNINGINSIGHT",
            description: ""
        },
        {
            id: 7,
            name: "George Mavros",
            imageURL: require("assets/img/faces/Mentors/MentorGeorge.jpg"),
            mutedText: "BUSINESS COACH @ ETSI CONSULTING",
            description: ""
        },
        {
            id: 8,
            name: "Hilary Brainard",
            imageURL: require("assets/img/faces/Mentors/MentorHilary.jpg"),
            mutedText: "HEAD OF MARKETING & COMMUNICATIONS @ BLUECHILLI GROUP",
            description: ""
        },
        {
            id: 9,
            name: "Harry Wilson",
            imageURL: require("assets/img/faces/Mentors/MentorHarry.jpg"),
            mutedText: "CEO @ ZUMP",
            description: ""
        },
        {
            id: 10,
            name: "John Groarke",
            imageURL: require("assets/img/faces/Mentors/MentorJohn.jpg"),
            mutedText: "CONSULTANT",
            description: ""
        },
        {
            id: 11,
            name: "Kerryn Richardson",
            imageURL: require("assets/img/faces/Mentors/MentorKerryn.jpg"),
            mutedText: "EXECUTIVE @ AUSTRALIAN ELECTORAL COMMISSION",
            description: ""
        },
        {
            id: 12,
            name: "Mereike Niedermeier",
            imageURL: require("assets/img/faces/Mentors/MentorMareike.jpg"),
            mutedText: "DIRECTOR @ SALES SAVVY",
            description: ""
        },
        {
            id: 13,
            name: "Mary Phillipou",
            imageURL: require("assets/img/faces/Mentors/MentorMary.jpg"),
            mutedText: "BUSINESS MENTOR @ SHOW ME THE MONEY",
            description: ""
        },
        {
            id: 14,
            name: "Mike Ferrier",
            imageURL: require("assets/img/faces/Mentors/MentorMike.jpg"),
            mutedText: " MANAGING DIRECTOR @ EYE ON PROPERTY INSPECTIONS",
            description: ""
        },
        {
            id: 15,
            name: "Natalie Salamat",
            imageURL: require("assets/img/faces/Mentors/MentorNatalie.jpg"),
            mutedText: "CEO @ INDUSTRY EVENTS",
            description: ""
        },
        {
            id: 16,
            name: "Paris Cutler",
            imageURL: require("assets/img/faces/Mentors/MentorParis.jpg"),
            mutedText: "FOUNDER @ PARISCUTLER.COM",
            description: ""
        },
        {
            id: 17,
            name: "Paul Higham",
            imageURL: require("assets/img/faces/Mentors/MentorPaul.jpg"),
            mutedText: "HEAD OF STRATEGY @ SYDNEY WATER",
            description: ""
        },
        {
            id: 18,
            name: "Raz Chorev",
            imageURL: require("assets/img/faces/Mentors/MentorRaz.jpg"),
            mutedText: "CONSULTANT @ STYLEQUITY ADVISORY",
            description: ""
        },
        {
            id: 19,
            name: "Terri-Helen Gaynor",
            imageURL: require("assets/img/faces/Mentors/MentorTerri.jpg"),
            mutedText: "GOVERNMENT CONSULTANT",
            description: ""
        },
        {
            id: 20,
            name: "Therese Wales",
            imageURL: require("assets/img/faces/Mentors/MentorTherese.jpg"),
            mutedText: "CEO @ CHANGE NERD",
            description: ""
        },
        {
            id: 21,
            name: "Trevor Weeding",
            imageURL: require("assets/img/faces/Mentors/MentorTrevor.jpg"),
            mutedText: "OWNER @ DEVELOPMENT PATHWAYS",
            description: ""
        },
        {
            id: 22,
            name: "William Tjo",
            imageURL: require("assets/img/faces/Mentors/MentorWilliam.jpg"),
            mutedText: "FOUNDER @ PAPER PENS CO.",
            description: ""
        },
        {
            id: 23,
            name: "Heejung Cecelia",
            imageURL: require("assets/img/faces/Mentors/MentorCecilia.jpg"),
            mutedText: "CPA",
            description: ""
        },
        {
            id: 24,
            name: "Alistair Gray",
            imageURL: require("assets/img/faces/Mentors/MentorAlistair.jpg"),
            mutedText: "BUSINESS GROWTH STRATEGIST",
            description: ""
        }
    ],
    2021: [
        {
            id: 1,
            name: "Sheilah Hogan Casey",
            imageURL: require("assets/img/faces/Mentors/MentorSheilah.jpg"),
            mutedText: "Founder @ BX Collective",
            description:
                "Sheliah has a global career spanning 20+ years owning and running businesses in the US, UK and Australia. She was Ernst & Young Entrepreneur of the Year Award Winner in 2007. Her extensive experience and passions include coaching, mentoring and consulting with business leaders on clarifying, simplifying and achieving their vision, identifying greatest growth opportunities and helping companies realise the ROI of Customer Experience Excellence."
        },
        {
            id: 2,
            name: "Amy Cheung",
            imageURL: require("assets/img/faces/Mentors/MentorAmy.jpg"),
            mutedText: "CEO @ HALLS GLOBAL",
            description:
                "Amy is an experienced senior executive with extensive experiences in Innovation, AI, IoT, Strategic Management, Digital Marketing, Strategic Sales, Channel Development, Change Management, Business Transformation, Entrepreneurship, Communications, Strategic Partnerships and General Management. Amy co-founded Halls Global Limited and Con Moto, which focuses on helping start-ups, entrepreneurs and businesses grow in the digital age through the latest digital strategies, business growth strategies, change management methodology and university validated communication strategies."
        },
        {
            id: 3,
            name: "Diego Trigo",
            imageURL: require("assets/img/faces/Mentors/MentorDiego.jpg"),
            mutedText: "FOUNDER @ HELLO AGAIN",
            description:
                "Diego has worked as CTO, Managing Partner, Founder, Technical Director, Coder and always as problem solver. He`s had the opportunity to apply his expertise to iconic brands such as Audi, Telstra, eBay, Google, VW, Telstra, Virgin for the past 10 years working in Melbourne and Sydney. He has always enjoyed building and leading teams, creating new businesses in uncharted territories and working along-side founders and entrepreneurs, advising on technology strategy, ideation and product roadmap for start-ups, scale-ups or established business."
        },
        {
            id: 4,
            name: "Claire Bath",
            imageURL: require("assets/img/faces/Mentors/MentorClaire.png"),
            mutedText: "IT EXECUTIVE  WOMAN LEADING TECH 2020 FINALIST",
            description:
                "Claire Bath is a high-calibre female leader in the tech industry, uniquely based in regional Australia. She is analytical, encouraging, forward thinking, and a superior coach and mentor. An early adopter of change, she worked remotely before it became mainstream; negotiated part time hours from full time positions. She has inspired business leaders, IT graduates, trainees, GIS specialists, project managers, mid-tier IT leaders, programmers, analysts, operations staff of all ages, genders and backgrounds.  Claire has maintained her networks through attending industry mentoring programs and continues to give back and coach others. She always kept abreast of industry developments, has a passion for continuous learning, offering opportunities to her staff and professional excellence. Following her passion as a mentor she joined The Opportunity Collective to inspire local women to reach their potential through the Hunter Women’s Leadership Program. Claire was a finalist in the 2020 B&T Women leading Tech awards under the mentor category"
        },
        {
            id: 5,
            name: "Lisa Penson",
            imageURL: require("assets/img/faces/Mentors/MentorLisa.jpg"),
            mutedText: "BUSINESS EDUCATOR & FACILITATOR",
            description:
                "After a successful career in business development both locally and internationally, Lisa decided to leave the corporate world to launch her own business - Solopreneur Success. She works with micro and small business owners (Solopreneurs) and helps them develop the skills, systems, foundation and strategies to build a successful business they love. She is also an advisor for NSW Department of Industry’s Business Connect advisory service, funded by the NSW Government."
        },
        {
            id: 6,
            name: "Filipa Araujo",
            imageURL: require("assets/img/faces/Mentors/MentorFilipa.png"),
            mutedText: "PROGRAM DIRECTOR @ BLUECHILLI",
            description:
                "Filipa is a strong advocate for women tech founders, entrepreneurship as a development driver and improving youth employment opportunities. Her work focuses on supporting entrepreneurs as they start and scale their companies and increasing diversity and inclusivity in startups. Prior to BlueChilli, Filipa worked with founders and startups in Europe, Africa, and Southeast Asia. She ran the first country-wide startup competition in Guinea Bissau, funded by the World Bank, training over 500 young entrepreneurs. Filipa is an international development and entrepreneurship consultant, a mentor for the DFAT-funded Women Leadership Initiative, a jury for the Cartier Women`s Initiative, a business coach for Global Sisters, and pitch coach for various incubators and accelerators in Australia and New Zealand. She holds a bachelor’s degree in Economics and a double Master`s in International Business and Social Entrepreneurship from CEMS and Nova School of Business and Economics."
        },
        {
            id: 7,
            name: "Jane Laverty",
            imageURL: require("assets/img/faces/Mentors/MentorJane.jpg"),
            mutedText: " REGIONAL MANAGER @ NSW BUSINESS CHAMBER",
            description:
                "Jane Laverty is a self-confessed regionalist with a personal mission and passion for sustainable economic development and meaningful business and community engagement. Based in the Northern Rivers of NSW, Jane is the Regional Manager for Business NSW, the peak body for business. With a depth of experience working in professional services marketing, economic development, strategic planning and engagement and a passion for making things happen she is best described as a blank page specialist. Jane advocates on behalf of regional communities, business and industry for improved business conditions and works closely with local business leaders and all levels of government to create jobs and attract regional investment."
        },
        {
            id: 8,
            name: "Aoife O`Connell",
            imageURL: require("assets/img/faces/Mentors/MentorAoife.png"),
            mutedText: "FOUNDER & YARNLY AI",
            description:
                " Aoife O’Connell is the Founder & Director of Yarnly.AI leading the AI smart team (an award-winning Chatbot & Voice Agency) and the COO of Women In Voice a non for profit built to celebrate, amplify, and empower women and diverse people in the voice technology field. My fuel is to inspire, encourage, support women on re-entering the workforce and have a career in the future of technology."
        },
        {
            id: 9,
            name: "Betty Andrews",
            imageURL: require("assets/img/faces/Mentors/MentorBetty.jpg"),
            mutedText: "FOUNDER @ HUSTLE & HEART",
            description:
                " Having found her big purpose in life, Betty now helps other women not only find their purpose, but create their dream lives and businesses. Using data and strategy, she loves to help people create more meaning for themselves. With 15 years experience in marketing, creative and brand strategy, Betty has worked on marketing campaigns for Destination New South Wales, LVMH, ASUS, Peugeot just to name a few. She is also currently the Expert in Residence at Fishburners, one of Australia`s best tech startup community."
        },
        {
            id: 10,
            name: "John Groarke",
            imageURL: require("assets/img/faces/Mentors/MentorJohn.jpg"),
            mutedText: "CONSULTANT",
            description:
                "  John Groarke is a mentor to Consultants and Services-Centric enterprises, creating relevant, distinctive, focused, sustainable & profitable enterprises since 2001. John is also a mentor to Early Stage Start-Ups, shaping their prosperous futures since 2006.   And besides mentoring for INTERCHANGE, he mentors for the CSIRO`s ON Prime program, the Small Business Mentoring Service, The University of Sydney) and The University of Sydney Business School - Genesis Program."
        },
        {
            id: 11,
            name: "Cheryl Royle",
            imageURL: require("assets/img/faces/Mentors/MentorCheryl.jpg"),
            mutedText: " ENTREPRENEURSHIP FACILITATOR @ FINER LINE",
            description:
                " Cheryl is a Hunter-based business coach, educator, consultant, and founder of The Finer Line, eMindshift Learning and Chair of the Young Business Minds. She has owned and operated six businesses over the past 25 years and understands the challenges of starting, growing and exiting a business. As an educator, she has supported and coached 100’s of business owners, freelancers, consultants, artists and entrepreneurs across Australia. Cheryl is committed to helping the small business owners and entrepreneurs unlock their potential, develop an entrepreneurial mindset and create the business of their dreams. For the past 5 years Cheryl has served as the Hunter Entrepreneurship Facilitator, a role with the aim to inspire, educate, mentor, and connect future entrepreneurs to start or build a sustainable business."
        },
        {
            id: 12,
            name: "Jacqueline Garrett",
            imageURL: require("assets/img/faces/Mentors/MentorJacqueline.jpg"),
            mutedText: "FOUNDER @ GGWP ACADEMY",
            description:
                " A passionate and experienced leader in operations and strategy, Jacqueline drives results with empowerment, culture and innovation. Her mission is to deliver education and opportunity across esports and gaming through technology."
        },
        {
            id: 13,
            name: "Mary Phillipou",
            imageURL: require("assets/img/faces/Mentors/MentorMary.jpg"),
            mutedText: "BUSINESS MENTOR @ SHOW ME THE MONEY",
            description:
                "Mary Philippou is one of the best business strategic minds in the entrepreneurial space today. With her business experience now covering over 30 years including raising a family while having a life of balance, dancing and fun.    With her knowledge, skills and expertise in sales, communication, people, networks, negotiation, she now turns her attention to helping others achieve financial success in their product based business."
        },
        {
            id: 14,
            name: "Julia Webber",
            imageURL: require("assets/img/faces/Mentors/MentorJulia.jpg"),
            mutedText:
                "BUSINESS CONTINUITY SPECIALIST @ NEWCASTLE PERMANENT BUILDING SOCIETY NSW Young Woman of the Year 2020 Nominee",
            description:
                "Julia Weber has always dedicated herself to contributing to the community, with the publication of her self-help book for teenage girls at age 14, anti-bullying campaigning throughout her high school years and becoming a financial literacy ambassador at university. She is the Treasurer at Animal Therapies Limited, a not-for-profit organisation connecting those in need with animal-assisted services to help prevent or manage disability, illness and suffering. Her work in the financial literacy space saw her become a finalist for the NSW Young Woman of the Year award in 2020. She recently graduated from the University of Newcastle with a Bachelor of Commerce (Accounting) and Bachelor of Business (Leadership and Management). Julia works for the Newcastle Permanent Building Society, building organisational resilience and providing insights in the IT space."
        },
        {
            id: 15,
            name: "Natalie Salamat",
            imageURL: require("assets/img/faces/Mentors/MentorNatalie.jpg"),
            mutedText: "CEO @ INDUSTRY EVENTS",
            description:
                "Natalie is the Co-Founder of the tech startup Industry Events, a professional platform showcasing the world`s top business events, courses and industry news across all sectors. Without funding from an investor, Natalie and her fellow Co-Founders have managed to build Industry Events from the ground up. Today Industry Events has become a global platform that attracts 200K professionals per month. Industry Events has also become a leading media partner for some of the world`s most renowned conferences and expos taking place in all regions of the world."
        },
        {
            id: 16,
            name: "Danielle Owen Whitford",
            imageURL: require("assets/img/faces/Mentors/MentorDanielle.jpg"),
            mutedText: "CEO @ PIONEERA",
            description:
                "Danielle, a former corporate executive, founded start up Pioneera in 2018 to improve our mental health by tackling the crippling issue of workplace stress and burnout. Pioneera’s AI platform combines technology with psychology to prevent workplace stress whilst promoting positive productive cultures and is gaining strong interest across both corporate Australia and small businesses. Danielle speaks regularly on wellbeing, AI, women in technology, leadership; and preventing burnout. She is also a guest lecturer at Sydney University on People and Leadership."
        },
        {
            id: 17,
            name: "Liam Manning",
            imageURL: require("assets/img/faces/Mentors/MentorLiam.jpg"),
            mutedText: "COO @ SAPHI ENGINEERING",
            description:
                "As the COO of SAPHI engineering, Liam helps lead a business whose focus centres around IoT development and systems integration for companies in the Water, Smart City, Energy, Agriculture, Waste and Facility Management sectors. He is also the COO & Founder of CYFERLINX, a HR recruitment tool designed to help tech recruiters hire coders faster and more reliably."
        },
        {
            id: 18,
            name: "Emily Bobis",
            imageURL: require("assets/img/faces/Mentors/MentorEmily.jpg"),
            mutedText: "CO-FOUNDER @ COMPASS IOT",
            description:
                "Emily is the co-founder of Compass IoT, a multi-award winning tech startup. She has over five years of experience working within the Sydney startup community and is passionate about mentorship, user-centric design, and the power of data to make a difference."
        },
        {
            id: 19,
            name: "Hannah Beder",
            imageURL: require("assets/img/faces/Mentors/MentorHannah.jpg"),
            mutedText:
                "TECHNOLOGY LEAD @ CREATABLE NSW YOUNG WOMAN OF THE YEAR",
            description:
                "Hannah is a computer scientist and the 2020 NSW Young Woman of the Year. She is passionate about addressing the historical gender disparity in technology, and her visibility in the tech industry helps to combat the notion that ‘you can’t be what you can’t see’. She enables growth for others by mentoring young women to be successful while pursuing careers in technology. Hannah has worked as a Software Engineer at both Google and Macquarie Group, and an educator with UNSW, Code Like A Girl, and Girls’ Programming Network. Currently she’s combined her passion for programming and education in her role as a Creative Technologist with Creatable. Here, she develops and teaches technical projects to high school girls, which provide a creative and real world context for STEM skills. She has been awarded by B&T, NSW Women, Rotary the Darlings, and UNSW, for her contribution to society in terms of the benefits of computing for women."
        },
        {
            id: 20,
            name: "Trevor Weeding",
            imageURL: require("assets/img/faces/Mentors/MentorTrevor.jpg"),
            mutedText: "OWNER @ DEVELOPMENT PATHWAYS",
            description:
                'Trevor enjoyed a 35 year career in the media industry - advertising sales and newspaper management - before leaving the corporate world to start Development Pathways. An enthusiastic networker, Trevor is a firm believer in The Law of Reciprocity and a "Giving Back" philosophy. Specialties: HR for small businesses, Customer Service training and education.'
        }
    ],
    2022: [
        {
            id: 1,
            name: "Colin Goudie",
            imageURL: require("assets/img/faces/Mentors/MentorColinGoudie.jpg"),
            mutedText: "CO-FOUNDER & CTO @ SOCIAL PINPOINT",
            description:
                "Colin is an experienced technical entrepreneur having founded a number of startups including Social Pinpoint which is a leading provider of engagement tools for governments. Throughout his career he has been involved with all areas of software development whilst his experience in founding startups has led to a keen passion in business, growth, marketing and scaling software startups. His passion is Software as a Service and using technology to better create a connected and well informed world."
        },
        {
            id: 2,
            name: "Amy Cheung",
            imageURL: require("assets/img/faces/Mentors/MentorAmy.jpg"),
            mutedText: "CEO @ CON MOTO",
            description: `Amy is an accomplished senior executive and management consultant, specialising in strategic management, people leadership, innovation, digital marketing, change management, business transformation, general management and entrepreneurship. In more recent years, Amy co-founded Con Moto, which focuses on helping start-ups, entrepreneurs and businesses grow with the latest business growth strategies, digital marketing strategies, change management methodology and university validated communication strategies. Amy is passionate about driving positive change, growth and creating value through people leadership and technology innovations. She lives by the motto "Be the change you want to see in the world". In addition to professional activities, Amy is passionate about volunteering her skills to various not-for-profit organisations and communities as a board member or an advisor. Amy delights in mentoring and coaching MBA students, university students and entrepreneurs. Amy holds a Master of Business Administration from the Australian Graduate School of Management, a Bachelor of Medical Science degree from The University of Sydney, and Change Management accreditation from Prosci United States.`
        },
        {
            id: 3,
            name: "Linda Apostolidis",
            imageURL: require("assets/img/faces/Mentors/MentorLindaApostolidis.jpeg"),
            mutedText: "DIRECTOR @ NEWYTECHPEOPLE",
            description:
                "Linda Apostolidis was born and raised in Newcastle, spending the majority of her career in advertising and marketing before bringing her family back to Newcastle in 2010. Linda spent 8 years at Hudson Newcastle, leading their Technology practice, before helping build a start-up recruitment agency. In a desire to start her own business, she joined James as a Director of NewyTechPeople in 2020. NewyTechPeople is a business based on Community + Recruitment. Linda has been a long term advocate of talent locally, helping many graduates start their careers in technology. She has experience recruiting across Software & Web Development, DevOps, Infrastructure, Project Management, Business Analysis, Change & Transformation, Solution & Enterprise Architecture, Analytics, Data Science, Business Intelligence, IT Operations and Senior Management."
        },
        {
            id: 4,
            name: "Claire Bath",
            imageURL: require("assets/img/faces/Mentors/MentorClaire.png"),
            mutedText: "IT EXECUTIVE WOMAN LEADING TECH 2020 FINALIST",
            description:
                "Claire Bath is a high-calibre female leader in the tech industry, uniquely based in regional Australia. She is analytical, encouraging, forward thinking, and a superior coach and mentor. An early adopter of change, she worked remotely before it became mainstream; negotiated part time hours from full time positions. She has inspired business leaders, IT graduates, trainees, GIS specialists, project managers, mid-tier IT leaders, programmers, analysts, operations staff of all ages, genders and backgrounds. Claire has maintained her networks through attending industry mentoring programs and continues to give back and coach others. She always kept abreast of industry developments, has a passion for continuous learning, offering opportunities to her staff and professional excellence. Following her passion as a mentor she joined The Opportunity Collective to inspire local women to reach their potential through the Hunter Women’s Leadership Program. Claire was a finalist in the 2020 B&T Women leading Tech awards under the mentor category"
        },
        {
            id: 5,
            name: "George Mavros",
            imageURL: require("assets/img/faces/Mentors/MentorGeorge.jpg"),
            mutedText: "BUSINESS COACH @ ETSI CONSULTING",
            description:
                "Specialties: Sales Marketing and Business consulting, Patent and Intellectual Property, negotiating and mediation, personal development and soft skills training, motivational speaking and corporate training, product development. I have been in sales marketing and distribution for over 30 years, I have represented companies like Artline Marking Pens, WD40, Armor All, Alcan Aluminium, Barilla Pastas and Spaghetti. and sold to Majors in most areas of the Market place, be it Grocery – Confectionery – Hardware - Pharmaceutical or Government. I created my first product at the age of 11 at the same time started my first business venture. Along the way I have learnt many secrets of the best, in Sales Marketing and Patenting and now look to use that knowledge and experience to assist others to realise their true potential. Be it in a commercial or person perspective."
        },
        {
            id: 6,
            name: "John Groarke",
            imageURL: require("assets/img/faces/Mentors/MentorJohn.jpg"),
            mutedText: "CONSULTANT",
            description:
                "John Groarke is a mentor to Consultants and Services-Centric enterprises, creating relevant, distinctive, focused, sustainable & profitable enterprises since 2001. John is also a mentor to Early Stage Start-Ups, shaping their prosperous futures since 2006.  And besides mentoring for INTERCHANGE, he mentors for the CSIR's ON Prime program, the Small Business Mentoring Service, The University of Sydney) and The University of Sydney Business School - Genesis Program."
        },
        {
            id: 7,
            name: "Natalie Salamat",
            imageURL: require("assets/img/faces/Mentors/MentorNatalie.jpg"),
            mutedText: "CEO @ INDUSTRY EVENTS",
            description:
                "Natalie is the Co-Founder of the tech startup Industry Events, a professional platform showcasing the world's top business events, courses and industry news across all sectors. Without funding from an investor, Natalie and her fellow Co-Founders have managed to build Industry Events from the ground up. Today Industry Events has become a global platform that attracts 200K professionals per month. Industry Events has also become a leading media partner for some of the world's most renowned conferences and expos taking place in all regions of the world."
        },
        {
            id: 8,
            name: "Trevor Weeding",
            imageURL: require("assets/img/faces/Mentors/MentorTrevor.jpg"),
            mutedText: "OWNER @ DEVELOPMENT PATHWAYS",
            description:
                'Trevor enjoyed a 35 year career in the media industry - advertising sales and newspaper management - before leaving the corporate world to start Development Pathways. An enthusiastic networker, Trevor is a firm believer in The Law of Reciprocity and a "Giving Back" philosophy. Specialties: HR for small businesses, Customer Service training and education.'
        },
        {
            id: 9,
            name: "Lisa Paisley",
            imageURL: require("assets/img/faces/Mentors/MentorLisaPaisley.jpg"),
            mutedText: "CO-FOUNDER @ AGGIE",
            description:
                "Being exposed to agriculture at a young age, Lisa knew that to make positive social change, a great place to start was by empowering smallholder farmers to address poverty and food insecurity. After graduating university as an Agricultural Scientist, Lisa moved to Fiji to startup Aggie Global; a social business connecting small farmers to big markets. As CEO of Aggie Global, Lisa has a wide breadth of experience in areas such as strategy, team management, marketing, sales, product development, supply chain management, design thinking etc. Aggie Global has now grown to include Aggie Gifts in Australia and has won several development awards for the use of technology to create positive social change."
        },
        {
            id: 10,
            name: "Zoe Paisley",
            imageURL: require("assets/img/faces/Mentors/MentorZoePaisley.jpg"),
            mutedText: "CO-FOUNDER @ AGGIE",
            description:
                "Zoe is passionate about agricultural development, and empowering communities. This is why she cofounded her social businesses Aggie Global and Aggie Gifts in Fiji and Australia, to reconnect humanity through the gift of food. In Fiji, Zoe works with smallholder farmers to meet market demand and sell to local buyers. In Australia, Zoe curates ethical bushfood hampers, focusing on empowering and supporting Inidgenous Australian producers. Zoe's focus is on product development, supply chain management and team management, but has experience across all aspects of a social enterprise.She is passionate about sharing her story to enable others and connect with suppliers."
        },
        {
            id: 11,
            name: "Raz Chorev",
            imageURL: require("assets/img/faces/Mentors/MentorRazChorev.jpg"),
            mutedText: "CONSULTANT @ STYLE EQUITY",
            description:
                "An entrepreneurial, commercially-focused business leader. Raz loves finding gaps and opportunities to create products, services, and businesses. He naturally tends to focus on the customer, while applying commercial considerations to make sure there's always a win-win situation. Over the past 20+ years, he has worked with a variety of companies, including multinational, global conglomerates. He communicates in a way that resonates with people on every level. Raz values complete transparency and honesty and holds himself to the same standards he expects from others. He has a keen interest in innovation, disruptive technologies and business models."
        },
        {
            id: 12,
            name: "Anton Pemmer",
            imageURL: require("assets/img/faces/Mentors/MentorAntonPemmer.jpg"),
            mutedText: "OWNER & FACILITATOR @ INOV8YOU",
            description:
                "Having advised 250+ businesses as well as coaching and lecturing on topics like high performance teams, business, entrepreneurship and innovation, Anton has a unique blend of business skills from both an education and experiential lens. Whether it be across Manufacturing, Distribution, Retail, Importing, Exporting, Healthcare, and Business Services one thing he loves doing is injecting more fun to help solve problems for organisations and their teams and have worked with clients like Canberra Business Chamber, Rotary International Clubs and The Australian Institute of Criminology to name just a few. "
        },
        {
            id: 13,
            name: "Fran Morris",
            imageURL: require("assets/img/faces/Mentors/MentorFranMorris.jpg"),
            mutedText: "LEARNING & DEVELOPMENT SPECIALIST",
            description:
                "TL&D specialist at the postgraduate, continuing professional development (CPD) level for institutes and companies developing internal knowledge capture programs. Fran has developed applied programs in finance (online program - Financial Performance Unveiled for decision makers without formal training in finance), development, sustainability, management; as well as induction or assessment centre and policy projects. Published online (e-book format) a series of workshops on ethics and fraud. She enjoys the challenge of developing robust and engaging programs."
        },
        {
            id: 14,
            name: "Debbie Roberts",
            imageURL: require("assets/img/faces/Mentors/MentorDebbieRoberts.png"),
            mutedText: "Consultant @ Debbie Roberts Consulting",
            description:
                "Debbie is a small business consultant, advisor and coach who is very much a ‘people person’ having a passion for connecting and engaging with people. During her time living and working across Greater Sydney, she has established a strong network of contacts & experience in the areas of business, health, education, not-for-profits, charities, hospitality, local and state government, and economic development. She thrives on connecting and empowering small business owners and organisational leaders to build their knowledge and capability for long term success. Using a collaborative approach, she shares her skills in the areas of strategy and business planning, stakeholder engagement, business systems, financial management and marketing to help clients reach full potential. Debbie has mentored many people over the years, both in a formal & informal capacity. From high school, TAFE & University students to overseas Rotary exchange students, youth advisors and new employees, Debbie takes great pride in the success of her mentees."
        },
        {
            id: 15,
            name: "Ingrid Cucchi",
            imageURL: require("assets/img/faces/Mentors/MentorIngridCucchi.jpg"),
            mutedText: "",
            description:
                "Ingrid Cucchi is a futurist. She loves thinking about the future and how to survive and thrive in it. She is particularly interested in the future of work and workplaces and how we might all ‘love what we do’ and the big benefits that, something this simple, could bring to communities. Ingrid is also an advocate for finding new ways to address big issues, in particular, homelessness and housing affordability. Her work involves consulting to business & government, speaking whenever she gets the opportunity and guiding women in her You: Re-imagined Retreats. Her retreats and workshops give women the space and tools to re-imagine their future and expand their vision of how they see themselves in the world. She has worked in the private sector, government and is now building her own business – The Next Twenty Years Academy. She has a vast array of skills from leadership and management of teams, strategic planning, business development, training, mentoring and coaching, developing and delivering government projects and programs."
        },
        {
            id: 16,
            name: "TIM BOYLE",
            imageURL: require("assets/img/faces/Mentors/MentorTimBoyle.jpg"),
            mutedText: "Director, Innovation & Commercialisation @ ANSTO ",
            description:
                "I am a globally recognised leader in research translation and IP commercialisation, a futurist, and keynote speaker with over 20 years of experience cultivating the interface between research and industry. I am an advocate for research innovation for impact and economic development. Previously I have worked for companies that include UNSW Innovations, Thomson Reuters and Johnson & Johnson. I am currently Director, Innovation and Commercialisation at ANSTO. I am the responsible officer and founder of the nandin Innovation Centre, a deep technology centre for commercialisation, experimentation, and entrepreneurial learning. My qualifications include a PhD in Chemistry, I am a Graduate of the Australian Institute of Company Directors (GAICD) and Registered Technology Transfer Professional (RTTP)."
        },
        {
            id: 17,
            name: "Anthony Pinto",
            imageURL: require("assets/img/faces/Mentors/MentorAnthonyPinto.jpg"),
            mutedText: "CEO @ Enlightened Business Solutions ",
            description: ""
        },
        {
            id: 18,
            name: "Borka Buseska",
            imageURL: require("assets/img/faces/Mentors/MentorBorkaBuseska.jpg"),
            mutedText: "",
            description:
                "Borka is a franchise owner in an emerging innovative industry, CEO of a Registered Training Organisations and a Board member on the Red Cross Advisory Board (ACT). Borka moved from the Public sector onto the private sector in 2012, where she owned and operated two franchises in the beauty industry (Laser Clinics Australia). That experience provided her with an insight into the skills gap that existing in the industry.  She now also owns a Register Training Organisations where working with employers is an essential element of successful training. Previously Borka worked in the public sector for over 30 years providing her with strong operational and policy knowledge of that sector.  She conducted three National Tenders and has also managed grant innovation funding programs that looked for innovative projects to work with employers, industry bodies and/or educator. After leaving the public service Borka created a consulting company that assisted a variety of private, not-for-profit and public organisations with navigating the government system. Borka also worked in the Australian Chamber of Commerce and Industry (ACCI) where she released a youth paper and a guide for employers hiring and employing Indigenous people."
        },
        {
            id: 19,
            name: "Jan Gonzalez",
            imageURL: require("assets/img/faces/Mentors/MentorJanGonzalez.jpg"),
            mutedText:
                "CEO @ Active Recruitment, Active Health & Allied Services, Active Carers ",
            description:
                "Jan is an experienced recruiter with over 25 years of experience in the industry and has worked in the White, Blue, and Pink-Collar Industries. A graduate of the University of Western Sydney with a Master in Business Commerce, Jan is a committed and passionate recruitment professional. Jan is one of the founding directors at Active Recruitment, a generalist recruitment company established in 2003. In 2020, Jan and her team noticed a gap in the marketplace and launched Active Health and Allied Services to assist with finding quality allied health professionals for the health industry. In 2021 they launched Active Carers to find carers with passion to help look after our community’s most vulnerable. Jan’s success is due to the close working relationships she and her team have built with clients and applicants over the years, providing high-quality solutions every time."
        },
        {
            id: 20,
            name: "Joseph Essey",
            imageURL: require("assets/img/faces/Mentors/MentorJosephEssey.jpg"),
            mutedText:
                "Associate partner @ Business Growth and Exit Specialists ",
            description:
                "As an MBA qualified advisor to the small business sector, I am a commercially minded and hands-on business mentor with over 15 years’ experience driving the finance function in fast growing private organisations. My career has included stints with a number of large businesses and corporates in finance and business management roles including Caroma Dorf, PFD Foodservices & Nisbets Australia but my passion is in helping small businesses to grow and achieve their goals. What differentiates me to most business consultants is that most of my expertise has been acquired through working in growing small businesses and with their owners and managers to overcome the unique challenges they face. This includes helping to build my family’s own distribution business and then orchestrating its multi-million-dollar sale in 2018. Specialising in finance and commercial matters, my expertise and skills span broadly across operations but with a focus in financial accounting and reporting and maintaining & advising on systems, controls & processes related to the finance function."
        },
        {
            id: 21,
            name: "Katrina Langhorn",
            imageURL: require("assets/img/faces/Mentors/MentorKatrinaLanghorn.jpg"),
            mutedText:
                "Clinical Director @ AT Full Potential Psychology Practice ",
            description:
                "Katrina has been practicing as a Registered Psychologist for over 20 years.  She is also a qualified Teacher, specialising in Children and Adolescents with emotional & behavioural disorders, learning difficulties and disabilities. Katrina works in both the Public and Private Sectors and has been a NSW presenter for Positive Partnership NSW (Autism) for the over 10 years.  Katrina has travelled throughout the world and resides in Oakdale, NSW."
        },
        {
            id: 22,
            name: "Sharon Mavros",
            imageURL: require("assets/img/faces/Mentors/MentorSharonMavros.jpg"),
            mutedText: "Multi-site retail leader @ BAKU Swimwear ",
            description:
                "Sharon has over 15 years’ experience as a  Senior Retail Leader, who has been responsible for developing highly successful stores, achieving and exceeding annual top line and EBIT budgets and implementing effective succession planning through structured and impromptu training and coaching. She has been responsible for multimillion-dollar budgets, forecasting of budgets and wages, full P&L management, wage, stock and overall cost control. She has successfully managed underperforming stores on numerous occasions through the identification of KPI & team opportunities and gaps which contribute to performance improvement, resulting in growth of personnel and therefore achieving and exceeding budgets. She has assisted in mentoring of 3 teams in previous Interchange challenges."
        },
        {
            id: 23,
            name: "Christine Di Bella",
            imageURL: require("assets/img/faces/Mentors/MentorChristinediBella.jpg"),
            mutedText:
                "CEO @ All Essentials Safety & Wellbeing and Clinical Hynotherapy",
            description:
                "I am a performance-driven professional with 20+ years’ of experience managing WHS projects. My background includes overseeing overall safety, risk and compliance management programs. Proficient in all areas of compliance, risk and safety management. What I will bring to your company is expertise in the development, implementation and continuous improvement of consistent WHS systems. Moreover, I am able to investigate and identify improvement opportunity in operating standards, systems, and structures and initiate corrective measures to increase efficiency. I am equipped with exceptional people management and influencing skills, coupled with the ability work across the depth and breadth of an organisation and management to achieve shared objectives."
        },
        {
            id: 24,
            name: "Alvin Rajakumar",
            imageURL: require("assets/img/faces/Mentors/MentorAlvinRajakumar.jpg"),
            mutedText:
                "Commercial Leader | Investor | Entrepreneur @ Your Business Live and others",
            description:
                "Marketing strategist responsible for developing and delivering on the overall Marketing & PR strategy. We help you to get in front of new customers actively seeking your product or service! Ambassador & Advisor To The Board of FISH - Foundation for Indigenous Sustainable Health."
        }
    ]
};
