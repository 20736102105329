import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office1 from "assets/img/examples/office1.jpg";
import office2 from "assets/img/examples/office2.jpg";
import office3 from "assets/img/examples/office3.jpg";
import office4 from "assets/img/examples/office4.jpg";
import office5 from "assets/img/examples/office5.jpg";

import { getCaseStudies } from "api/UserService";

const backgrounds = [office1, office2, office3, office4, office5];

const useStyles = makeStyles(projectsStyle);

const CaseStudyList = ({ switchMode }) => {
    const classes = useStyles();
    const [caseStudies, setCaseStudies] = useState([]);

    const createNewForm = caseStudy => {
        switchMode("form", caseStudy);
    };

    const viewForm = caseStudy => {
        switchMode("view", caseStudy);
    };

    useEffect(() => {
        fetchCaseStudies();
    }, []);

    const fetchCaseStudies = async () => {
        const caseStudies = await getCaseStudies();
        setCaseStudies(caseStudies);
        console.log(caseStudies);
    };

    return (
        <GridContainer>
            {caseStudies.map((item, index) => (
                <GridItem key={index} xs={12} sm={6} md={6}>
                    <Card
                        raised
                        background
                        style={{
                            backgroundImage: `url(${backgrounds[index % 5]})`
                        }}
                    >
                        <CardBody background>
                            <h6 className={classes.cardCategory}>Case Study</h6>
                            <a href="#pablito">
                                <h3
                                    className={classNames(
                                        classes.twoLineClamp,
                                        classes.cardTitleWhite
                                    )}
                                >
                                    {item?.answers?.answer1 ||
                                        `Case Study ${index + 1}`}
                                </h3>
                            </a>
                            <div className={classes.cardButtonContainer}>
                                <Button
                                    round
                                    color="danger"
                                    onClick={() => createNewForm(item)}
                                >
                                    Create / Edit
                                </Button>

                                <Button
                                    round
                                    color="primary"
                                    onClick={() => viewForm(item)}
                                >
                                    View
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            ))}
        </GridContainer>
    );
};

CaseStudyList.propTypes = {
    switchMode: PropTypes.func
};

export default CaseStudyList;
