import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        name,
        inputProps,
        control,
        error,
        white,
        inputRootCustomClasses,
        success,
        handleChange,
        defaultValue,
        value
    } = props;
    const classes = useStyles();
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }

    const [counter, setCounter] = useState(defaultValue?.length || 0);

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <>
                            <Input
                                classes={{
                                    input: inputClasses,
                                    root: marginTop,
                                    disabled: classes.disabled,
                                    underline: underlineClasses
                                }}
                                id={id}
                                {...inputProps}
                                inputProps={{
                                    maxLength: inputProps?.maxLength
                                }}
                                {...field}
                                onChange={e => {
                                    if (e.target && e.target.value != null) {
                                        setCounter(e.target.value.length);
                                    }
                                    field.onChange(e);
                                    if (handleChange) {
                                        handleChange(e);
                                    }
                                }}
                            />
                            {props.showCounter && props.inputProps.maxLength && (
                                <span className={classes.textCounter}>
                                    {counter}/{props.inputProps.maxLength}
                                </span>
                            )}
                        </>
                    )}
                />
            ) : (
                <>
                    <Input
                        classes={{
                            input: inputClasses,
                            root: marginTop,
                            disabled: classes.disabled,
                            underline: underlineClasses
                        }}
                        id={id}
                        name={name}
                        {...inputProps}
                        inputProps={{
                            ...inputProps,
                            value: value,
                            multiline:
                                inputProps.multiline &&
                                inputProps.multiline.toString()
                        }}
                        onChange={e => {
                            if (e.target && e.target.value != null) {
                                setCounter(e.target.value.length);
                            }
                            if (handleChange) {
                                handleChange(e);
                            }
                        }}
                    />
                    {props.showCounter && props.inputProps.maxLength && (
                        <span className={classes.textCounter}>
                            {counter}/{props.inputProps.maxLength}
                        </span>
                    )}
                </>
            )}

            {error ? (
                <Clear
                    className={classes.feedback + " " + classes.labelRootError}
                />
            ) : success ? (
                <Check
                    className={
                        classes.feedback + " " + classes.labelRootSuccess
                    }
                />
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    name: PropTypes.string,
    control: PropTypes.object,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    showCounter: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    handleChange: PropTypes.func,
    defaultValue: PropTypes.string
};
