import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { useHistory } from "react-router-dom";

import image from "assets/img/bg7.jpg";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "api/AuthService";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles(loginPageStyle);

const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required()
});

export default function LoginPage() {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(signInSchema)
  });
  const history = useHistory();

  const submitSignInForm = async data => {
    try {
      const response = await login(data);
      localStorage.setItem("token", response);
      history.push("/");
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      console.error(error);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Header absolute color="transparent" brand="INTERCHANGE" />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form
                  onSubmit={handleSubmit(submitSignInForm)}
                  className={classes.form}
                >
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Login</h4>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      name="email"
                      control={control}
                      inputProps={{
                        placeholder: "Email...",
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.email?.message && (
                      <span className={classes.labelError}>
                        {errors.email?.message}
                      </span>
                    )}
                    <CustomInput
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      name="password"
                      control={control}
                      inputProps={{
                        placeholder: "Password",
                        type: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                    {errors.password?.message && (
                      <span className={classes.labelError}>
                        {errors.password?.message}
                      </span>
                    )}
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button
                      type="submit"
                      simple
                      color="primary"
                      size="lg"
                      className={classes.borderOne}
                    >
                      Sign In
                    </Button>
                  </div>
                  <div
                    className={classNames(
                      classes.signUpLink,
                      classes.textCenter
                    )}
                  >
                    <div className={classNames(classes.flex, classes.width60, classes.marginAuto, classes.justifySpaceBetween)}>
                      <Link to="/signup" className={classes.textUnderline}>
                        Sign up
                      </Link>
                      <br/>
                      <Link to="/reset-password" className={classes.textUnderline}>
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
