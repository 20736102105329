import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import Speakerlucas from "assets/img/faces/Speakerlucas.jpeg";
import Speakerchibs from "assets/img/faces/Speakerchibs.jpeg";
// import judgeangela from "assets/img/faces/judgeangela.jpeg";
// import judgeneil from "assets/img/faces/judgeneil.jpeg";
// import judgenashid from "assets/img/faces/judgenashid.jpeg";

const useStyles = makeStyles(teamsStyle);

export default function SectionGuestPresenters({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div className={classes.team}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Our Guest Presenters
                            </h2>
                            <h5 className={classes.description}>
                                Meet our special guest presenters who will run
                                masterclasses teaching us how to be mindful as
                                well as informing us how to test and prototype
                                our ideas
                            </h5>
                        </GridItem>
                    </GridContainer>
                    {/* <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain>
                                <CardAvatar profile plain>
                                    <a
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={Speakerchibs}
                                            alt="..."
                                            className={classes.img}
                                        />
                                    </a>
                                </CardAvatar>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        Chibs Okereke
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            Meditation & Resilience Expert
                                        </h6>
                                    </Muted>
                                    <p className={classes.description}>
                                        Chibs has gone from being a CEO of a
                                        very successful healthcare company to
                                        now being a Mindfulness Coach. He will
                                        support and enable students to perform
                                        at their best by kicking off each
                                        session with a dose of mindfulness and
                                        balance.
                                    </p>
                                </CardBody> */}

                                {/* <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="facebook"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  > */}
                                {/* <i className="fab fa-facebook" />
                  </Button>
                  <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="google"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-google" />
                  </Button> */}
                            {/* </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain>
                                <CardAvatar profile plain>
                                    <a
                                        href=""
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={Speakerlucas}
                                            alt="..."
                                            className={classes.img}
                                        />
                                    </a>
                                </CardAvatar>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        Lucas Hakewell
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            Program Manager, Entrepreneurship
                                            Education
                                        </h6>
                                    </Muted>
                                    <p className={classes.description}>
                                        Lucas is passionate about solving
                                        problems effectively and using the
                                        latest technology tools. He will deliver
                                        a super informative Masterclass teaching
                                        you how to take any idea and make it a
                                        reality.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={Speakerlucas} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Lucas Hakewell</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Program Manager, Entrepreneurship Education
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Lucas loves to solve interesting problems. Formerly working
                    at BT Financial Group and Westpac, Lucas brings an innotive
                    and fresh approach to innovation. Lucas will run a
                    Prototyping Masterclass for the JOONDALUP INNOVATION
                    CHALLENGE.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/lucashakewill/"
                    target="blank"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="dribbble"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-dribbble" /> */}
                        {/* </Button> */}
                        {/* </CardFooter>
              </Card>
            </GridItem> */}{" "}
                    {/* </GridContainer> */}
                </div>
            </div>
        </div>
    );
}
