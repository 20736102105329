import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";
import "./SectionInterested.scss";
import { BlogCard } from "components/Blog/BlogCard/BlogCard";

import { blogpostsdata } from "../../BlogPostsData/BlogPostsData";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
    const classes = useStyles();
    const blogs = [];
    for (const key in blogpostsdata) {
        blogs.push(blogpostsdata[key]);
    }
    return (
        <div className={classes.section}>
            <h3 className={classes.title + " " + classes.textCenter}>
                Enjoy the Read from our Students & Participants
            </h3>
            <br />
            <GridContainer>
                {blogs.map((blog, index) => (
                    <GridItem xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                        <BlogCard
                            heading={blog.title}
                            description={blog.subheading}
                            link={`/blog-post/${blog.id}`}
                            blogImage={blog.blogImage || blog.backgroundImgUrl}
                            backgroundImage={blog.backgroundImgUrl}
                        />
                    </GridItem>
                ))}
            </GridContainer>
        </div>
    );
}
