import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { toast } from "react-toastify";
import { getCaseStudy, saveCaseStudy } from "api/UserService";
import ReactDOM from "react-dom";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Question from "./CaseStudyQuestion/CaseStudyQuestion";
import CaseStudySummary from "../CaseStudySummary/CaseStudySummary";
import { CASE_STUDY_QUESTIONS } from "../Questions";

const useStyles = makeStyles(styles);

function getSteps() {
    return ["", "", "", "", ""];
}

const CaseStudyStepperForm = ({ caseStudyId, switchMode }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [loading, setLoading] = useState(false);
    const [answers, setAnswers] = useState({});

    const submitCaseStudyForm = async data => {
        if (data) {
            await saveCaseStudy(caseStudyId, data);
            toast.success("Saved Case Study Successfully!");
        }
    };

    const loadCaseStudy = async () => {
        try {
            setLoading(true);
            const answers = await getCaseStudy(caseStudyId);
            ReactDOM.unstable_batchedUpdates(() => {
                setAnswers(answers);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    };

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            await submitCaseStudyForm(answers);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    useEffect(() => {
        loadCaseStudy();

        // eslint-disable-next-line
    }, []);

    const handleChange = e => {
        setAnswers({ ...answers, [e.target.name]: e.target.value });
    };

    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <Question
                        name={"answer1"}
                        question={CASE_STUDY_QUESTIONS[0].title}
                        subtitle={CASE_STUDY_QUESTIONS[0].subtitle}
                        inputProps={{
                            multiline: true,
                            rows: 1,
                            type: "text",
                            maxLength: 50
                        }}
                        value={answers["answer1"]}
                        handleChange={handleChange}
                    />
                );
            case 1:
                return (
                    <Question
                        name={"answer2"}
                        question={CASE_STUDY_QUESTIONS[1].title}
                        subtitle={CASE_STUDY_QUESTIONS[1].subtitle}
                        value={answers["answer2"]}
                        handleChange={handleChange}
                        inputProps={{
                            multiline: true,
                            rows: 3,
                            type: "text",
                            maxLength: 400
                        }}
                    />
                );
            case 2:
                return (
                    <Question
                        name={"answer3"}
                        question={CASE_STUDY_QUESTIONS[2].title}
                        subtitle={CASE_STUDY_QUESTIONS[2].subtitle}
                        value={answers["answer3"]}
                        handleChange={handleChange}
                        inputProps={{
                            multiline: true,
                            rows: 3,
                            type: "text",
                            maxLength: 400
                        }}
                    />
                );
            case 3:
                return (
                    <Question
                        name={"answer4"}
                        question={CASE_STUDY_QUESTIONS[3].title}
                        subtitle={CASE_STUDY_QUESTIONS[3].subtitle}
                        value={answers["answer4"]}
                        handleChange={handleChange}
                        inputProps={{
                            multiline: true,
                            rows: 3,
                            type: "text",
                            maxLength: 400
                        }}
                    />
                );
            case 4:
                return (
                    <Question
                        name={"answer5"}
                        question={CASE_STUDY_QUESTIONS[4].title}
                        subtitle={CASE_STUDY_QUESTIONS[4].subtitle}
                        value={answers["answer5"]}
                        handleChange={handleChange}
                        inputProps={{
                            multiline: true,
                            rows: 3,
                            type: "text",
                            maxLength: 400
                        }}
                    />
                );
            default:
                return "Unknown step";
        }
    };
    if (loading) return null;

    return (
        <div className={classes.root}>
            <Stepper
                className={classes.stepper}
                variant="progress"
                activeStep={activeStep}
            >
                {steps.map((label, index) => {
                    const stepProps = {
                        color: "red"
                    };
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <CaseStudySummary
                            caseStudyId={caseStudyId}
                            switchMode={switchMode}
                        />
                    </div>
                ) : (
                    <div>
                        {getStepContent(activeStep)}
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Back
                            </Button>

                            <Button
                                variant="contained"
                                color="rose"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1
                                    ? "Finish"
                                    : "Next"}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    // return (
    //     <GridContainer>
    //         <GridItem xs={12} sm={12} md={12}>
    //             <Card>
    //                 <CardHeader icon="true">
    //                     <CardIcon color="rose">
    //                         <MailOutline />
    //                     </CardIcon>
    //                     <h4 className={classes.cardIconTitle}>Case Study</h4>
    //                 </CardHeader>
    //                 <CardBody>
    //                     <form onSubmit={handleSubmit(submitCaseStudyForm)}>
    // <GridContainer>
    //     <GridItem xs={12} sm={12}>
    //         <FormLabel
    //             className={classes.labelHorizontal}
    //         >
    //             What is the skill that you would like to
    //             write a case study about? (Examples of
    //             skills include Listening, Communication,
    //             Teamwork etc.)
    //         </FormLabel>
    //     </GridItem>
    //     <GridItem xs={12} sm={12}>
    // <CustomInput
    //     id="help-text"
    //     formControlProps={{
    //         fullWidth: true
    //     }}
    //     inputProps={{
    //         multiline: true,
    //         rows: 1,
    //         type: "text",
    //         maxLength: 50
    //     }}
    //     name="answer1"
    //     showCounter={true}
    //     defaultValue={answers["answer1"]}
    //     control={control}
    // />
    //     </GridItem>
    // </GridContainer>
    //                         <GridContainer>
    //                             <GridItem xs={12} sm={12}>
    //                                 <FormLabel
    //                                     className={classes.labelHorizontal}
    //                                 >
    //                                     Describe in detail the situation where
    //                                     you learnt or practiced this skill
    //                                 </FormLabel>
    //                             </GridItem>
    //                             <GridItem xs={12} sm={12}>
    //                                 <CustomInput
    //                                     formControlProps={{
    //                                         fullWidth: true
    //                                     }}
    // inputProps={{
    //     multiline: true,
    //     rows: 3,
    //     type: "text",
    //     maxLength: 200
    // }}
    //                                     name="answer2"
    //                                     defaultValue={answers["answer2"]}
    //                                     showCounter={true}
    //                                     control={control}
    //                                 />
    //                             </GridItem>
    //                         </GridContainer>
    //                         <GridContainer>
    //                             <GridItem xs={12} sm={12}>
    //                                 <FormLabel
    //                                     className={classes.labelHorizontal}
    //                                 >
    //                                     Describe what responsibilities were
    //                                     expected of you in this situation?
    //                                 </FormLabel>
    //                             </GridItem>
    //                             <GridItem xs={12} sm={12}>
    //                                 <CustomInput
    //                                     formControlProps={{
    //                                         fullWidth: true
    //                                     }}
    // inputProps={{
    //     multiline: true,
    //     rows: 3,
    //     type: "text",
    //     maxLength: 200
    // }}
    //                                     name="answer3"
    //                                     defaultValue={answers["answer3"]}
    //                                     showCounter={true}
    //                                     control={control}
    //                                 />
    //                             </GridItem>
    //                         </GridContainer>
    //                         <GridContainer>
    //                             <GridItem xs={12} sm={12}>
    //                                 <FormLabel
    //                                     className={classes.labelHorizontal}
    //                                 >
    //                                     Describe what actions you took in this
    //                                     situation? (Talk about what you did
    //                                     specifically, not others and be detailed
    //                                     in your response)
    //                                 </FormLabel>
    //                             </GridItem>
    //                             <GridItem xs={12} sm={12}>
    //                                 <CustomInput
    //                                     id="help-text"
    //                                     formControlProps={{
    //                                         fullWidth: true
    //                                     }}
    //                                     inputProps={{
    //                                         multiline: true,
    //                                         rows: 3,
    //                                         type: "text",
    //                                         maxLength: 200
    //                                     }}
    //                                     name="answer4"
    //                                     defaultValue={answers["answer4"]}
    //                                     showCounter={true}
    //                                     control={control}
    //                                 />
    //                             </GridItem>
    //                         </GridContainer>
    //                         <GridContainer>
    //                             <GridItem xs={12} sm={12}>
    //                                 <FormLabel
    //                                     className={classes.labelHorizontal}
    //                                 >
    //                                     Describe what results happened because
    //                                     of your actions? (Your results can
    //                                     include any failures or disappointments
    //                                     you had but if this is the case, include
    //                                     what lessons were learnt from this
    //                                     experience)
    //                                 </FormLabel>
    //                             </GridItem>
    //                             <GridItem xs={12} sm={12}>
    //                                 <CustomInput
    //                                     id="help-text"
    //                                     formControlProps={{
    //                                         fullWidth: true
    //                                     }}
    //                                     inputProps={{
    //                                         multiline: true,
    //                                         rows: 3,
    //                                         type: "text",
    //                                         maxLength: 200
    //                                     }}
    //                                     name="answer5"
    //                                     defaultValue={answers["answer5"]}
    //                                     showCounter={true}
    //                                     control={control}
    //                                 />
    //                             </GridItem>
    //                         </GridContainer>
    //                         <Button color="rose" type="submit">
    //                             Save Answer
    //                         </Button>
    //                     </form>
    //                 </CardBody>
    //             </Card>
    //         </GridItem>
    //     </GridContainer>
    // );
};

CaseStudyStepperForm.propTypes = {
    switchMode: PropTypes.func
};

export default CaseStudyStepperForm;
