import React from "react";
// react component for creating beautiful carousel
// import Carousel from "react-slick";
import Slider from "react-slick";
// nodejs library that concatenates classes
import classNames from "classnames";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "assets/css/slick.css";
import "assets/css/slick-theme.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui icons
import Grid from "@material-ui/icons/GridOn";
import PhoneLink from "@material-ui/icons/Phonelink";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import Adb from "@material-ui/icons/AdbOutlined";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// images
import LogosMySkills from "assets/img/assets-for-demo/ourClients/LogoMySkills.jpg";

import LogosWSU from "assets/img/assets-for-demo/ourClients/LogosWSU.jpg";
import LogosUTS from "assets/img/assets-for-demo/ourClients/LogosUTS.jpeg";
import LogosUsyd from "assets/img/assets-for-demo/ourClients/LogosUsyd.jpg";
import LogosStudyNSW from "assets/img/assets-for-demo/ourClients/LogosStudyNSW.png";
import LogosCQU from "assets/img/assets-for-demo/ourClients/LogosCQU.png";
import LogosTorrens from "assets/img/assets-for-demo/ourClients/LogosTorrens.jpg";
import LogoTafeNSW from "assets/img/assets-for-demo/ourClients/LogoTafeNSW.jpeg";
import LogosSCU from "assets/img/assets-for-demo/ourClients/LogosSCU.png";
import FRANKlogo from "assets/img/assets-for-demo/ourClients/FRANKlogo.png";
import people from "assets/img/assets-for-demo/people.png";
import people1 from "assets/img/assets-for-demo/people1.png";
import people2 from "assets/img/assets-for-demo/people2.png";

import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js";
import { SectionOverviewData } from "./SectionOverviewData/SectionOverviewData";

import image1 from "assets/img/bg.jpg";
import image2 from "assets/img/bg2.jpg";
import image3 from "assets/img/bg3.jpg";

const useStyles = makeStyles(overviewStyle);

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

export default function SectionOverview() {
    const classes = useStyles();
    const settings = {
        // dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: "100px",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1234,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
        // arrows: false
        // autoplay: true
    };
    return (
        <div className={classes.section}>
            {/* <div
                className={classes.features5}
                style={{
                    backgroundImage: `url(${require("assets/img/assets-for-demo/features-5.jpg")})`
                }}
            >
                <GridItem
                    md={8}
                    className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                    <h2 className={classes.title}>Our 5 Week Journey</h2>
                </GridItem>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Week 1"
                                description={
                                    <p>
                                        Teams from different universities and
                                        skillsets are introduced to each other
                                        and their mentors. Teams participate in
                                        social teambuilding challenges using
                                        social media and team communication
                                        platforms.
                                    </p>
                                }
                                icon={Grid}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Week 2"
                                description={
                                    <p>
                                        Virtual Summit #1 kicks off with 300
                                        plus students. It is high energy and
                                        engagement with both education and
                                        entertainment being available as well as
                                        recognised global speakers. Students are
                                        introduced to key entrepreneurship
                                        information and start up methodologies.
                                    </p>
                                }
                                icon={PhoneLink}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Week 3"
                                description={
                                    <p>
                                        Virtual Summit #2 kicks off with another
                                        amazing speaker. Students learn how to
                                        wireframe and commercialise their ideas.
                                        Teams finalise their business ideas and
                                        get ready for their pitches. Mentors are
                                        on stand-by to help the teams.
                                    </p>
                                }
                                icon={Adb}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer justify="center">
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Week 4"
                                description={
                                    <p>
                                        Virtual Summit #3 is here and it is all
                                        systems go. This Summit session is about
                                        communication and pitching skills in
                                        preparation for the Live Pitch.
                                        Participants will undergo a shortlisting
                                        process to secure a place at the Virtual
                                        Pitch Night. Mentors keep empowering
                                        their teams.
                                    </p>
                                }
                                icon={AttachMoney}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Week 5"
                                description={
                                    <p>
                                        This is the Virtual Pitch Night infront
                                        of 3 esteemed judges and a captive
                                        audience. Each shortlisted team will
                                        pitch to the judges and 3 winning teams
                                        will be announced.
                                    </p>
                                }
                                icon={AccessTime}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem sm={4} className={classes.featuresShow}>
                            <InfoArea
                                title="Reward & Reflection"
                                description={
                                    <p>
                                        It is the experience of INTERCHANGE
                                        journey that counts; each student who
                                        completes this journey reaps the rewards
                                        including building great friendships and
                                        networks. They also get a chance to
                                        reflect on their self development and
                                        employability journey. Each student gets
                                        access to valuable video learning
                                        resources to continue their
                                        employability success.
                                    </p>
                                }
                                icon={CardGiftcard}
                                iconColor="gray"
                                vertical={true}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div> */}

            <div className={classes.sectionTestimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            <h2 className={classes.title}>
                                Trusted by over 1500+ Students
                            </h2>
                            <h5 className={classes.description}>
                                INTERCHANGE has run for last 7 years with
                                <b> 1500 students</b>{" "}
                            </h5>
                        </GridItem>
                    </GridContainer>

                    {/* <GridContainer>
                        <GridItem md={4} sm={4}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={3}>
                                        <CardHeader image plain>
                                            <img src={people} alt="..." />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={9}>
                                        <CardBody
                                            plain
                                            className={classes.alignLeft}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                INTERCHANGE 2019 Participant
                                            </h4>
                                            <p
                                                className={
                                                    classes.cardDescription
                                                }
                                            >
                                                {'"'}This event is AMAZEBALLS!
                                                {"'"}
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={3}>
                                        <CardHeader image plain>
                                            <img src={people2} alt="..." />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people2 + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people2 + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={9}>
                                        <CardBody
                                            plain
                                            className={classes.alignLeft}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                INTERCHANGE 2019 Participant
                                            </h4>
                                            <p
                                                className={
                                                    classes.cardDescription
                                                }
                                            >
                                                {'"'}This has been a very
                                                inspiring experience for me
                                                {'"'}
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={3}>
                                        <CardHeader image plain>
                                            <img src={people1} alt="..." />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people1 + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                            <div
                                                // className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        "url(" + people1 + ")",
                                                    opacity: "1"
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={9}>
                                        <CardBody
                                            plain
                                            className={classes.alignLeft}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                INTERCHANGE 2019 Participant
                                            </h4>
                                            <p
                                                className={
                                                    classes.cardDescription
                                                }
                                            >
                                                {'"'}It is a great platform to
                                                network and get to learn about
                                                the work culture of Australia
                                                {'"'}
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer> */}

                    <GridContainer className="custom-carousel-margin">
                        <GridItem>
                            <h4 className={classes.cardTitle}>
                                INTERCHANGE 2022 Participant
                            </h4>

                            <Slider {...settings}>
                                {SectionOverviewData[2022].map(
                                    (participant, index) => (
                                        <div key={index}>
                                            <h4 className={classes.cardTitle}>
                                                {participant.name}
                                            </h4>
                                            <div className="video-container">
                                                <iframe
                                                    src={participant.videoURL}
                                                    className="slick-image"
                                                ></iframe>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Slider>
                        </GridItem>
                    </GridContainer>

                    <div className={classes.ourClients}>
                        <GridContainer
                            justify="center"
                            style={{ padding: "10px 0", alignItems: "center" }}
                        >
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosWSU}
                                    alt="Western Sydney University"
                                />
                            </GridItem>

                            <GridItem md={2} sm={2}>
                                <img src={LogosUTS} alt="UTS" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosUsyd}
                                    alt="University of Sydney"
                                />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosSCU} alt="SCU" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosCQU} alt="CQU" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosTorrens}
                                    alt="Torrens University"
                                />
                            </GridItem>
                            
                            <GridItem md={2} sm={2}>
                                <img src={LogoTafeNSW} alt="TAFE NSW" />
                            </GridItem>

                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosMySkills}
                                    alt="My Skills Australia"
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <div className={classes.sectionTestimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            <div className={classes.ourClients}>
                                <GridContainer justify="center">
                                    <GridItem md={12} sm={12}>
                                        <img
                                            src={LogosStudyNSW}
                                            alt="Study NSW"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.sectionTestimonials}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                md={8}
                                className={classNames(
                                    classes.mlAuto,
                                    classes.mrAuto
                                )}
                            >
                                <h2 className={classes.title}>Powered by</h2>
                                <div className={classes.ourClients}>
                                    <GridContainer justify="center">
                                        <GridItem md={12} sm={12}>
                                            <img
                                                src={FRANKlogo}
                                                alt="franked.io"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
