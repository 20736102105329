export const CASE_STUDY_QUESTIONS = [
    {
        title:
            "What is the skill that you would like to write a case study about?",
        subtitle:
            "(Examples of skills include Listening, Communication, Teamwork etc.)"
    },
    {
        title:
            "Describe in detail the situation where you learnt or practiced this skill.",
        subtitle: ""
    },
    {
        title:
            "Describe what responsibilities were expected of you in this situation?",
        subtitle: ""
    },
    {
        title: "Describe what actions you took in this situation?",
        subtitle:
            "(Talk about what you did specifically, not others and be detailed in your response)"
    },
    {
        title: "Describe what results happened because of your actions?",
        subtitle:
            "(Your results can include any failures or disappointments you had but if this is the case, include what lessons were learnt from this experience)"
    }
];
