import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import city from "assets/img/ContactUs.jpg";
import { BlogFooter } from "components/Blog/BlogFooter/BlogFooter";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

class ContactUsPage extends React.Component {
    componentDidMount() {
        window.scrollTo(1, 0);
        document.body.scrollTop = 0;
    }
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Header
                    color="info"
                    brand="INTERCHANGE is brought to you by franked.io"
                    links={<HeaderLinks dropdownHoverColor="info" />}
                    fixed
                    changeColorOnScroll={{
                        height: 300,
                        color: "info"
                    }}
                    {...rest}
                />
                <div
                    className={`${classes.contacts}`}
                    style={{
                        backgroundImage: `url(${city})`,
                        height: "300px"
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer />
                    </div>
                </div>
                {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
                <div className={classes.contactContent}>
                    <div className={classes.container}>
                        <h2 className={classes.title}>Get in Touch</h2>
                        <GridContainer>
                            <GridItem md={6} sm={6}>
                                <p>
                                    Have some questions about INTERCHANGE? We'd
                                    love to hear from you.
                                    <br />
                                </p>
                                {/* <form>
                    <CustomInput
                      labelText="Your Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Email address"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Phone"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Your message"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button color="primary" round>
                        Contact us
                      </Button>
                    </div>
                  </form> */}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <div className={classes.container}>
                                <GridItem md={12} sm={12}>
                                    <InfoArea
                                        className={
                                            (classes.info,
                                            classes.left,
                                            classes.inlineBlock)
                                        }
                                        title="Find us at the office"
                                        description={
                                            <p>
                                                Suite 29/20-40 Meagher St,{" "}
                                                <br /> Chippendale NSW 2008
                                            </p>
                                        }
                                        icon={PinDrop}
                                        iconColor="info"
                                        justifyContent="500px"
                                    />
                                </GridItem>
                                <GridItem>
                                    <InfoArea
                                        className={
                                            (classes.left, classes.inlineBlock)
                                        }
                                        title="Give us a ring"
                                        description={
                                            <p>
                                                1300 953 133 <br /> Mon - Fri,
                                                9:00am-5:00pm
                                            </p>
                                        }
                                        icon={Phone}
                                        iconColor="info"
                                    />
                                </GridItem>
                                <GridItem>
                                    <InfoArea
                                        className={
                                            (classes.left, classes.inlineBlock)
                                        }
                                        title="Email us"
                                        description={
                                            <p>info@frankteam.com.au</p>
                                        }
                                        icon={BusinessCenter}
                                        iconColor="info"
                                    />
                                </GridItem>
                            </div>
                        </GridContainer>
                    </div>
                </div>
                <BlogFooter />
            </div>
        );
    }
}

export default withStyles(contactUsStyle)(ContactUsPage);
