/*eslint-disable*/
import React, { useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import { BlogFooter } from "components/Blog/BlogFooter/BlogFooter";

import ProfilePlaceholder from "assets/img/profile_placeholder.png";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import EditIcon from "@material-ui/icons/Edit";
import { uploadProfileImage, getUserProfile } from "api/UserService";
import CardIcon from "components/Card/CardIcon";
import { PermIdentity } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import CardAvatar from "components/Card/CardAvatar";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserProfile } from "api/UserService";
import { ToastContainer, toast } from "react-toastify";
import GrowthJourney from "./GrowthJourney/GrowthJourney";
import SkillSummary from "./SkillSummary/SkillSummary";
import CaseStudy from "./CaseStudy/CaseStudy";

const useStyles = makeStyles(profilePageStyle);

const updateProfileSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    institution: yup.string(),
    degree: yup.string()
});

export default function ProfilePage({ ...rest }) {
    // fileInput must be declared here so the ref can refer to it
    const fileInput = useRef(null);
    const [profileImage, setProfileImage] = useState(ProfilePlaceholder);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [institution, setInstitution] = useState("");
    const [degree, setDegree] = useState("");

    const [submitted, setSubmitted] = React.useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(updateProfileSchema)
    });
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    // Fetch profile details
    const getProfileData = async () => {
        const { data } = await getUserProfile();
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setDegree(data.degree?.name);
        setInstitution(data.institution?.name);

        setValue("firstName", data.firstName, { shouldValidate: true });
        setValue("lastName", data.lastName, { shouldValidate: true });
        setValue("degree", data.degree?.name, { shouldValidate: true });
        setValue("institution", data.institution?.name, {
            shouldValidate: true
        });

        if (data.key) {
            setProfileImage(data.profileImage);
        }
    };

    const onFileInputChange = async e => {
        const files = Array.from(e.target.files);
        const formData = new FormData();

        if (files && files[0]) {
            formData.append("file", files[0], files[0].fileName);
        }

        const response = await uploadProfileImage(formData);
        setProfileImage(response.data.Location);
    };

    const submitUpdateProfileForm = async data => {
        try {
            await updateUserProfile(data);
            setSubmitted(true);
            getProfileData();
            toast.success("Updated Profile Successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        } catch (error) {
            toast.error("Something went wrong!", {
                hideProgressBar: true
            });
            console.error(error);
        }
    };

    // when called, triggers fileInput click function
    const triggerInputFile = () => {
        if (fileInput.current && fileInput.current.click)
            fileInput.current.click();
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        getProfileData();
    }, []);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <Header
                color="transparent"
                brand="INTERCHANGE"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "info"
                }}
                {...rest}
            />
            <Parallax
                image={require("assets/img/examples/city.jpg")}
                filter="dark"
                className={classes.parallax}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div
                    className={classNames(
                        classes.editProfileContainer,
                        classes.container
                    )}
                >
                    <div>
                        <form onSubmit={handleSubmit(submitUpdateProfileForm)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={8}>
                                    <Card>
                                        <CardHeader
                                            className={
                                                classes.editProfileHeader
                                            }
                                        >
                                            <CardIcon color="rose">
                                                <PermIdentity />
                                            </CardIcon>
                                            <h4
                                                className={
                                                    classes.cardIconTitle
                                                }
                                            >
                                                Edit Profile -{" "}
                                                <small>
                                                    Complete your profile
                                                </small>
                                            </h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                >
                                                    <CustomInput
                                                        labelText="First Name"
                                                        id="first-name"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        name="firstName"
                                                        control={control}
                                                    />
                                                    {errors.firstName
                                                        ?.message && (
                                                        <span
                                                            className={
                                                                classes.labelError
                                                            }
                                                        >
                                                            {
                                                                errors.firstName
                                                                    ?.message
                                                            }
                                                        </span>
                                                    )}
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                >
                                                    <CustomInput
                                                        labelText="Last Name"
                                                        id="last-name"
                                                        name="lastName"
                                                        control={control}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                    {errors.lastName
                                                        ?.message && (
                                                        <span
                                                            className={
                                                                classes.labelError
                                                            }
                                                        >
                                                            {
                                                                errors.lastName
                                                                    ?.message
                                                            }
                                                        </span>
                                                    )}
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                >
                                                    <CustomInput
                                                        labelText="Educational Institution"
                                                        id="institution"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        name="institution"
                                                        control={control}
                                                    />
                                                    {errors.institution
                                                        ?.message && (
                                                        <span
                                                            className={
                                                                classes.labelError
                                                            }
                                                        >
                                                            {
                                                                errors
                                                                    .institution
                                                                    ?.message
                                                            }
                                                        </span>
                                                    )}
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                >
                                                    <CustomInput
                                                        labelText="Degree"
                                                        id="degree"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        name="degree"
                                                        control={control}
                                                    />
                                                    {errors.degree?.message && (
                                                        <span
                                                            className={
                                                                classes.labelError
                                                            }
                                                        >
                                                            {
                                                                errors.degree
                                                                    ?.message
                                                            }
                                                        </span>
                                                    )}
                                                </GridItem>
                                            </GridContainer>
                                            <Button
                                                color="rose"
                                                className={
                                                    classes.updateProfileButton
                                                }
                                                type="submit"
                                            >
                                                Update Profile
                                            </Button>
                                            <Clearfix />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <Card profile={true}>
                                        <CardAvatar profile={true}>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={profileImage}
                                                    alt="..."
                                                />
                                            </a>
                                        </CardAvatar>
                                        <input
                                            ref={fileInput}
                                            type="file"
                                            accept="image/*"
                                            onChange={onFileInputChange}
                                            className={classes.fileInput}
                                        ></input>
                                        <div
                                            className={classes.editButton}
                                            onClick={triggerInputFile}
                                        >
                                            <EditIcon />
                                        </div>
                                        <CardBody profile="true">
                                            <h4 className={classes.cardTitle}>
                                                {firstName} {lastName}
                                            </h4>
                                            {institution && (
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    {institution}
                                                </h6>
                                            )}
                                            {degree && (
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    {degree}
                                                </h6>
                                            )}
                                            {/* <p className={classes.description}>
                        Don{"'"}t be scared of the truth because we need to restart the
                  human foundation in truth And I love you like Kanye loves Kanye
                  I love Rick Owens’ bed design but the back is...
                </p> */}
                                            {/* <Button color="rose" round>
                        Follow
                      </Button> */}
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </div>
                    <div className={classes.profileTabs}>
                        <NavPills
                            color="rose"
                            tabs={[
                                {
                                    tabButton: "INTERCHANGE Growth Journey",
                                    tabContent: <GrowthJourney />
                                },
                                {
                                    tabButton: "Skill Summary",
                                    tabContent: <SkillSummary />
                                },
                                {
                                    tabButton: "INTERCHANGE Experiences",
                                    tabContent: <CaseStudy />
                                }
                            ]}
                        />
                        {/* <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Work",
                  tabIcon: Palette,
                  tabContent: (
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={7}
                        className={classes.gridItem}
                      >
                        <h4 className={classes.title}>Latest Collections</h4>
                        <GridContainer className={classes.collections}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Card
                              background
                              style={{
                                backgroundImage: "url(" + mariyaGeorgieva + ")"
                              }}
                            >
                              <CardBody background className={classes.cardBody}>
                                <Badge
                                  color="warning"
                                  className={classes.badge}
                                >
                                  Spring 2016
                                </Badge>
                                <a href="#pablo">
                                  <h2 className={classes.cardTitleWhite}>
                                    Stilleto
                                  </h2>
                                </a>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Card
                              background
                              style={{
                                backgroundImage: "url(" + clemOnojeghuo + ")"
                              }}
                            >
                              <CardBody background className={classes.cardBody}>
                                <Badge color="info" className={classes.badge}>
                                  Spring 2016
                                </Badge>
                                <a href="#pablo">
                                  <h2 className={classes.cardTitleWhite}>
                                    High Heels
                                  </h2>
                                </a>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Card
                              background
                              style={{
                                backgroundImage: "url(" + oluEletu + ")"
                              }}
                            >
                              <CardBody background className={classes.cardBody}>
                                <Badge color="danger" className={classes.badge}>
                                  Summer 2016
                                </Badge>
                                <a href="#pablo">
                                  <h2 className={classes.cardTitleWhite}>
                                    Flats
                                  </h2>
                                </a>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Card
                              background
                              style={{
                                backgroundImage: "url(" + darrenColeshill + ")"
                              }}
                            >
                              <CardBody background className={classes.cardBody}>
                                <Badge
                                  color="success"
                                  className={classes.badge}
                                >
                                  Winter 2016
                                </Badge>
                                <a href="#pablo">
                                  <h2 className={classes.cardTitleWhite}>
                                    Men{"'"}s Sneakers
                                  </h2>
                                </a>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={2}
                        className={classes.gridItem}
                      >
                        <h4 className={classes.title}>Stats</h4>
                        <ul className={classes.listUnstyled}>
                          <li>
                            <b>60</b> Products
                          </li>
                          <li>
                            <b>4</b> Collections
                          </li>
                          <li>
                            <b>331</b> Influencers
                          </li>
                          <li>
                            <b>1.2K</b> Likes
                          </li>
                        </ul>
                        <hr />
                        <h4 className={classes.title}>About this work</h4>
                        <p className={classes.description}>
                          French luxury footwear and fashion. The footwear has
                          incorporated shiny, red-lacquered soles that have
                          become his signature.
                        </p>
                        <hr />
                        <h4 className={classes.title}>Focus</h4>
                        <Badge color="primary">Footwear</Badge>
                        <Badge color="rose">Luxury</Badge>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Connections",
                  tabIcon: People,
                  tabContent: (
                    <div>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={5}
                          className={classes.gridItem}
                        >
                          <Card profile={true} plain className={classes.card}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={5}>
                                <CardHeader image plain>
                                  <a href="#pablo">
                                    <img src={avatar} alt="..." />
                                  </a>
                                  <div
                                    className={classes.coloredShadow}
                                    style={{
                                      backgroundImage: "url(" + avatar + ")",
                                      opacity: "1"
                                    }}
                                  />
                                </CardHeader>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={7}>
                                <CardBody plain>
                                  <h4 className={classes.cardTitle}>
                                    Gigi Hadid
                                  </h4>
                                  <Muted>
                                    <h6>MODEL</h6>
                                  </Muted>
                                  <p className={classes.description}>
                                    Don{"'"}t be scared of the truth because we
                                    need to restart the human foundation in
                                    truth...
                                  </p>
                                </CardBody>
                              </GridItem>
                            </GridContainer>
                          </Card>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={5}
                          className={classes.gridItem}
                        >
                          <Card profile={true} plain className={classes.card}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={5}>
                                <CardHeader image plain>
                                  <a href="#pablo">
                                    <img src={marc} alt="..." />
                                  </a>
                                  <div
                                    className={classes.coloredShadow}
                                    style={{
                                      backgroundImage: "url(" + marc + ")",
                                      opacity: "1"
                                    }}
                                  />
                                </CardHeader>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={7}>
                                <CardBody plain>
                                  <h4 className={classes.cardTitle}>
                                    Marc Jacobs
                                  </h4>
                                  <Muted>
                                    <h6>DESIGNER</h6>
                                  </Muted>
                                  <p className={classes.description}>
                                    Don{"'"}t be scared of the truth because we
                                    need to restart the human foundation in
                                    truth...
                                  </p>
                                </CardBody>
                              </GridItem>
                            </GridContainer>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={5}
                          className={classes.gridItem}
                        >
                          <Card profile={true} plain className={classes.card}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={5}>
                                <CardHeader image plain>
                                  <a href="#pablo">
                                    <img src={kendall} alt="..." />
                                  </a>
                                  <div
                                    className={classes.coloredShadow}
                                    style={{
                                      backgroundImage: "url(" + kendall + ")",
                                      opacity: "1"
                                    }}
                                  />
                                </CardHeader>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={7}>
                                <CardBody plain>
                                  <h4 className={classes.cardTitle}>
                                    Kendall Jenner
                                  </h4>
                                  <Muted>
                                    <h6>MODEL</h6>
                                  </Muted>
                                  <p className={classes.description}>
                                    I love you like Kanye loves Kanye. Don
                                    {"'"}t be scared of the truth.
                                  </p>
                                </CardBody>
                              </GridItem>
                            </GridContainer>
                          </Card>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={5}
                          className={classes.gridItem}
                        >
                          <Card profile={true} plain className={classes.card}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={5}>
                                <CardHeader image plain>
                                  <a href="#pablo">
                                    <img src={cardProfile2Square} alt="..." />
                                  </a>
                                  <div
                                    className={classes.coloredShadow}
                                    style={{
                                      backgroundImage:
                                        "url(" + cardProfile2Square + ")",
                                      opacity: "1"
                                    }}
                                  />
                                </CardHeader>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={7}>
                                <CardBody plain>
                                  <h4 className={classes.cardTitle}>
                                    George West
                                  </h4>
                                  <Muted>
                                    <h6>MODEL/DJ</h6>
                                  </Muted>
                                  <p className={classes.description}>
                                    I love you like Kanye loves Kanye.
                                  </p>
                                </CardBody>
                              </GridItem>
                            </GridContainer>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )
                },
                {
                  tabButton: "Media",
                  tabIcon: Camera,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={3}>
                        <img
                          alt="..."
                          src={mariyaGeorgieva}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={clemOnojegaw}
                          className={navImageClasses}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <img
                          alt="..."
                          src={clemOnojeghuo}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={oluEletu}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={cynthiaDelRio}
                          className={navImageClasses}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            /> */}
                    </div>
                    <Clearfix />
                </div>
            </div>
            <BlogFooter />
        </div>
    );
}
