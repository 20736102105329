import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// images
import LogosWSU from "assets/img/assets-for-demo/ourClients/LogosWSU.jpg";
import LogosACU from "assets/img/assets-for-demo/ourClients/LogosACU.jpg";
import LogosUTS from "assets/img/assets-for-demo/ourClients/LogosUTS.jpeg";
import LogosUsyd from "assets/img/assets-for-demo/ourClients/LogosUsyd.jpg";
import LogosUNSW from "assets/img/assets-for-demo/ourClients/LogosUNSW.jpeg";
import LogosStudyNSW from "assets/img/assets-for-demo/ourClients/LogosStudyNSW.png";
import LogoTafeNSW from "assets/img/assets-for-demo/ourClients/LogoTafeNSW.jpeg";
import LogosCQU from "assets/img/assets-for-demo/ourClients/LogosCQU.png";
import FRANKLogo from "assets/img/assets-for-demo/ourClients/FRANKlogo.png";
import LogosTorrens from "assets/img/assets-for-demo/ourClients/LogosTorrens.jpeg";
import LogosSCU from "assets/img/assets-for-demo/ourClients/LogosSCU.png";

import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js";

const useStyles = makeStyles(overviewStyle);

export default function SectionOverview() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.sectionTestimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            <h2 className={classes.title}>
                                Trusted by over 1500+ Students
                            </h2>
                            <h5 className={classes.description}>
                                INTERCHANGE has run for last 7 years with
                                <b> 1500 students</b>{" "}
                            </h5>
                        </GridItem>
                    </GridContainer>

                    <div className={classes.ourClients}>
                        <GridContainer
                            justify="center"
                            style={{ padding: "10px 0", alignItems: "center" }}
                        >
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosWSU}
                                    alt="Western Sydney University"
                                />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosACU}
                                    alt="Australian Catholic University"
                                />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosUNSW} alt="UNSW" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosUTS} alt="UTS" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosUsyd}
                                    alt="University of Sydney"
                                />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosSCU} alt="SCU" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogosCQU} alt="CQU" />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img
                                    src={LogosTorrens}
                                    alt="Torrens University"
                                />
                            </GridItem>
                            <GridItem md={2} sm={2}>
                                <img src={LogoTafeNSW} alt="TAFE NSW" />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <div className={classes.sectionTestimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            {/* <h2 className={classes.title}>Powered by</h2> */}
                            <div className={classes.ourClients}>
                                <GridContainer justify="center">
                                    <GridItem md={12} sm={12}>
                                        <img
                                            src={LogosStudyNSW}
                                            alt="Study NSW"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.sectionTestimonials}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                md={8}
                                className={classNames(
                                    classes.mlAuto,
                                    classes.mrAuto
                                )}
                            >
                                <h2 className={classes.title}>Powered by</h2>
                                <div className={classes.ourClients}>
                                    <GridContainer justify="center">
                                        <GridItem md={6} sm={6}>
                                            <img
                                                src={FRANKLogo}
                                                alt="franked.io"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
