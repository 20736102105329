import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import KeynoteAbdulRazak from "assets/img/faces/KeynoteAbdulRazak.JPG";
import KeynoteMelanieGreblo from "assets/img/faces/KeynoteMelanieGreblo.jpg";
import SpeakerKayla from "assets/img/faces/SpeakerKayla.JPG";
import SpeakerUzair from "assets/img/faces/SpeakerUzair.jpeg";
import SpeakerMadeleine from "assets/img/faces/SpeakerMadeleine.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionSpeakers({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div className={classes.team}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Our Keynote Speakers
                            </h2>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Card profile plain>
                                        <CardAvatar profile plain>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={KeynoteAbdulRazak}
                                                    alt="..."
                                                    className={classes.img}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "200px"
                                                    }}
                                                />
                                            </a>
                                        </CardAvatar>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>
                                                Abdul Razak
                                            </h4>
                                            <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Director, Maple Services
                                                </h6>
                                            </Muted>
                                            <p className={classes.description}>
                                                Abdul Razak, a 30-year-old
                                                entrepreneur from Western
                                                Sydney, is a three-time Sydney
                                                Young Entrepreneur of the Year
                                                award recipient. As the founder
                                                of Maple Services, Abdul has
                                                established a formidable
                                                business empire recognized by
                                                Business News Australia.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Card profile plain>
                                        <CardAvatar profile plain>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={KeynoteMelanieGreblo}
                                                    alt="..."
                                                    className={classes.img}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "200px"
                                                    }}
                                                />
                                            </a>
                                        </CardAvatar>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>
                                                Melanie Greblo
                                            </h4>
                                            <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Founder & CEO at Banksia
                                                    Academy
                                                </h6>
                                            </Muted>
                                            <p className={classes.description}>
                                                Mel's driving purpose has been
                                                to catalyse and lead positive
                                                impact. She is currently Founder
                                                & CEO at Scriibed and Banksia
                                                Academy with a shared Theory of
                                                Change to achieve long term
                                                financial independence for women
                                                victim survivors of domestic and
                                                family violence through full
                                                social and economic
                                                participation.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>

                    {/* <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card profile plain>
                                <CardAvatar profile plain>
                                    <a
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={SpeakerKayla}
                                            alt="..."
                                            className={classes.img}
                                            style={{
                                                objectFit: "cover",
                                                height: "150px"
                                            }}
                                        />
                                    </a>
                                </CardAvatar>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        Kayla Caruso
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            FOUNDER & MANAGING DIRECTOR, MUSIC
                                            LESSONS ACADEMY
                                        </h6>
                                    </Muted>
                                    <p className={classes.description}>
                                        Kayla Caruso founded Music Lessons
                                        Australia as a modest endeavour to pay
                                        her way through university. Music
                                        Lessons Australia is a network of
                                        enthusiastic university students
                                        providing a fresh approach to private
                                        music lessons in homes across Australia.
                                        Kayla's innate understanding of an old
                                        staid industry approach, along with her
                                        unparalleled willpower, has resulted in
                                        a fast and ever-growing community of
                                        550+ students learning across Australia
                                        and is quickly propelling her music
                                        lessons formula to the forefront of the
                                        musical education industry.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card profile plain>
                                <CardAvatar profile plain>
                                    <a
                                        href=""
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={SpeakerMadeleine}
                                            alt="..."
                                            className={classes.img}
                                        />
                                    </a>
                                </CardAvatar>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        Madeleine Buchner OAM
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            FOUNDER & CEO, LITTLE DREAMERS
                                            AUSTRALIA
                                        </h6>
                                    </Muted>
                                    <p className={classes.description}>
                                        Madeleine is an entrepreneur and social
                                        innovator with a passion for supporting
                                        people often overlooked in the
                                        Australian community, Young Carers.
                                        Fuelled by this passion for helping
                                        others, Madeleine founded Little
                                        Dreamers Australia at age 16 to support
                                        Young Carers through the provision of
                                        Dream Experiences, Education and
                                        Advocacy. Having raised over $1.5m and
                                        supported more than 4000 families in
                                        Australia, Little Dreamers is now an
                                        internationally recognised organisation
                                        that believes in a world where Young
                                        Carers have someone to care for them. In
                                        2017, Madeleine was named as a 2017
                                        Queen's Young Leader award recipient,
                                        where she was presented the award medal
                                        by Her Majesty Queen Elizabeth II.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card profile plain>
                                <CardAvatar profile plain>
                                    <a
                                        href=""
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={SpeakerUzair}
                                            alt="..."
                                            className={classes.img}
                                        />
                                    </a>
                                </CardAvatar>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        Uzair Moosa
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            COO, BRIGHTE
                                        </h6>
                                    </Muted>
                                    <p className={classes.description}>
                                        Uzair Moosa is a dynamic leader who has
                                        led transformation results in fin-tech,
                                        food-tech, marketplace, aviation and the
                                        technology world. His work and education
                                        experience span Australia, Asia, Middle
                                        East and Europe. Uzair is currently the
                                        Chief Operations Officer at Brighte –
                                        helping households get financing for any
                                        appliances that help reduce carbon
                                        emissions. Uzair led Hey You - the
                                        largest order ahead business in
                                        Australia and joined them at a very
                                        critical time when the business was
                                        losing $ 4 million a year and competing
                                        with a global much better funded
                                        competitor. During his time at Hey You,
                                        he was able to turn around the business
                                        to profitability and outcompeted the
                                        global competitor. He has had the
                                        pleasure of introducing the ride hailing
                                        app industry in Saudi Arabia by
                                        launching Careem in KSA in 2013. He was
                                        the first employee and has since led
                                        Careem to be the market leader with more
                                        than 6 million happy customers and
                                        150,000+ satisfied captains driving for
                                        Careem. Uzair has also worked at some of
                                        the top multi national companies such as
                                        Cisco and KPMG. Uzair has an MBA from
                                        INSEAD and a Bachelors from LUMS.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer> */}
                </div>
            </div>
        </div>
    );
}
