export const SectionOverviewData = {
    2022: [
        {
            id: 1,
            name: "Apoorv Maroo",
            videoURL: "https://www.youtube.com/embed/lx6p00wT2UM"
        },
        {
            id: 2,
            name: "Tanya Sheehan",
            videoURL: "https://www.youtube.com/embed/xJACeVkAGag"
        },
        {
            id: 3,
            name: "Kenia Marcano",
            videoURL: "https://www.youtube.com/embed/awBh7B2nma4"
        },
        {
            id: 4,
            name: "Rachael Campion",
            videoURL: "https://www.youtube.com/embed/8xdu1PfycwI"
        }
    ]
};
