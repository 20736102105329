import React, { useState } from "react";

import CaseStudyList from "./CaseStudyList/CaseStudyList";
import CaseStudyStepperForm from "./CaseStudyStepperForm/CaseStudyStepperForm";
import CaseStudySummary from "./CaseStudySummary/CaseStudySummary";

const CaseStudy = () => {
    const [mode, setMode] = useState("list");
    const [caseStudy, setCaseStudy] = useState(null);
    const switchMode = (type, caseStudy) => {
        setCaseStudy(caseStudy);
        setMode(type);
    };

    switch (mode) {
        case "list": {
            return <CaseStudyList switchMode={switchMode} />;
        }
        case "view": {
            return (
                <CaseStudySummary
                    caseStudyId={caseStudy.id}
                    switchMode={switchMode}
                />
            );
        }
        case "form": {
            return (
                <CaseStudyStepperForm
                    caseStudyId={caseStudy.id}
                    switchMode={switchMode}
                />
            );
        }
    }
};

export default CaseStudy;
