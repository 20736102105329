import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
// import BlogPostPageSupritha from "views/BlogPostPageSupritha/BlogPostPageSupritha.js";
// import BlogPostPageAbdul from "views/BlogPostPageAbdul/BlogPostPageAbdul.js";
// import BlogPostPageCheryl from "views/BlogPostPageCheryl/BlogPostPageCheryl.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
// import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
// import EcommercePage from "views/EcommercePage/EcommercePage.js";
// import LandingPage from "views/LandingPage/LandingPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
// import PricingPage from "views/PricingPage/PricingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
// import ProductPage from "views/ProductPage/ProductPage.js";
// import SectionsPage from "views/SectionsPage/SectionsPage.js";
// import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import TeamsPage from "views/TeamsPage/TeamsPage.js";
import MentorPage from "views/MentorPage/MentorPage.js";
import Mentors2021Page from "views/Mentors2021Page/Mentors2021Page.js";
import MentorFAQPage from "views/MentorFAQPage/MentorFAQPage.js";
import ParticipantFAQPage from "views/ParticipantFAQPage/ParticipantFAQPage.js";
import ParticipantSchedulePage from "views/ParticipantSchedulePage/ParticipantSchedulePage.js";
import PrizesPage from "views/PrizesPage/PrizesPage.js";
// import { getBlogPage } from "utils/BlogUtils.js";
// import { blogpostsdata } from "views/BlogPostsData/BlogPostsData.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/PublicRoute/PublicRoute";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { VerifySuccessPage } from "views/VerifySuccessPage/VerifySuccessPage";
import ResetPasswordPage from "views/LoginPage/ResetPasswordPage";
import ChangePasswordPage from "views/LoginPage/ChangePasswordPage";

var hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <PublicRoute
                restricted={true}
                path="/signin"
                component={LoginPage}
            />
            <PublicRoute
                restricted={true}
                path="/signup"
                component={SignupPage}
            />
            <PublicRoute path="/verify-success" component={VerifySuccessPage} />
            <PublicRoute
                path="/reset-password/:id"
                component={ChangePasswordPage}
            />
            <PublicRoute path="/reset-password" component={ResetPasswordPage} />
            <PublicRoute path="/about-us" component={AboutUsPage} />
            <PublicRoute path="/teams/:year" component={TeamsPage} />
            <PublicRoute path="/mentors/:year" component={MentorPage} />
            <PublicRoute path="/mentors2021" component={Mentors2021Page} />
            <PublicRoute path="/mentor-information" component={MentorFAQPage} />
            <PublicRoute
                path="/participant-information"
                component={ParticipantFAQPage}
            />
            <PublicRoute path="/schedule" component={ParticipantSchedulePage} />
            <PublicRoute path="/blog-post/:blogId" component={BlogPostPage} />
            <PublicRoute path="/blog-posts" component={BlogPostsPage} />
            <PublicRoute path="/prizes" component={PrizesPage} />
            <PrivateRoute path="/profile-page" component={ProfilePage} />

            {/* <Route path="/components" component={ComponentsPage} /> */}
            <PublicRoute path="/contact-us" component={ContactUsPage} />
            {/* <Route path="/ecommerce-page" component={EcommercePage} />
      <Route path="/landing-page" component={LandingPage} />
      
      <Route path="/pricing" component={PricingPage} />
      
      <Route path="/product-page" component={ProductPage} />
      <Route path="/sections" component={SectionsPage} />
      <Route path="/shopping-cart-page" component={ShoppingCartPage} />
       */}
            <PublicRoute path="/error-page" component={ErrorPage} />
            <PublicRoute path="/" component={PresentationPage} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
