import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
    const classes = useStyles();
    return (
        <div
            className={classNames(classes.aboutDescription, classes.textCenter)}
        >
            <GridContainer>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h5 className={classes.description}>
                        INTERCHANGE is a{" "}
                        <a
                            href="https://www.study.nsw.gov.au/industry/partner-projects"
                            target="new window"
                        >
                            Study NSW Partner Project{" "}
                        </a>{" "}
                        in collaboration with 7 outstanding educational
                        institutions. 150 international and local students have
                        a one-of-a-kind platform of theoretical and practical
                        employability learning, where they can establish and
                        extend both their local and international networks. Over
                        2.5 weeks inter-institutional teams create a simulated
                        start-up, identifying and addressing real-life problems
                        facing humanity. The student teams are supported and
                        mentored by previous participants and professional
                        industry experts. Students work and ideate virtually,
                        learning and applying the latest technology tools.
                    </h5>
                </GridItem>
            </GridContainer>
        </div>
    );
}
