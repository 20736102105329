import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import { registerUser } from "../../api/AuthService";
import image from "assets/img/bg7.jpg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { SignupComplete } from "./SignupComplete";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";

const signUpSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    password: yup.string().required(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required()
});

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage({ ...rest }) {
    const [submitted, setSubmitted] = React.useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(signUpSchema)
    });

    const submitRegisterForm = async data => {
        try {
            await registerUser(data);
            setSubmitted(true);
        } catch (error) {
            toast.error(error.response.data);
        }
    };

    const isFormValid = () => {
        return true;
    };

    const classes = useStyles();
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <Header
                absolute
                color="transparent"
                brand="INTERCHANGE"
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                {submitted ? (
                                    <SignupComplete />
                                ) : (
                                    <>
                                        <h2 className={classes.cardTitle}>
                                            Register
                                        </h2>
                                        <CardBody>
                                            <GridContainer justify="center">
                                                {/* <GridItem xs={12} sm={5} md={5}>
                                                    <InfoArea
                                                        className={
                                                            classes.infoArea
                                                        }
                                                        title="Marketing"
                                                        description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                                                        icon={Timeline}
                                                        iconColor="rose"
                                                    />
                                                    <InfoArea
                                                        className={
                                                            classes.infoArea
                                                        }
                                                        title="Fully Coded in HTML5"
                                                        description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                                                        icon={Code}
                                                        iconColor="primary"
                                                    />
                                                    <InfoArea
                                                        className={
                                                            classes.infoArea
                                                        }
                                                        title="Built Audience"
                                                        description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                                                        icon={Group}
                                                        iconColor="info"
                                                    />
                                                </GridItem> */}
                                                <GridItem xs={12} sm={5} md={5}>
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            submitRegisterForm
                                                        )}
                                                        className={classes.form}
                                                    >
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className:
                                                                    classes.customFormControlClasses
                                                            }}
                                                            name="firstName"
                                                            control={control}
                                                            inputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        <Face
                                                                            className={
                                                                                classes.inputAdornmentIcon
                                                                            }
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                                placeholder:
                                                                    "First Name"
                                                            }}
                                                        />
                                                        {errors.firstName
                                                            ?.message && (
                                                            <span
                                                                className={
                                                                    classes.labelError
                                                                }
                                                            >
                                                                {
                                                                    errors
                                                                        .firstName
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className:
                                                                    classes.customFormControlClasses
                                                            }}
                                                            name="lastName"
                                                            control={control}
                                                            inputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        <Face
                                                                            className={
                                                                                classes.inputAdornmentIcon
                                                                            }
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                                placeholder:
                                                                    "Last Name"
                                                            }}
                                                        />
                                                        {errors.lastName
                                                            ?.message && (
                                                            <span
                                                                className={
                                                                    classes.labelError
                                                                }
                                                            >
                                                                {
                                                                    errors
                                                                        .lastName
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className:
                                                                    classes.customFormControlClasses
                                                            }}
                                                            name="email"
                                                            control={control}
                                                            inputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        <Email
                                                                            className={
                                                                                classes.inputAdornmentIcon
                                                                            }
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                                placeholder:
                                                                    "Email"
                                                            }}
                                                        />
                                                        {errors.email
                                                            ?.message && (
                                                            <span
                                                                className={
                                                                    classes.labelError
                                                                }
                                                            >
                                                                {
                                                                    errors.email
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className:
                                                                    classes.customFormControlClasses
                                                            }}
                                                            name="password"
                                                            control={control}
                                                            inputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            className={
                                                                                classes.inputAdornmentIcon
                                                                            }
                                                                        >
                                                                            lock_outline
                                                                        </Icon>
                                                                    </InputAdornment>
                                                                ),
                                                                placeholder:
                                                                    "Password",
                                                                type: "password"
                                                            }}
                                                        />
                                                        {errors.password
                                                            ?.message && (
                                                            <span
                                                                className={
                                                                    classes.labelError
                                                                }
                                                            >
                                                                {
                                                                    errors
                                                                        .password
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className:
                                                                    classes.customFormControlClasses
                                                            }}
                                                            name="confirmPassword"
                                                            control={control}
                                                            inputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            className={
                                                                                classes.inputAdornmentIcon
                                                                            }
                                                                        >
                                                                            lock_outline
                                                                        </Icon>
                                                                    </InputAdornment>
                                                                ),
                                                                placeholder:
                                                                    "Confirm Password",
                                                                type: "password"
                                                            }}
                                                        />
                                                        {errors.confirmPassword
                                                            ?.message && (
                                                            <span
                                                                className={
                                                                    classes.labelError
                                                                }
                                                            >
                                                                {
                                                                    errors
                                                                        .confirmPassword
                                                                        ?.message
                                                                }
                                                            </span>
                                                        )}
                                                        <div
                                                            className={
                                                                classes.textCenter
                                                            }
                                                        >
                                                            <Button
                                                                round
                                                                color="primary"
                                                                disabled={
                                                                    !isFormValid()
                                                                }
                                                                type="submit"
                                                            >
                                                                Get started
                                                            </Button>
                                                        </div>

                                                        <div
                                                            className={classNames(
                                                                classes.signInLink,
                                                                classes.textCenter
                                                            )}
                                                        >
                                                            or{" "}
                                                            <Link
                                                                to="/signin"
                                                                className={
                                                                    classes.textUnderline
                                                                }
                                                            >
                                                                Sign In
                                                            </Link>
                                                        </div>
                                                    </form>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </>
                                )}
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
