import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/bg7.jpg";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "api/AuthService";
import { Icon } from "@material-ui/core";
import { ChangePasswordSubmitted } from "./ChangePasswordSubmitted";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required()
});

const useStyles = makeStyles(signupPageStyle);

export default function ChangePasswordPage({ ...rest }) {
  // retrieve params into a variable
  const params = useParams();
  const token = params.id;

  const [submitted, setSubmitted] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(resetPasswordSchema)
  });

  const submitChangePasswordForm = async data => {
    try {
      await resetPassword({ newPassword: data.password }, token);
      setSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const isFormValid = () => {
    return true;
  };

  const classes = useStyles();
  return (
    <div>
      <Header absolute color="transparent" brand="INTERCHANGE" {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {submitted ? (
              <GridItem xs={12} sm={10} md={10}>
                <Card>
                  <ChangePasswordSubmitted />
                </Card>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={5} md={5}>
                <Card>
                  <h3 className={classes.cardTitle}>Reset Password</h3>
                  <p className={classes.textCenter}>Enter your new password.</p>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <form
                          onSubmit={handleSubmit(submitChangePasswordForm)}
                          className={classes.form}
                        >
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            name="password"
                            control={control}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Password",
                              type: "password"
                            }}
                          />
                          {errors.password?.message && (
                            <span className={classes.labelError}>
                              {errors.password?.message}
                            </span>
                          )}
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            name="confirmPassword"
                            control={control}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Confirm Password",
                              type: "password"
                            }}
                          />
                          {errors.confirmPassword?.message && (
                            <span className={classes.labelError}>
                              {errors.confirmPassword?.message}
                            </span>
                          )}
                          <div className={classes.textCenter}>
                            <Button
                              round
                              color="primary"
                              disabled={!isFormValid()}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            )}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
