import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import StarRatingComponent from "react-star-rating-controlled-component";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { getUserSkillRatings } from "api/UserService";
import { getAllSkills } from "../../../api/SkillsService";
import { addUserSkillRating } from "../../../api/UserService";
import ReactDOM from "react-dom";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    starRating: {
        "& label i": {
            fontSize: "24px"
        }
    },
    tableHeader: {
        width: "80%"
    }
};

const useStyles = makeStyles(styles);

function getDefaultSkills(allSkills, userSkillRatings) {
    // Create user rating map
    const userRatingsMap = {};

    for (let item of userSkillRatings) {
        userRatingsMap[item.skill.id] = item.rating;
    }

    const result = {};
    for (let skill of allSkills) {
        if (!result[skill.type]) {
            result[skill.type] = [];
        }
        result[skill.type].push({
            id: skill.id,
            name: skill.name,
            type: skill.type,
            value: userRatingsMap[skill.id] || 0
        });
    }

    return result;
}

export default function SkillSummary(props) {
    const classes = useStyles();

    const [communicationSkills, setCommunicationSkills] = useState([]);
    const [leadershipSkills, setLeadershipSkills] = useState([]);
    const [technologySkills, setTechnologySkills] = useState([]);
    const [managementSkills, setManagementSkills] = useState([]);
    const [teamworkSkills, setTeamworkSkills] = useState([]);

    const [communicationRating, setCommunicationRating] = useState(0);
    const [leadershipRating, setLeadershipRating] = useState(0);
    const [technologyRating, setTechnologyRating] = useState(0);
    const [managementRating, setManagementRating] = useState(0);
    const [teamworkRating, setTeamworkRating] = useState(0);

    const [bestSkills, setBestSkills] = useState([]);
    const [worstSkills, setWorstSkills] = useState([]);

    const updateBestAndWorstSkills = () => {
        const allRatedSkills = [
            ...communicationSkills,
            ...leadershipSkills,
            ...technologySkills,
            ...managementSkills,
            ...teamworkSkills
        ];
        const bestRatedSkills = allRatedSkills.filter(item => item.value === 5);
        const worstRatedSkills = allRatedSkills.filter(
            item => item.value === 1
        );

        setBestSkills(bestRatedSkills);
        setWorstSkills(worstRatedSkills);
    };

    const updateSkillRatingSummary = () => {
        const allRatedSkills = [
            ...communicationSkills,
            ...leadershipSkills,
            ...technologySkills,
            ...managementSkills,
            ...teamworkSkills
        ];
        let userRatingScore = {};
        let totalRatingScore = {};
        for (let skill of allRatedSkills) {
            if (!userRatingScore[skill.type]) {
                userRatingScore[skill.type] = skill.value;
            } else {
                userRatingScore[skill.type] += skill.value;
            }

            if (!totalRatingScore[skill.type]) {
                totalRatingScore[skill.type] = 5;
            } else {
                totalRatingScore[skill.type] += 5;
            }
        }

        for (let key in totalRatingScore) {
            const percentage =
                ((userRatingScore[key] || 0) / totalRatingScore[key]) * 100;
            switch (key) {
                case "communication": {
                    setCommunicationRating(percentage);
                    break;
                }
                case "leadership": {
                    setLeadershipRating(percentage);
                    break;
                }
                case "technology": {
                    setTechnologyRating(percentage);
                    break;
                }
                case "management": {
                    setManagementRating(percentage);
                    break;
                }
                case "teamwork": {
                    setTeamworkRating(percentage);
                    break;
                }
            }
        }
    };

    const loadSkills = async () => {
        const allSkills = await getAllSkills();
        const userSkillRatings = await getUserSkillRatings();

        const defaultTableValues = getDefaultSkills(
            allSkills,
            userSkillRatings
        );

        ReactDOM.unstable_batchedUpdates(() => {
            setCommunicationSkills(defaultTableValues["communication"] || []);
            setLeadershipSkills(defaultTableValues["leadership"] || []);
            setTechnologySkills(defaultTableValues["technology"] || []);
            setManagementSkills(defaultTableValues["management"] || []);
            setTeamworkSkills(defaultTableValues["teamwork"] || []);
        });
    };

    useEffect(() => {
        loadSkills();
    }, []);

    useEffect(() => {
        updateBestAndWorstSkills();
        updateSkillRatingSummary();

        // eslint-disable-next-line
    }, [
        communicationSkills,
        leadershipSkills,
        technologySkills,
        managementSkills,
        teamworkSkills
    ]);

    const onStarClick = async (name, index, value) => {
        switch (name) {
            case "communication": {
                const skills = communicationSkills.slice();
                skills[index].value = value;
                await addUserSkillRating(value, skills[index].id);
                setCommunicationSkills([...skills]);
                break;
            }
            case "leadership": {
                const skills = leadershipSkills.slice();
                skills[index].value = value;
                await addUserSkillRating(value, skills[index].id);
                setLeadershipSkills([...skills]);
                break;
            }
            case "technology": {
                const skills = technologySkills.slice();
                skills[index].value = value;
                await addUserSkillRating(value, skills[index].id);
                setTechnologySkills([...skills]);
                break;
            }
            case "management": {
                const skills = managementSkills.slice();
                skills[index].value = value;
                await addUserSkillRating(value, skills[index].id);
                setManagementSkills([...skills]);
                break;
            }
            case "teamwork": {
                const skills = teamworkSkills.slice();
                skills[index].value = value;
                await addUserSkillRating(value, skills[index].id);
                setTeamworkSkills([...skills]);
                break;
            }
        }
    };

    const addStarRating = (name, data, editing = true) => {
        return data.map((item, index) => {
            return [
                item.name,
                <StarRatingComponent
                    name="rating"
                    editing={editing}
                    className={classes.starRating}
                    starCount={5}
                    value={item.value}
                    onStarClick={
                        editing
                            ? value => onStarClick(name, index, value)
                            : null
                    }
                />
            ];
        });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <h2 className={classes.title}>Skills Summary</h2>
                <h4>
                    RATE YOURSELF: 1 star (Low, Beginning Level) to 5 stars
                    (High, Expert Level).
                </h4>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader icon="true">
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Communication</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating"]}
                            tableHeaderClass={classes.tableHeader}
                            tableData={addStarRating(
                                "communication",
                                communicationSkills
                            )}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader icon="true">
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Leadership</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating"]}
                            tableHeaderClass={classes.tableHeader}
                            tableData={addStarRating(
                                "leadership",
                                leadershipSkills
                            )}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader icon="true">
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Technology</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating"]}
                            tableHeaderClass={classes.tableHeader}
                            tableData={addStarRating(
                                "technology",
                                technologySkills
                            )}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader icon="true">
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            Self Management
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating"]}
                            tableHeaderClass={classes.tableHeader}
                            tableData={addStarRating(
                                "management",
                                managementSkills
                            )}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader icon="true">
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Teamwork</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating"]}
                            tableHeaderClass={classes.tableHeader}
                            tableData={addStarRating(
                                "teamwork",
                                teamworkSkills
                            )}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </CardBody>
                </Card>
            </GridItem>

            {bestSkills && bestSkills.length > 0 && (
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon="true">
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Best Rated Skills
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                hover
                                tableHeaderColor="primary"
                                tableHead={["Name", "Rating"]}
                                tableHeaderClass={classes.tableHeader}
                                tableData={addStarRating(
                                    null,
                                    bestSkills,
                                    false
                                )}
                                coloredColls={[3]}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            )}

            {worstSkills && worstSkills.length > 0 && (
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon="true">
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Skills Needing Development
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                hover
                                tableHeaderColor="primary"
                                tableHead={["Name", "Rating"]}
                                tableHeaderClass={classes.tableHeader}
                                tableData={addStarRating(
                                    null,
                                    worstSkills,
                                    false
                                )}
                                coloredColls={[3]}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            )}

            <GridItem xs={12}>
                <h2 className={classes.title}>My Skill Summary</h2>

                <div>
                    <h4>Communication</h4>
                    <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={communicationRating}
                    />
                </div>

                <div>
                    <h4>Leadership</h4>
                    <CustomLinearProgress
                        variant="determinate"
                        color="danger"
                        value={leadershipRating}
                    />
                </div>

                <div>
                    <h4>Technology</h4>
                    <CustomLinearProgress
                        variant="determinate"
                        color="info"
                        value={technologyRating}
                    />
                </div>

                <div>
                    <h4>Self Management</h4>
                    <CustomLinearProgress
                        variant="determinate"
                        color="warning"
                        value={managementRating}
                    />
                </div>

                <div>
                    <h4>Teamwork</h4>
                    <CustomLinearProgress
                        variant="determinate"
                        color="success"
                        value={teamworkRating}
                    />
                </div>
            </GridItem>
        </GridContainer>
    );
}
