import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
import Apps from "@material-ui/icons/Apps";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem md={8} sm={8}>
                        <h4 className={classes.description}>
                            INTERCHANGE is an employability program introducing
                            students from a range of educational institutions to
                            the world of entrepreneurship and innovation. At the
                            same time providing them with critical work
                            experience for their resumes and future job
                            applications. The program builds career confidence.
                        </h4>
                    </GridItem>
                </GridContainer>
                <div className={classes.features}>
                    <GridContainer>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="Over 40 Multidisciplinary Student Teams"
                                description="Over 120 international and local students working together to solve real-world problems impacting humanity."
                                icon={Apps}
                                iconColor="danger"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="8 Outstanding Educational Institutions"
                                description="Collaboration at it's best to serve both students and the NSW community"
                                icon={ViewDay}
                                iconColor="primary"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="2.5 Weeks of Entrepreneurial Challenge"
                                description="Students learn and work virtually & in-person, applying the latest technology and business tools."
                                icon={ViewCarousel}
                                iconColor="success"
                                vertical={true}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
