import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import Facilitatoraaron from "assets/img/faces/Facilitatoraaron.jpeg";
import FacilitatorIvan from "assets/img/faces/FacilitatorIvan.jpeg";
import FacilitatorLucas from "assets/img/faces/FacilitatorLucas.jpeg";
import FacilitatorAmieMorris from "assets/img/faces/FacilitatorAmieMorris.jpeg";

const useStyles = makeStyles(teamsStyle);

export default function SectionFacilitators({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div className={classes.team}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Our Expert Facilitation Team
                            </h2>
                            <h5 className={classes.description}>
                                Facilitators from the business, start-up and
                                community sectors will deliver this rapid paced
                                2.5 week challenge
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={3} md={3}>
                            <Card plain profile>
                                <CardHeader image plain>
                                    <a
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img src={Facilitatoraaron} alt="..." />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${Facilitatoraaron})`,
                                            opacity: "1"
                                        }}
                                    />
                                </CardHeader>
                                <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                        Aaron Ngan
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            Presenter, franked.io & CEO Junior
                                            Achievement Australia
                                        </h6>
                                    </Muted>
                                </CardBody>
                                <CardFooter
                                    profile
                                    plain
                                    className={classes.justifyContent}
                                >
                                    {/* <Button justIcon round color="linkedin">
                                        <i className="fab fa-linkedin-in" />
                                    </Button>
                                    <Button justIcon round color="facebook">
                                        <i className="fab fa-facebook-square" />
                                    </Button>
                                    <Button justIcon round color="dribbble">
                                        <i className="fab fa-dribbble" />
                                    </Button> */}
                                </CardFooter>
                            </Card>
                        </GridItem>

                        <GridItem xs={6} sm={3} md={3}>
                            <Card plain profile>
                                <CardHeader image plain>
                                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                    <img src={FacilitatorIvan} alt="..." />
                                    {/* </a> */}
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${FacilitatorIvan})`,
                                            opacity: "1"
                                        }}
                                    />
                                </CardHeader>
                                <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                        Ivan Chew{" "}
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            Presenter, franked.io & Workplace
                                            Strategist
                                        </h6>
                                    </Muted>
                                </CardBody>
                                <CardFooter
                                    profile
                                    plain
                                    className={classes.justifyContent}
                                >
                                    {/* <Button justIcon round color="dribbble">
                                        <i className="fab fa-dribbble" />
                                    </Button>
                                    <Button justIcon round color="twitter">
                                        <i className="fab fa-twitter" />
                                    </Button> */}
                                </CardFooter>
                            </Card>
                        </GridItem>

                        <GridItem xs={6} sm={3} md={3}>
                            <Card plain profile>
                                <CardHeader image plain>
                                    <a
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img src={FacilitatorLucas} alt="..." />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${FacilitatorLucas})`,
                                            opacity: "1"
                                        }}
                                    />
                                </CardHeader>
                                <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                        Lucas Hakewill
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            PRESENTER, FRANKED.IO & FACILITATOR
                                            AND PROGRAM MANAGER, UNSW
                                        </h6>
                                    </Muted>
                                </CardBody>
                                <CardFooter
                                    profile
                                    plain
                                    className={classes.justifyContent}
                                >
                                    {/* <Button justIcon round color="dribbble">
                                        <i className="fab fa-dribbble" />
                                    </Button>
                                    <Button justIcon round color="twitter">
                                        <i className="fab fa-twitter" />
                                    </Button> */}
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={6} sm={3} md={3}>
                            <Card plain profile>
                                <CardHeader image plain>
                                    <a
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img
                                            src={FacilitatorAmieMorris}
                                            alt="..."
                                        />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${FacilitatorAmieMorris})`,
                                            opacity: "1"
                                        }}
                                    />
                                </CardHeader>
                                <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                        Amie Morris
                                    </h4>
                                    <Muted>
                                        <h6 className={classes.cardCategory}>
                                            PRESENTER, FRANKED.IO & DIRECTOR &
                                            HEAD OF MEDIA, BONDI RADIO MEDIA
                                            GROUP
                                        </h6>
                                    </Muted>
                                </CardBody>
                                <CardFooter
                                    profile
                                    plain
                                    className={classes.justifyContent}
                                >
                                    {/* <Button justIcon round color="dribbble">
                                        <i className="fab fa-dribbble" />
                                    </Button>
                                    <Button justIcon round color="twitter">
                                        <i className="fab fa-twitter" />
                                    </Button> */}
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem
                            md={8}
                            sm={8}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Innovation Challenge Themes
                            </h2>
                            <h5 className={classes.description}>
                                Here are the
                                <a
                                    href="https://docs.google.com/document/d/1XnuHxqN0Xa5qeUyQwi2ssChFE5-OgVbAjKRr1wGQVZU/edit?usp=sharing"
                                    target="new window"
                                >
                                    {" "}
                                    2023 Challenge Themes.{" "}
                                </a>
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem
                            md={12}
                            sm={12}
                            className={
                                classes.mlAuto +
                                " " +
                                classes.mrAuto +
                                " " +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>INTERCHANGE 2022</h2>
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "100%",
                                    position: "relative",
                                    paddingBottom: "56.25%",
                                    height: 0
                                }}
                            >
                                <iframe
                                    src="https://frankteamwebsite.s3.ap-southeast-2.amazonaws.com/INTERCHANGE+2022+HIGHLIGHTS+VIDEO.mp4"
                                    width="100%"
                                    height={500}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%"
                                    }}
                                ></iframe>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
