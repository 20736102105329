import { makeStyles } from "@material-ui/core";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle";
import classNames from "classnames";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(signupPageStyle);

export function ResetPasswordSubmitted() {
  const classes = useStyles();
  return (
    <CardBody>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={10}>
          <p className={classNames(classes.description, classes.textCenter)}>
            You will receive a email on your registered email shortly. Please click on the reset password link in the email to change your password.
          </p>
          <br />
          <p className={classNames(classes.description, classes.textCenter)}>
            Click <Link to="/signin">here</Link> to go back to Sign in page.
          </p>
        </GridItem>
      </GridContainer>
    </CardBody>
  );
}
