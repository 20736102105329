import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// images
import VirtualInternshipsLogo from "assets/img/assets-for-demo/ourClients/VirtualInternshipsLogo.png";
import CareerMasterClass from "assets/img/partners/CareerMasterClass.jpg";
import ResumeandLinkedIn from "assets/img/partners/ResumeandLinkedIn.png";

import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js";
import CardHeader from "components/Card/CardHeader";
import FRANKlogo from "assets/img/assets-for-demo/ourClients/FRANKlogo.png";
const useStyles = makeStyles(overviewStyle);

export default function SectionPrizes() {
    const classes = useStyles();
    const cardBorder = {
        border: "4px solid",
        borderColor: "#3c4858"
    };
    return (
        <div className={classes.section}>
            <div className={classes.sectionTestimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={10}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            <h2 className={classes.title}>Finalist Prizes</h2>

                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card
                                        profile
                                        plain
                                        className={classes.cardBorder}
                                    >
                                        <CardHeader image plain>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={CareerMasterClass}
                                                    alt="..."
                                                    className={
                                                        classes.cardImage
                                                    }
                                                />
                                            </a>
                                        </CardHeader>
                                        <CardBody
                                            style={{
                                                padding: "0.9375rem"
                                            }}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                First Prize
                                            </h4>
                                            <h4 className={classes.cardTitle}>
                                                Careers Masterclass
                                            </h4>
                                            {/* <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Director, Maple Services
                                                </h6>
                                            </Muted> */}
                                            <p>
                                                The first place team will win a
                                                90 minute Careers Masterclass
                                                with one of Australia’s top
                                                Career Coaches, Langdon Rodda.
                                                The Masterclass will give you
                                                the edge you need to secure
                                                graduate employment! Enhance
                                                your CV, Cover Letter and
                                                Interview skills in this Careers
                                                Masterclass session!
                                                <br />
                                                <br />
                                                Langdon is Head Career Coach at
                                                Career Success Australia with
                                                25+ years’ of experience in
                                                teaching and business. He worked
                                                with AMES (Adult Multicultural
                                                Education Services) for many
                                                years as a specialist teacher
                                                and project manager, including
                                                supporting clients who were
                                                seeking employment. After that
                                                he ran a video production
                                                company for 12 years, where he
                                                gained insights on hiring staff
                                                from the employer’s perspective.
                                                Langdon has been the Head Carer
                                                Coach at Career Success
                                                Australia for over two years,
                                                where he helps clients secure
                                                employment in a range of
                                                industries including
                                                Engineering, IT, Biotechnology,
                                                Marketing and
                                                Accounting/Finance.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card
                                        profile
                                        plain
                                        className={classes.cardBorder}
                                    >
                                        <CardHeader image plain>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={FRANKlogo}
                                                    alt="..."
                                                    className={
                                                        classes.cardImage
                                                    }
                                                />
                                            </a>
                                        </CardHeader>

                                        <CardBody
                                            style={{ padding: "0.9375rem" }}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                Second Prize
                                            </h4>
                                            <h4 className={classes.cardTitle}>
                                                Professional Brand: Be the CEO
                                                of your Career
                                            </h4>
                                            {/* <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Founder & CEO at Banksia
                                                    Academy
                                                </h6>
                                            </Muted> */}
                                            <p>
                                                For the INTERCHANGE 2023 third
                                                prize, you will be able to join
                                                an engaging Professional Brand:
                                                Be the CEO of your Career
                                                personalised session where you
                                                will learn how to market
                                                yourself to potential employers
                                                and recruiters. You will learn
                                                to stand out and communicate in
                                                a way that gets you noticed and
                                                where you generate opportunities
                                                for yourself. A franked.io
                                                facilitator will guide this
                                                session with your team and help
                                                you become career confident and
                                                excited how to capitalise on all
                                                the INTERCHANGE learnings.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card
                                        profile
                                        plain
                                        className={classes.cardBorder}
                                    >
                                        <CardHeader image plain>
                                            <a
                                                href="#pablo"
                                                onClick={e =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    src={ResumeandLinkedIn}
                                                    alt="..."
                                                    className={
                                                        classes.cardImage
                                                    }
                                                />
                                            </a>
                                        </CardHeader>

                                        <CardBody
                                            style={{ padding: "0.9375rem" }}
                                        >
                                            <h4 className={classes.cardTitle}>
                                                Third Prize
                                            </h4>
                                            <h4 className={classes.cardTitle}>
                                                Resume and LinkedIn review.
                                            </h4>
                                            {/* <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Founder & CEO at Banksia
                                                    Academy
                                                </h6>
                                            </Muted> */}
                                            <p>
                                                Resumes To You will provide a
                                                Resume and LinkedIn review for
                                                all third place team members.
                                                All they will need to do is
                                                email a copy of their resume and
                                                LinkedIn to their LinkedIn
                                                profile. Resumes To You will
                                                review and offer feedback and
                                                tips for improvement. <br />
                                                <br />
                                                Resumes To You was founded in
                                                2008 by Matt Tutty an
                                                experienced Resume Writer, CV
                                                Writer, Cover Letter Writer,
                                                Selection Criteria Writer,
                                                LinkedIn profile Writer with a
                                                12+ year career in professional
                                                writing and career coaching.
                                                Their elite resume writing team
                                                have experience in 85+
                                                industries and professions and
                                                assist clients across Australia.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                            {/* <h5 className={classes.description}>
                                Meet our partners who provide experiential
                                prizes for winning INTERCHANGE teams.
                            </h5> */}
                        </GridItem>
                    </GridContainer>
                    {/* <div className={classes.ourClients}>
                        <GridContainer justify="center">
                            <GridItem md={4} sm={4}>
                                <img
                                    src={VirtualInternshipsLogo}
                                    alt="Virtual Internships"
                                />
                            </GridItem>
                        </GridContainer>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
