import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
//HEADER and FOOTER
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import b01 from "assets/img/b01.jpg";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import { TeamsData } from "./TeamsData";
import { TeamCard } from "components/TeamCard/TeamCard";
const useStyles = makeStyles(teamsStyle);

export default function TeamsPage({ ...rest }) {
    const possibleYears = ["2020", "2021", "2022"];
    const years = possibleYears.includes(rest.match.params.year)
        ? rest.match.params.year
        : 2020;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    const classes = useStyles();
    return (
        <div>
            <Header
                // brand="Material Kit PRO React"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "info"
                }}
            />
            <div className="cd-section" {...rest}>
                <div
                    className={classes.team + " " + classes.section}
                    style={{ backgroundImage: `url(${b01})` }}
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                xs={12}
                                sm={8}
                                md={8}
                                className={
                                    classes.mlAuto +
                                    " " +
                                    classes.mrAuto +
                                    " " +
                                    classes.textCenter
                                }
                            >
                                <h2 className={classes.title}>
                                    INTERCHANGE {years} Teams
                                </h2>
                                <h5 className={classes.descriptionWhite}>
                                    Meet our amazing INTERCHANGE {years} teams.
                                    These teams came together to work on
                                    real-life problems impacting humanity.
                                </h5>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={4} md={4}>
                <Card profile>
                  <CardAvatar profile>
                    <a href="https://www.youtube.com/watch?v=sMoDbbh-FO4&feature=youtu.be">
                      <img src={Team1} alt="Team 1" />
                    </a>
                  </CardAvatar>
                  <CardBody>
                    <h4 className={classes.cardTitle}>Team 1</h4>
                    <Muted>
                      <h6 className={classes.cardCategory}>
                        Thought Collective
                      </h6>
                    </Muted>
                    <p className={classes.description}>
                      Change begins with a thought
                    </p>
                  </CardBody>
                  <CardFooter profile className={classes.justifyContent}>
                    <Button justIcon round color="youtube">
                      <i className="fab fa-youtube" />
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem> */}
                            {TeamsData[years].map((team, index) => (
                                <GridItem xs={12} sm={4} md={4} key={index}>
                                    <TeamCard
                                        id={team.id}
                                        name={team.name}
                                        image={team.image}
                                        motto={team.motto}
                                        url={team.url}
                                    />
                                </GridItem>
                            ))}
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
