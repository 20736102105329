// eslint-disable prettier/prettier //
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Message from "@material-ui/icons/Message";
import Assessment from "@material-ui/icons/Assessment";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(pillsStyle);

export default function SectionSchedule() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textCenter
                    )}
                >
                    <h2 className={classes.title}>
                        INTERCHANGE Schedule of Events
                    </h2>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <NavPills
                        color="info"
                        tabs={[
                            {
                                tabButton: "DAY 1: 22nd July 2023",
                                tabIcon: Dashboard,
                                tabContent: (
                                    <span>
                                        <p>
                                            <strong>LEARNING MODE:</strong>{" "}
                                            In-person Orientation Day. Venue is
                                            University of Sydney.
                                        </p>
                                        <p>
                                            <strong>ATTENDANCE:</strong>{" "}
                                            Attendance is compulsory. Failure to
                                            attend this event without prior
                                            notification will make you
                                            ineligible to continue with the
                                            program
                                        </p>
                                        <p>
                                            10.00am Program Welcome: Facilitator
                                            introduction & Agenda.
                                            <br />
                                            10.20am Speed networking activity.
                                            You will also meet your teams and be
                                            assigned a mentor.
                                            <br />
                                            10.40am Inspirational speaker.
                                            <br />
                                            10.55am Introduction to themes OR
                                            guest speakers on each theme
                                            (Smaller masterclass format).
                                            <br />
                                            11.30am Break (Entertainment).
                                            <br />
                                            11.45am Masterclasses: Introduction
                                            to the Business Model Canvas with
                                            franked.io facilitators covering
                                            topics such as customer segments,
                                            value proposition, channels, costs,
                                            revenue.
                                            <br />
                                            12.45pm Debrief & Wrap up.
                                            <br />
                                            1.00pm Conclusion.
                                            <br />
                                        </p>
                                        <br />
                                        <p>
                                            <strong>
                                                WHAT HAPPENS AFTER THE
                                                ORIENTATION DAY 1 SUMMIT
                                                COMPLETES?{" "}
                                            </strong>
                                        </p>
                                        <p>
                                            1) You should keep communicating
                                            with your team and working on your
                                            tasks.
                                        </p>
                                        <p>
                                            2) You should login and watch the 2
                                            video courses that FRANK will give
                                            you access to; Introduction to
                                            Entrepreneurship and Teamwork
                                            Skills. These courses go over the
                                            content covered during INTERCHANGE
                                            and by watching it before
                                            INTERCHANGE kicks off; you give
                                            yourself an advantage.
                                        </p>
                                    </span>
                                )
                            },
                            {
                                tabButton: "DAY 2: 29th July 2023",
                                tabIcon: Schedule,
                                tabContent: (
                                    <span>
                                        <p>
                                            <strong>LEARNING MODE:</strong>{" "}
                                            Virtual Launch Summit
                                            <br />
                                            <strong>PLATFORM:</strong> Zoom
                                        </p>
                                        <p>
                                            <strong>ATTENDANCE:</strong>{" "}
                                            Attendance is compulsory.
                                        </p>
                                        <p>
                                            10.00am Introduction
                                            <br />
                                            10.15am Speed networking activity
                                            <br />
                                            10.30am Inspirational speaker
                                            <br />
                                            10.55am FRANK Masterclass on how to
                                            create a video pitch and
                                            presentation
                                            <br />
                                            11.55am Break
                                            <br />
                                            12.00pm Question time
                                            <br />
                                            12.40pm Debrief & Wrap up
                                            <br />
                                            1.00pm Conclusion
                                            <br />
                                        </p>
                                        <br />
                                        <p>
                                            <strong>
                                                WHAT HAPPENS AFTER THE DAY 2
                                                SUMMIT COMPLETES?{" "}
                                            </strong>
                                        </p>
                                        <p>
                                            1) You and your team should keep
                                            communicating and discussing your
                                            idea and continue to develop it
                                            further for the remainder of the
                                            day. You will need to delegate tasks
                                            in your team as time is very tight
                                        </p>
                                        <p>
                                            2) You should arrange to meet with
                                            your mentor for any guidance on your
                                            idea development
                                        </p>
                                        <br />
                                        <p>
                                            <strong>
                                                IS THERE ANYTHING DUE FOR
                                                SUBMISSION AFTER DAY 2?{" "}
                                            </strong>
                                        </p>
                                        <p>
                                            Yes, you and your team will need to
                                            submit your 3 minute video pitch of
                                            your idea to us by{" "}
                                            <strong>
                                                12pm 1st August 2023.
                                            </strong>
                                            We will show you how to create a
                                            video pitch.
                                        </p>
                                    </span>
                                )
                            },
                            {
                                tabButton: "DAY 3: 1st August 2023",
                                tabIcon: List,
                                tabContent: (
                                    <span>
                                        <p>
                                            <strong>
                                                OPTIONAL VIRTUAL MASTERCLASS ON
                                                PITCHING SKILLS
                                            </strong>{" "}
                                        </p>
                                        <p>
                                            <strong>LEARNING MODE:</strong>{" "}
                                            Virtual Launch Summit
                                            <br />
                                            <strong>PLATFORM:</strong> Zoom
                                        </p>
                                        <p>
                                            <strong>ATTENDANCE:</strong>{" "}
                                            Optional.
                                        </p>
                                        <p>
                                            Students will access a virtual
                                            masterclass on presentation skills
                                            to help them fine tune their
                                            pitching skills.
                                        </p>
                                    </span>
                                )
                            },
                            {
                                tabButton:
                                    "VIDEO PITCH DUE: 12PM 1ST AUGUST 2023",
                                tabIcon: Assessment,
                                tabContent: (
                                    <span>
                                        <p>
                                            <strong>
                                                IS THERE ANYTHING DUE FOR
                                                SUBMISSION TODAY?{" "}
                                            </strong>
                                        </p>
                                        <p>
                                            Yes, your team is responsible for
                                            submitting a 3 minute video pitch of
                                            your idea to FRANK by 12pm 1st
                                            August 2023. We will show you how to
                                            create a video pitch.
                                        </p>
                                        <p>
                                            <strong>
                                                IS THERE ANYTHING ELSE WE NEED
                                                TO PREPARE FOR BEFORE THE PITCH
                                                EVENT ON 3RD AUGUST 2023?{" "}
                                            </strong>
                                        </p>
                                        <p>
                                            Yes, your team will need to nominate
                                            and prepare 1-2 speakers from your
                                            team to pitch your idea at the Pitch
                                            Event on 3rd August 2023.This pitch
                                            will be 3 minutes long and cover the
                                            same content as the video pitch
                                            <br />
                                        </p>
                                    </span>
                                )
                            },
                            {
                                tabButton: "DAY 4: 3rd August 2023",
                                tabIcon: List,
                                tabContent: (
                                    <span>
                                        <p>
                                            <strong>LEARNING MODE:</strong>{" "}
                                            In-person Pitch & Graduation Event
                                            in Sydney
                                        </p>
                                        <p>
                                            <strong>ATTENDANCE:</strong>{" "}
                                            Attendance is compulsory.
                                        </p>
                                        <p>
                                            5.00pm Welcome & agenda for pitch
                                            event
                                            <br />
                                            5.15pm Attendance roll-call for
                                            students who will be pitching and
                                            representing their team. Evaluation
                                            of the program.
                                            <br />
                                            5.30pm FRANK Facilitators will
                                            deliver smaller masterclass sessions
                                            to provide tips and practice for
                                            pitching
                                            <br />
                                            6.00pm Break & networking
                                            <br />
                                            6.30pm Official pitch event welcome
                                            and agenda. Introduction to the 3
                                            judges and the INTERCHANGE journey
                                            <br />
                                            6.45pm VIP Welcome Remarks
                                            <br />
                                            6.50pm Pitching commences: Speakers
                                            from each of the 5 teams will pitch
                                            their idea 1 by 1 to the 3 judges
                                            <br />
                                            7.30pm Break for guests
                                            <br />
                                            7.50pm Winners announced
                                            <br />
                                            8.00pm Final words and event close
                                            <br />
                                        </p>
                                    </span>
                                )
                            }
                        ]}
                    />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={8} lg={6}>
              <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 4 },
                  contentGrid: { xs: 12, sm: 9, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem> */}
            </GridContainer>
        </div>
    );
}
