import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
    const classes = useStyles();
    return (
        <div
            className={classNames(classes.aboutDescription, classes.textCenter)}
        >
            <GridContainer>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h5 className={classes.description}>
                        INTERCHANGE is a virtual employability program that will
                        introduce students to the world of entrepreneurship and
                        innovation, whilst at the same time building their
                        career confidence. Students work in multidisciplinary
                        teams with prominent business mentors to solve
                        real-world problems like Sustainability, Smart Cities
                        and Artificial Intelligence. Students develop key
                        employability skills including start-up strategies,
                        communication, technology innovation, leadership,
                        business agility and networking.
                    </h5>
                </GridItem>
            </GridContainer>
        </div>
    );
}
