export const blogpostsdata = {
    "1": {
        id: "1",
        title: "INTERCHANGE 2022 from the perspective of a “wantrepreneur”",
        paragraphs: [
            "The INTERCHANGE program for me was that one signal that everyone stumbles across in their life when they know they are on the right path and to keep moving forward. Everything about the program was unbelievably incredible, I was given practical, hands-on experience with other students around the world on a virtual platform building an entrepreneurial startup.",
            "INTERCHANGE taught me how to be a leader, instead of building a virtual platform for your team to work they provide you with your team’s name, team members, and their contact details. With this information and a list of suggested virtual applications to use it was up to us to get in contact with each other and make a start. I took that initiative and got our entire team on WhatsApp so we could discuss as a group and plan team meetings and events.",
            "Throughout the entire program I met so many amazing individuals and mentors, each with their own strengths, weaknesses and personal goals. It was such an extraordinary experience getting to communicate with these talented students and professionals and build our own network of friends and future business partners. The amount of value this provided is indescribable with words alone.",
            "My experience at INTERCHANGE has helped shape my mindset on building successful entrepreneurial startups. I am incredibly grateful for the opportunity to participate in the program, and I look forward to my future ventures thanks to it!"
        ],
        author: "Akeem Perry",
        brand: "By Akeem Perry",
        authorImage: require("assets/img/blogs/16/AkeemPerry.png"),
        backgroundImgUrl: require("assets/img/blogs/16/background.jpg")
    },
    "2": {
        id: "2",
        title: "“The experience of a lifetime”",
        paragraphs: [
            "I am Apoorv Maroo, an Interchanger from 2022. The INTERCHANGE is a unique one of its kind program. Although it has a set format there is space for a great deal of creativity. I realized that teamwork and creativity are the two allies that will take you very far in this competition.",
            "Key opportunities that should be exploited:",
            "1. <u>TEAM</u> - Your group will consist of students from diverse backgrounds, disciplines, and universities. <b>This is the best part of the competition.</b>",
            "2. <u>PEOPLE</u> - Weekly sessions with well-qualified and experienced mentors, speakers, and other industry professionals. The program also has bonus “Masterclass” sessions with experts.",
            "3. <u>RESOURCES</u> - The provides you access to a well-designed HANDBOOK and other recorded video resources which can prove to be so beneficial and act as a guiding star helping you navigate better through the challenge.",
            "4. <u>AUTONOMY</u> - You have all the freedom in the world, follow the Double Diamond approach to successfully wrap everything in a timely fashion.",
            "5. <u>BUCKET FULL OF FUN</u> - DJs, live performances, meditation sessions, and so much more. Don&#39;t forget to have fun in the process.",
            "Key learnings:",
            "1. A diverse team can be a liability if you don&#39;t learn to unlock its' benefits and work together because alone you can only go so far.",
            "2. “Time is your Frenemy” - Although you have all the autonomy that you need, this program is time-bound, and you should be quick to think on your toes.",
            "3. Don't enter this challenge thinking you know all the answers. Enter this challenge with a growth mindset and the eagerness to learn from anybody and everybody.",
            "4. Finally, be open to all possibilities. Don't fall in love with any idea. Keep innovating and keep refining till you finally cannot anymore.",
            "I have tried to be as honest as possible in writing this reflection. I hope, this helps the future INTERCHANGERS."
        ],
        author: "Apoorv Maroo",
        brand: "By Apoorv Maroo",
        authorImage: require("assets/img/blogs/17/ApoorvMaroo.png"),
        backgroundImgUrl: require("assets/img/blogs/17/background.jpg")
    },
    "3": {
        id: "3",
        title: "INTERCHANGE 2022",
        paragraphs: [
            "It is the <i>third year</i> I received emails about INTERCHANGE. The same thought ran through my mind again – hesitation to take a step into entrepreneurship. Based on my past experiences, pitching competitions are professional and serious (sometimes boring).",
            "“Why should I pressure myself more if 2022 had already been a tough year?”",
            "The next month was filled with doubts – should I or should I not. Next thing I know, I was at the orientation of INTERCHANGE 2022. Even though I am socially anxious, I still made a promise to myself to engage fully in the program. I know this is a valuable opportunity to grow; To grow my entrepreneurial understandings as well as my communication skills.",
            "To sum up INTERCHANGE in the past 5 weeks, it is basically giving up the afternoon of Saturdays, spending hours for virtual meetings, and cracking my head to brainstorm. However, the magical thing is INTERCHANGE has made these challenges fun. The Saturdays’ afternoon was filled with inspiring speakers, very useful meditation sessions, some good music, and practical masterclasses. The virtual meetings had some difficult moments (undoubtedly), but my teammates were collaborative, and our mentor was always there for us. The most important lesson that I learnt is to have fun; You would not know what is coming next in life, but at least you can have some laughter to make the moments memorable.",
            "Using some imagination; INTERCHANGE was like a treasure-hunting adventure. Six of us in the team were different heroes with different talents. Luckily, we were guided by a captain. Although we were not the top 6 that had the shiniest armours, we still collected a lot of memories and treasures along the journey. At the end (surprisingly), I was awarded a talking mirror by winning the People’s Prize.",
            "Thank you, Australian Catholic University for introducing me into INTERCHANGE.",
            "Thank you, Natasha and the FRANK team for hosting such an amazing program.",
            "Thank you, all the speakers and performers in completing the program.",
            "Thank you, Aaron, for engaging all participants throughout the days.",
            "Thank you, George for being the best mentor.",
            "Thank you, all my teammates (Jacky, Jordan, Lola, Sameer, and Natalie) for being so awesome throughout the challenge.",
            "Lastly, I thank myself for taking a bold step in 2022."
        ],
        author: "Huoy Shi BOON",
        brand: "By Huoy Shi BOON",
        authorImage: require("assets/img/blogs/18/HuoyShiBOON.JPG"),
        backgroundImgUrl: require("assets/img/blogs/18/background.jpg")
    },
    "4": {
        id: "4",
        title: "The thrill of entrepreneurship in INTERCHANGE",
        paragraphs: [
            "POV: You're speaking in front of a laptop, pitching an amazing idea on clothing sustainability on a zoom with 100+ participants and judges!",
            "I've had my fair share of group projects, and entering the INTERCHANGE 2022 Program from franked.io has strengthened my capabilities in industry collaborative work. I entered the program feeling equally nervous and thrilled to meet my teammates, especially when orientation day came! The weekly summits were amazing, meeting many industry professionals with their own repertoire of skills. I particularly enjoyed the prototyping session with Lucas Hakewill. I’ve made my fair share of prototypes as a budding TAS Educator, but I learned much more about the world of UI/UX design through the workshop.",
            "Team 27 ‘Pi’ consisted of myself from ACU, Yujie & Yanjee from USYD, Alexandra Tomas from UNSW and Pinru from UTS. And away we go! Many sleepless nights and countless ideas were bounced off each other the more we discussed what would now be known as ‘Re:Life’, a conceptual, pragmatic solution that tackles the core of textile sustainability's social and environmental crisis by approaching the current cradle-to-grave model and reshaping it into an innovative cradle-to-cradle system - ensuring a zero waste approach.",
            "I would love to thank the most amazing team 'Pi'; for working excellently under my leadership as the delegated team leader! And of course, many thanks to our mentor Amy Cheung for providing us with constructive feedback that certainly guided us to make our project idea 'Re:Life' - a reality.",
            "I'm amazed that we did well to reach even the Top 6! Despite not ranking either 1st, 2nd or 3rd, I'm thrilled to have even been considered part of the Top 6 out of 34 teams - to complete an effective project as large-scale as this within a month speaks volumes on the late night zooms to get the pitch video and solution done!",
            "Thank you to Aaron Ngan and Tina Wyer (and, of course, the rest of the franked.io team) for organising such a great opportunity for any student to participate, regardless of where they are and what they do! And thank you to Australian Catholic University for sending me that fated email that led me to apply for this opportunity to grow as a professional on various levels!",
            "Onwards and upwards to the journey as a future technology educator with this under my skills belt!"
        ],
        author: "Jerica Cabanit",
        brand: "By Jerica Cabanit",
        authorImage: require("assets/img/blogs/19/JericaCabanit.jpg"),
        backgroundImgUrl: require("assets/img/blogs/19/background.jpg")
    },
    "5": {
        id: "5",
        title: "A Mini MBA in 3 days.",
        subtitle:
            "INTERCHANGE was a memorable experience which gave me friends from different countries and universities.",
        subheading: "INTERCHANGE Equals Experience",
        paragraphs: [
            "The reminiscence of 200+ people in a hall still gives me goosebumps of positive energy!!!! That is so nostalgic. Last year, I found an amazing opportunity on the UNSW website for international students called the Interchange. It is a 4-day TED-style conference program. This involved collaboration with 7 universities across Sydney. Well, at UNSW we had to clear two rounds to be a part of this program. It was an intense program where we learned a mini MBA in 3 days. What did we do?",
            "This program began with meeting our teammates from different universities and backgrounds. We were introduced to our peer and business mentors. The three days covered various concepts like speed networking, team dynamics, business canvas models, theme identification, customer segments, interviews, and many more. We had guest talks by some awesome young entrepreneurs. It was difficult to finalize a topic for the pitch when we had so many people pitching outstanding ideas.",
            "<b>Major Takeaways:</b> Interchange was a memorable experience which gave me friends from different countries and universities. It made me more confident to speak to people. It enhanced my communication and pitching skills. This program brought out the leader within me. This experience truly made me more responsible and punctual.",
            "Another major learning:“Listen to others and allow them to speak”. We were a team of 10(which later became 7), it was necessary for everyone to express ideas and keep the remaining in the entire group active at the same time. This boosted my listening skills and patience. Today, when I look at the job descriptions, I not only find the technical skills but also the interpersonal skills."
        ],
        quote:
            "This program has shaped my skills. In my opinion, it was an experience worth having and I look forward to channeling the inspiration I have gained. There is something for everyone to take away and I am happy to have left with a new outlook on technology and entrepreneurship..",
        author: "Supritha Uday, UNSW Student.",
        brand: "By Supritha Uday. University of New South Wales",
        authorImage: require("assets/img/blogs/1/supritha.jpeg"),
        backgroundImgUrl: require("assets/img/blogs/1/background.jpg"),
        blogImage: require("assets/img/blog1.jpg")
    },
    "6": {
        id: "6",
        title: "Igniting The Spark",
        subtitle:
            "Two weekends of intense hands-on approach that helps in developing key skills that every entrepreneur will benefit from. Not everyone can make it into the program but if you make it, you are in for one hell of a journey!",
        subheading: "When mentors become mentees",
        paragraphs: [
            "In 2018, 200 students were chosen from 7 universities and fortunately, I was one of the 28 UNSW students that got to work in teams to develop innovative business ideas as part of the entrepreneurial program. The program is an active learning journey designed to help students find their inner entrepreneur and develop professional skills including communication, innovation and leadership.",
            "The intense two-week program had all the highs and lows of entrepreneurship, it would have been a rough two weeks without the insight, encouragement and guidance of the mentors who have generously shared their wisdom.",
            "<strong>Major Takeaways: </strong> I was contacted with the opportunity to be a peer advisor in the 2019 program, and I felt that I needed to give back! As a peer advisor, I got the chance to make an impact and leave a mark. It’s a rewarding feeling to know that my mentees are trying to make a positive impact in the world!",
            "Being a mentor can deliver personal benefits as well, I improved my communication and supervisory skills and expanded my connections and network. It also gave me the opportunity to reflect on what have I achieved and how I got there."
        ],
        quote: "The program was a roadmap to entrepreneurial success",
        author: "Abdulrahman Alyousef, UNSW Student.",
        brand: "By Abdulrahman Alyousef. University of New South Wales",
        authorImage: require("assets/img/blogs/2/abdul.jpeg"),
        backgroundImgUrl: require("assets/img/blogs/2/background.jpg")
    },
    "7": {
        id: "7",
        title: "INTERCHANGE Hugs!",
        subtitle:
            "We met like-minded individuals from different universities as well as various professionals in the industry.",
        subheading: "Meeting Likeminded People from the World",
        paragraphs: [
            "The INTERCHANGE program has been a pleasant journey for me. The program gives me so many opportunities to form valuable connections with like-minded individuals from different universities as well as various professionals in the industry.",
            "Furthermore, through INTERCHANGE, I enhanced my entrepreneurship and communication skills by working together to come up with a start-up idea that solved a problem that is experienced by international students. I remember when I first started the program, I felt so excited and nervous at the same time meeting my team members. It was awkward at first, but as time went on, we all became closer and we remain good friends until today.",
            "<strong>Major Takeaways: </strong> INTERCHANGE was intense and sometimes we experienced conflict amongst ourselves, especially when coming up and deciding on the start-up idea but we learnt how to collaborate and find a win-win solution. Time went by so fast and I felt sad once we reached the end of INTERCHANGE."
        ],
        quote:
            "We hugged for one last time at the end of the gala night, where the selected teams will pitch their start-up idea and celebrate the completion of the program. We did not win but the memories of INTERCHANGE will forever be endearing to me.",
        author: "Cheryl Faustina, UTS Student.",
        brand: "By Cheryl Faustina. University of Technology Sydney",
        authorImage: require("assets/img/blogs/3/cheryl.jpg"),
        backgroundImgUrl: require("assets/img/blogs/3/background.jpg")
    },
    "8": {
        id: "8",
        title: "INTERCHANGE 2020 - what a finish!",
        subtitle:
            "I made friends, connected with other students and learnt about entrepreneurship.",
        subheading: "Quick reflections from INTERCHANGE 2020",
        paragraphs: [
            "My name is ZhiYu Song. I'm a participant of the 2020 INTERCHANGE Program and a team member of Team 41(Surftron).",
            "First of all, I really want to thank you and all the FRANK team members as well as the amazing entrepreneurs, mentors & judges for giving us an amazing month-long MBA class!",
            "Without this program, I probably would not have met my teammates and would not have had the opportunity to connect with students from other universities and cultural backgrounds.",
            "<b>Major Takeaways:</b>  Most importantly this program taught me how to cooperate like a team, how to start my own business and be passionate about what you're doing.",
            "Today's final pitching session was also really great, we've been able to see 6 amazing teams pitching their brilliant ideas. That was a very exciting Pitch night and for us a bit frustrating as well; because we were not lucky enough to be nominated to the pitching night."
        ],
        quote:
            "This is definitely the best program I have ever participated in. ",
        author: "Zhiyu Song, UNSW Student.",
        brand: "By Zhiyu Song, UNSW Student",
        authorImage: require("assets/img/blogs/4/zhiyu.jpg"),
        backgroundImgUrl: require("assets/img/blogs/4/background.png")
    },
    "9": {
        id: "9",
        title: "INTERCHANGE 2020 - 4.30am Zoom calls and more?!!!",
        subtitle: "It changed my way of thinking and approaching new ideas.",
        subheading:
            "How can basic idea evolve into a startup within your team if you are passionate about it?",
        paragraphs: [
            "4:30 am Zoom Calls as I was participating from Chandigarh, India and three amazing weeks full of learning, excitement and endless group discussions. If I had to describe my Interchange 2020 experience in one line, this would be it.",
            "My amazing journey at Interchange started on 25th July 2020.",
            "Interchange 2020 was 100% virtual but despite that, it was so well organized that I didn’t feel like I was losing on anything (apart from food of course 😛). In fact, we were introduced to many online tools which can be used for prototyping, video making, etc which makes implementation and presentation of ideas much easier.",
            "<b>Major Takeaways:</b>  I made some amazing friends from various universities all over Sydney. I learnt how a basic idea can evolve into a startup within your team if you are passionate about it. It changed my way of thinking and approaching new ideas. The importance of healthy team discussions and in-depth analysis are some of the other things that will stay with me after the program. Overall, the program was a great mix of learning and fun and I will cherish the memories forever!!!",
            "We, in teams of 7 or 8 people, had to come up with a startup idea out of a few given topics such as Sustainability and Networking. My team members came from various cultural and educational backgrounds. Throughout the competition, we had many opportunities to connect with our team mentors and join other upskill workshops. Many important ideas like the Business Model Canvas, Design Thinking, Effective communication Skills were introduced to us over a period of 3-4 weeks. As the organizers liked to call it, it was like a fast-paced mini MBA! There were many amazing ideas that circulated through the teams and the whole program was filled with excitement and joy."
        ],
        quote:
            "The beauty of the Interchange is that it brings together different people from different walks of life with different expertise  and we come together to work on ONE passionate and innovative idea. ",
        author: "Yuval Kansal, UNSW Student.",
        brand: "By Yuval Kansal, UNSW Student.",
        authorImage: require("assets/img/blogs/5/yuval.png"),
        backgroundImgUrl: require("assets/img/blogs/5/background.png")
    },
    "10": {
        id: "10",
        title:
            "Why some people hate group work, effective leadership and other lessons from INTERCHANGE 2021",
        paragraphs: [
            "<b>There is a reason why a lot of people hate group work, and it often has more to do with their lack of empathy and ineffective leadership, than with the people they are working with.</b> As humans, we have the tendency of holding others to our own standards, but fail to consider everyone has a different story and perspective of life and work. Is your teammate not doing enough despite working 8 hours? Probably not. Is it not enough because you are working 14 hours by choice and expect them to do the same? Most likely.",
            "I believe in the world there are many leaders, but having the title is not equivalent to having the ability to lead effectively. After talking to a couple of “Interchangers” in breakout rooms, or outside the session, there seemed to be a common topic: <b>Not having a voice and feeling worthless.</b> As the program went on, some people struggled because they were working on a topic they had no interest in, their ideas were never taken into consideration, more experienced people would do all the work rather than teaching others how to do it, and some felt they could not put their abilities and strengths to use. If this sounds like you, you are not alone. Perhaps some people in your team felt the same way without you even noticing.",
            "There is this notion that leaders are the most outgoing, loudest person in the room. And while that might be the case sometimes, it is not necessarily always true. Someone with great conviction, power and a basic understanding of the human psyche can easily become one, depending on the group he is trying to approach. However, being an effective leader is a whole different story.",
            "<b>Effective leaders are able to identify their team members’ strengths, encourage their growth</b> and delegate tasks, rather than holding on to everything. A very important person in my life once said to me, “Some of my subordinates are way better at doing what they do than I will ever be, but there is a reason why I am the head. I am able to ensure as a team we deliver the best results, because I am able to work with their strengths and create a sense of commitment and belonging.”",
            "One of the guest speakers during the program was the CEO of Prismatik, <b>Mr. Rob Morris</b>. There were two things he said that really stood out to me: “One of the most valuable things someone has is their reputation” and “No startup can grow beyond (or faster) than its founder.” Although he was talking about entrepreneurship and startups, both principles still apply to team work.",
            "It takes an entire team to back up your reputation as a leader, but it only takes one negative review from a teammate to ruin it. And the second one, a team cannot grow beyond (or faster) than its leader. When we started the program there were over 20 teams, but only a couple of them will be chosen to pitch in the final night. Frankly speaking, and without intending to take merit away from anyone, the groups chosen might not necessarily have the best idea, but they for sure had the best execution. At the end,<b> effective teams always yield the best results, and that is much more valuable</b>.",
            "Whether we admit it or not, we all depend on others when developing a project such as the one we worked in during Interchange. <b>We can ensure completion, but the success of a team is based upon the sense of belonging and cohesion of its members. A good leader is able to achieve this by empowering team members and creating a culture of recognition. And that's probably one of the things that separates an effective team from the rest.</b>",
            "In programs such as Interchange, where everyone starts at the same level, leaders seem to emerge naturally. Some do due to their natural charm, expertise on the topic, power of persuasion, overpowering personality or ability to connect with their teammates. If this sounds like you, would all of your teammates choose to work with you again, if the groups were not selected at random? If a future employee asked any of your teammates for recommendations, would they be there to back you up? Could you truly identify your teammates’ strengths, and were they put into use?",
            "If on the other hand, your experience was different from what you expected, take this as a learning opportunity. Your idea is not necessarily bad, your skills are not worthless and you have much more to offer than you can even imagine. It is a matter of being surrounded by the correct people, having a supportive team and finding the right mentor. So if this is really what you want, keep working on it and you will find your way.",
            "This might not be what you expected when joining Interchange, but the truth is, at the end you end up learning what you need, not what you want (There is people who finished the program not knowing how to use Balsamiq and Slack. I have got no proof, but also no doubt). I can assure you, the lessons learnt throughout cannot be taken away from you, and one day you might even become the leader you once needed. Because there is no better leader than the one who understands his/her team. “Lead not to be the centre of attention, but rather support your team's growth in experience and knowledge, therefore, enhancing belief in their own strengths whilst in the spotlight and enabling them to smile and truly enjoy it” (Jonah Roberman, 2021)."
        ],
        author: "María Alejandra Toncel Parrado, Torrens University",
        brand: "By María Alejandra Toncel Parrado, Torrens University",
        authorImage: require("assets/img/blogs/6/maria.jpg"),
        backgroundImgUrl: require("assets/img/blogs/6/main.jpg")
    },
    "11": {
        id: "11",
        title: "Useful & Impactful Feedbacks",
        paragraphs: [
            "During the INTERCHANGE 2021 program, getting feedback from users is one of our team tasks. A survey is one of the standard methods of gathering feedback on our projects. However, our team uses another method on top of the surveys.",
            "Social media like Facebook and WhatsApp is another way of getting feedback throughout our INTERCHANGE 2021 program. We can engage the key users more effectively and efficiently to suit the target audience using social media. The best part is when our team targeted the actual target group instead of family members, friends or the general public.",
            "Our methods enable us to get better feedback from different parts of the world, a higher volume of feedbacks/key points (where the survey is optional), and gather the essential information from the key users. Social media also encourage the key users to express their experiences.",
            "Using Facebook posts and target to the key users in Facebook Group is essential to get the feedback that you needed. Remember to follow the rules set by the group to prevent being kicked out.",
            "Based on the feedback, even the safest place to keep your bicycle at home. It is still not the safest place as people will rob you.",
            "Especially high-value bicycles like Brompton, most people will bring them along whenever they go out.",
            "Some of them suggested getting a lock and lock it at a safe place when leaving unattended.",
            "Instead of using standard survey templates, Facebook post is more effective as it is two-way communication. In addition, feedback from the key users can be done at any time based on both parties convenience.",
            "As a result, our group is part of the INTERCHANGE program. We are able to collect the information that we needed.",
            "It is more to have qualitative feedbacks. That really matters the most as it affects our Business Model Concept (BMC).",
            "Therefore, our team has expanded the limitations on the survey questions that we made. We can obtain better answers due to survey limitations.",
            "At the same time, I would like to thank Nazmije Sikaloska for creating the surveys using Google Forms. Her contributions to the team are greatly appreciated."
        ],
        author: "Sheng Yang Lee, University of Technology Sydney",
        brand: "By Sheng Yang Lee, University of Technology Sydney",
        authorImage: require("assets/img/blogs/7/Sheng.jpg"),
        backgroundImgUrl: require("assets/img/blogs/7/background.png")
    },
    "12": {
        id: "12",
        title: "The journey of Interchange",
        paragraphs: [
            "We have finally come to the end of our 4-week long experience of teamwork, regular zoom sessions, research, brainstorming, and interviewing for Interchange. We represented our idea in a single video pitch, and by submitting it today, our final task is done now. We don't know yet whether we will get selected for the final pitch or not. If we get chosen, it will enhance our enthusiasm for sure, but the truth is, even if we don't make it, we will still be grateful for the knowledge and skills we have gathered during Interchange. The journey of Interchange started last month. During the time, Natasha Munasinghen, Aaron Ngan, & Sally Hetherington arranged four perfectly organized sessions with successful guest hosts like Rob Morris, Yvonne Power, Celia Harding, and Lucas Hakewill. Their stories of hardships and success to become the most successful entrepreneurs made us amazed and inspired. There was a particular meditation period with Chibs Okereke in almost all of those sessions, which were so stress-relieving. We were also introduced to various user-friendly tools like Slack, Canva, Wireframe, Powtoon Capture.",
            "I got to know my six teammates, who are literally from different parts of the world ( 1 Indian, 3 Chinese, 2 Australian, and I am from Bangladesh). We got direct guidance regarding the task from our mentor Claire Bath in zoom sessions and emails. In addition, we had separate zoom sessions with the team almost regularly. We analyzed various ideas to project a realistic solution to our chosen real-life problem under the `Friends and Networks` theme. In addition, we kept interviewing people worldwide to ensure and clarify if our solution is actually innovative and can benefit them. Finally, when we reach the point where we all agree that the answer is satisfactory, we put them in different sections of the Business Model canvas, finalized the platform's prototype, and finally, made the video pitch. It was quite a challenging task. However, this whole journey was not about completing the tasks only. It was so much more than that. Through this experience, we got to know people from different cultures, and we listened to their stories of joy and struggle. These make the platform most unique. I want to appreciate my teammates for putting their best efforts into this. I am also grateful to our mentor Claire Bath to always direct us towards the right path.  I hope we will stay connected and meet in the future when we all are finally in Sydney."
        ],
        author: "Mithila Kundu Barsha, Western Sydney University",
        brand:
            "By Mithila Kundu Barsha Analyst, Operations Delivery Centre at PwC Australia",
        authorImage: require("assets/img/blogs/8/mithila.jpg"),
        backgroundImgUrl: require("assets/img/blogs/8/background.png")
    },
    "13": {
        id: "13",
        title: "My perspective on Interchange",
        paragraphs: [
            "<b>Program Background Brief</b>",
            "Interchange 2021 is a four weeks program where it teaches university students about creating company startups, the usefulness of Business Model Canvas (BMC) and mostly importantly, entrepreneurship skills. Unfortunately due to lockdown restrictions, everyone has to attend this program virtually, but everyone seems delighted to understand the overall process of teamwork, as well as, learning and applying the skills into their team's final product. This year there were around 34 teams participating. In this program, each team consist of 5-8 students from different universities and only 6 teams will be going to the final.",
            "Before the program begins, students from different universities such as ACU, USYD, WSU, UNSW, UTS and other universities all over Australia are required to go through selection processes in their respective university. Afterwards, every students will have the opportunity to represent their respective university to join this program, which I (the author) personally felt proud and delighted to represent UNSW to attend Interchange.",
            "<b>Journey from Day one until Final day</b>",
            "<b><u>On the orientation day</u></b>, there were approximately 210 students attended this program and the Interchange facilitators were extremely enthusiastic to see students virtually and began to introduce by explaining the agenda and the expectations of this program's requirement. As well as joining Interchange group chat on Slack and connect with other fellow students and facilitators on LinkedIn. At the end of the day, our homework was to learn the introduction to entrepreneurship and BMC (showed on diagram 1 and 2 respectively) that is required for this program.",
            "<b><u>Day 2</u></b>, the facilitators have brought in two different guests. First guest was Chibs Okereke, he was there to introduce and help students to meditate better, in order to help them perform their duties better during these covid times. His soothing voice were his unique selling point, which was calming everyone down and it gave me (the author) goosebumps! The second guest was Rob Morris, which he shared his startup and entrepreneurship experiences and struggles with the students, to inspire them to become entrepreneurs and wanted students to treat failures as our learning curve.",
            "Afterwards, the masterclass was held by Interchange facilitators, and they explained about entrepreneurship and creating our own five elements of BMC, which were customer segments, value proposition, distribution channels, cost and revenue.",
            "Our homework of the day was to connect with my team and my mentor (Natalie Salamat) on a social platform. In addition, my team began to select a theme of our choice among 5 themes (future of work, sustainability, friends & network, smart cities and group passion project) by voting and brainstorming ideas.",
            "<b><u>Day 3</u></b>, It was all about prototyping ideas and testing it by gathering surveys from our user segment audience. Afterwards, improve the prototype by `listening` to the public's needs and wants from the responded surveys. Lucas Hakewell's masterclass gave the students, insightful information about prototyping our wireframe by sketching it first on a paper and afterwards use software such as Balsamiq or Marvel Pop to create an interactive wireframe, an example of my team's wireframe",
            "<b><u>Final day</u></b>, it is usually the hardest/saddest day of any program, because it is ending. However, we must keep focusing until the end. Anyway, the final masterclass held by the facilitators, and it was about creating video pitch and final presentation, as well as explaining the judging criteria and pitching instructions alongside with the submission deadlines.",
            "<b>Why I join interchange?</b>",
            "Interchange helps me to explore new career path and learn the skills that I will definitely use for my career. It also gives me the chance to work with students from different universities and professionally connect with them to try to make long lasting friendship. On the personal side, representing my university is always something that I wanted to do.",
            "<b>Team working product processes</b>",
            "To create out of nothing, into a concept with wireframe within four weeks was a tough process, due to multiple ideas within a big group. Understanding teammate's strengths and weaknesses was another difficult process, because we have never met each other well enough to create a great chemistry. Therefore, we could not optimise the team's strengths to create the best outcome.",
            "<b>What went well during this program?</b>",
            "In my team, everyone was super proactive and punctual to every meetings with our mentor to get as much information as we could within everyone's busy schedule outside Interchange. Some are more active and works harder than others on some parts of the project, due to their familiarity of technology.",
            "<b>What difficulties did my team and I faced during this program?</b>",
            "The main difficulty was the short timeframe with a massive objective, that was given to the group. Another difficulty was that, some of us took more workload than others due people's commitment and interest of our chosen project. Thankfully, we had enough committed and skilled teammates to cover every aspect that was required. Another difficulty was spending too much time, brainstorming ideas into our product during our first, second and third meeting between day 1 and day 2 of the program. The last difficulty was creating a three minutes video with the massive information that my group has.",
            "<b>Lesson learnt</b>",
            "To be honest, having seven people in a team could cause some delays due to everyone's different life schedules and project ideas. I realised that commitment and attitude is the most important characteristics in a person, even though skills might be important. But, supporting each other is the most important part of a team, it doesn't matter if your ideas got rejected. This is because you will never know how your team's project will help you strive in your next stage of your career."
        ],
        author: "Desmond Tan, University of New South Wales",
        brand: "By Desmond Tan, University of New South Wales",
        authorImage: require("assets/img/blogs/9/desmond.jpg"),
        backgroundImgUrl: require("assets/img/blogs/9/background.jpg")
    },
    "14": {
        id: "14",
        title: "Interchange 2021 - retrospect on an entrepreneurship journey",
        paragraphs: [
            "With our Pitch videos successfully released two nights ago, we are all left eagerly awaiting the judge’s winner results for the final ‘live’ virtual pitch.",
            "Looking in hindsight, it’s amazing how much time has passed in this short period. When signing up for the free <b>Interchange</b> program (what I perceived to be purely a simple <b>‘employability’</b> program), I really had no idea of the true depth or nature of what would be covered, what kind of skills we would learn, and the interactions and experiences we would be offered.",
            "If you’re only looking for a generic class style session on how to write a resume (like I was), this isn’t the type of program for that. And yet, I would still absolutely recommend it, because Interchange is so much more.",
            "During this four week long period, we the participants were given the opportunities of free masterclass sessions from real life entrepreneurs with real world established businesses and enterprises, as well as entrepreneurship coaches, business professionals, and more. Individuals such as <b>Yvonne Power</b> from <b>Village Energy</b>, <b>Rob Morris</b> founder of <b>Prismatik</b>, and director of <b>PR Shed</b>, <b>Celia Harding</b> to name just a few",
            "My personal favourites:",
            "The masterclass from Rob Morris, a highly engaging, insightful and realistic introduction to the world of business startups,",
            "The following class with Lucas Hakewill from UNSW on prototyping and customer segments, a section that seriously helped our 'Gren Nextdoor' team in identifying our user and customer demographics as well as teaching me about some more generally applicable rational models,",
            "And finally, the short but intriguing opportunity to have a chat with Arnold Jorge, Chief Executive Officer at Export Council of Australia, on his extensive career experiences in international diplomatic relations (an area I am currently studying) and his entrepreneurship ventures.",
            "In addition, the program connects over 200 university students from a number of academic institutions across the globe and from all degrees, offering the opportunity to collaborate and communicate, pretty priceless experiences.",
            "It wasn’t all ‘business’ either (pun intended), as we were also treated to conscious mindfulness and meditation sessions from Chibs Okereke, as well as live music thanks to Kartic Kunasegaran.",
            "Overall, I’m extremely fortunate to have participated, though it made my end of holidays more busy than initially anticipated, also continuing into the second yearly semester. But this business helped me get into my workflow ahead of time. I’m ultimately thankful for the opportunity.",
            "These sessions and the program-long team startup activity have equipped us all with more than mere information, but also hands on experience on workplace team building, management, and entrepreneurial mindset. I feel extremely fortunate that ACU and all other participating universities provided all their students with this program, especially for no charge.",
            "This course was an excellent introduction to the broader scope of professional life and future potential, providing us with what I expect most will agree was much of the latter.",
            "Thanks again to the Frank team, to Aaron, Natasha, Gabby and Ivan, and to my own Team 26, or ‘Green Nextdoor!’"
        ],
        author: "Ioan Bugheanu, Australian Catholic University",
        brand: "By Ioan Bugheanu, Australian Catholic University",
        authorImage: require("assets/img/blogs/10/loan.jpg"),
        backgroundImgUrl: require("assets/img/blogs/10/background.png")
    },
    "15": {
        id: "15",
        title: "Interchange 2021-Orientation",
        paragraphs: [
            "July 13th, 2021 was the day of orientation of Interchange 2021 managed and powered by FRANK Education. Though I was aware that Interchange was a virtual employability programme designed to introduce Australian University students to the world of entrepreneurship and innovation, I have had a long way to go.",
            "At first, we were introduced to the team: <b>Aaron Ngan</b> (CEO of Junior Achievement Australia), <b>Natasha Munasinghe</b> (CEO @franked.io Australia), <b>Gabriella Yan</b> (FRANK Event Coordinator), <b>Christine Espero</b> (FRANK support), <b>Sally Hetherington</b> (CEO/Founder of Human & Hope Association) and <b>Ivan Chew</b> (Head of Workplace Strategy, Australian National Maritime Museum). Initially, I felt (super) overwhelmed by being surrounded by such big names and tried to keep my feet on the ground.",
            "We have been assigned with an amazing mentor <b>Trevor Weeding</b> for our team, along with five team members from diverse backgrounds, universities and ethnicities. We are getting familiar with each other at this stage and having small talks on our WhatsApp group.",
            "<center><b><i>Goals during the journey of INTERCHANGE 2021:</i></b></center>",
            "<ul><li>Building Australian and global networks & make friends</li></ul>",
            "<ul><li>Learning key employability skills such as working with people, creating high performing teams and communication skills </li></ul>",
            "<ul><li>Getting introduced to the world of entrepreneurship – and getting a head-start with start-ups</li></ul>",
            "<ul><li>Becoming more career confident</li></ul>",
            "<center><b><i>Top Fact of the Day</i></b></center>",
            "According to The World Economic Forum innovative thinking will be the number 1 sought after skill by employers by 2025.",
            "<center><b><i>Theme of 2021: Solving real life problems</i></b></center>",
            "<ol><li>Future of Work</li></ol>",
            "<ol><li>Sustainability</li></ol>",
            "<ol><li>Friends & Network</li></ol>",
            "<ol><li>Smart cities</li></ol>",
            "<ol><li>(Your) passion project</li></ol>",
            "<center><b><i>Tasks</i></b></center>",
            "<ol><li>Collaborating with team members to select a Team name, logo and theme.</li></ol>",
            "<ol><li>Researching on the selected problem</li></ol>",
            "<ol><li>Access two of Online Courses in <b>Entrepreneurship & Teams</b> and <b>Business Model Canvas</b> (Thanks to FRANK team!)</li></ol>",
            "<center><b><i>Favourite part so far</i></b></center>",
            "<b>Speed Networking:</b> Connecting with like-minded ambitious individuals is the key takeaway for me. There is so much to learn from everyone as they are all equally supportive and inspiring.",
            "I will be having an elaborate review of the online courses and the upcoming events on my LinkedIn profile so stay tuned!"
        ],
        author: "Shajnin Mahmud Sara, University of Sydney",
        brand: "By Shajnin Mahmud Sara, University of Sydney",
        authorImage: require("assets/img/blogs/11/shajnin.svg"),
        backgroundImgUrl: require("assets/img/blogs/11/background.png")
    },
    "16": {
        id: "16",
        title: "Interchange 2021",
        paragraphs: [
            "We had the Interchange2021 DAY3 session yesterday and I learnt a lot!",
            "At the first part, we had the meditation and mindfulness with <b>Chibs Okereke</b>. I learnt the four ways of deep breathing to release anxiety and I found out that <q>in for 4, hold for 2, and out for 6</q> is my best breathing type to relax myself! I believe it will be useful for me in the future life and work!",
            "After that, we had <b>Yvonne Power</b>, who is the Co-Founder & COO of Village Energy, shared her experience of education and career, and the most inspiring things I got from her is that be open to opportunities and not afraid to fail. There are many opportunities in the uni life and I will challenge myself and keep learning new things.",
            "Then big thank you to <b>Lucas Hakewill</b>, who is the Former Growth Program Lead, he shared useful ways of testing hypothesis by doing prototype like user story and wireframe. He also shared us many useful APP and website to do prototype, and my team believe we can use many of them in our project!",
            "Finally, thank <b>Natasha</b> and <b>Aaron</b> to organize the breakout room design all these sessions, I really enjoied this event!",
            "#Interchange2021 #FRANK"
        ],
        author: "Yuan Fang, University of Sydney",
        brand: "By Yuan Fang, University of Sydney",
        authorImage: require("assets/img/blogs/12/yuan.jpg"),
        backgroundImgUrl: require("assets/img/blogs/12/background.png")
    },
    "17": {
        id: "17",
        title: "Inspire thinking, Ignite lives",
        paragraphs: [
            "From July 24 to August 12, I had a great experience during the interchange program. The master classes are so incredible. What impressed and inspired me the most were the tool of prototyping introduced by Luca Hackwill and explained further by Frank facilitators. Prototyping provides a set of approaches for testing our ideas before too many resources get used. It reminds of my childhood when I created a mock-up of the clock using plasticine. It's very similar with the prototyping process in the real design of an app. All of the keynote speakers shared their experiences in their career and they were so insightful.",
            "We were assigned to different groups with participants from different institutions. Our team picked the question <q>What might be the different ways people can get jobs and earn a living?</q> as our theme. We focused the problem about lack of access of information for working abroad and we invented an app to provide a platform for people to share their experiences, search their ideal job and get working-oversea service from us. We try to inspire people to explore overseas jobs, encourage them to change the way they used to work and help them find the ideal job to earn a living.",
            "During the program, every member was so engaging and participating in our project. I am so glad to have them as my team members and to have Julia as our mentor, who gave us many support and guidance during the project. We should be proud of ourselves for being one of the most productive, supportive and cohesive teams.",
            "The three-week Interchange program inspires our innovative thinking. As our team name, IGNITE, states, we want to use our innovative ideas to ignite people's lives, starting from our app."
        ],
        author: "Menghan Zhang, University of Sydney",
        brand: "By Menghan Zhang, University of Sydney",
        authorImage: require("assets/img/blogs/13/menghan.jpg"),
        backgroundImgUrl: require("assets/img/blogs/13/background.jpg")
    },
    "18": {
        id: "18",
        title: "My journey with INTERCHANGE 2021",
        paragraphs: [
            "My journey with <b>INTERCHANGE 2021</b>, a 5-week virtual entrepreneurship challenge program for university students across Australia, is almost about to come to an end now. A lot has happened in these past 5 weeks and these were the highlights of my journey:",
            "<ul><li><b>Virtually met some wonderful teammates</b> from different universities across Australia and a very kind mentor, whom I probably would not have gotten to known even if I was physically present in Sydney. So, thanks to INTERCHANGE for bringing us all together!</li></ul>",
            "<ul><li><b>Completed courses on entrepreneurship and teamwork,</b> both of which were extremely useful in building my foundations for the program.</li></ul>",
            "<ul><li><b>Raised up my hand in front of almost 200 people</b> (in Zoom of course) to ask questions, that too to incredibly successful keynote speakers in the start-up space. This was one of my biggest achievements in the program because I often start trembling when it comes to speaking in front of so many people.</li></ul>",
            "<ul><li><b>Learnt lots of lessons from Rob Morris,</b> a truly inspiring tech investor and entrepreneur, around building and running your own start-up. Some of these include <q>Build companies that you are passionate about</q>, <q>Treat people well, especially when it's hard</q>, <q>Do things that customers will pay for</q> and <q>Do the hard stuff early and often</q>.</li></ul>",
            "<ul><li><b>Got inspired by Yvonne Power,</b> an art history graduate who turned into the co-founder of one of the world's most technologically advanced microgrid operators. Her journey shows that no obstacle is big enough to interrupt your dreams. It makes me proud to see how much women are achieving great success as entrepreneurs, inspiring other women like me to work towards the same.</li></ul>",
            "<ul><li><b>Learnt how to create an engaging video pitch</b> using the tool <b>'Powtoon'</b> instead of the usual standard presentation slides. This process of knowing nothing about creating videos to becoming a pro at visualising concepts was a fun ride. I would highly recommend anyone who wants to grab the attention of their audience to use Powtoon, it is so flexible and full of creative options.</li></ul>",
            "Overall, INTERCHANGE has taught me a lot and I will forever be grateful to FRANK for organising this program. I am looking forward to the finale in two days, super excited to see the wonderful ideas all the teams have come up with!!",
            "Thanks for reading my article :)",
            "#interchange #frank"
        ],
        author: "Khyati Zaveri, University of New South Wales",
        brand: "By Khyati Zaveri, University of New South Wales",
        authorImage: require("assets/img/blogs/14/khyati.jpg"),
        backgroundImgUrl: require("assets/img/blogs/14/background.jpg")
    },
    "19": {
        id: "19",
        title: "The Journey of Interchange 2021",
        paragraphs: [
            "First, I would like to thank the University of Sydney and the Careers Centre to give me this opportunity to participate in this program. Also, thank to FRANK and the members for providing such a platform where we can work with people from different cultures and backgrounds and put our expertise and ideas together to solve a real-world problem.",
            "Everything started on the 13th July 2021. It was the first time we got together and met the facilitators and other participants. The overview of the program and the incentive for us to participate actively was really captivating. I was excited to start on this journey to meet new people and to tackle a meaningful world problem as a team. The idea that we may even put this solution to work in real life is just wonderful. Whether this happens or not, the first step is important.",
            "So, the first workshop kicked off greatly. Being an introverted person, I would shy away from people and be hesitated to have the spotlight on me. It was my goal to get myself out of comfort zone when I decided to apply for this program. Yet, I wasn't able to raise my hand to talk in front of so many people. I am grateful to the facilitators for encouraging us and telling us that it is okay to hesitate and not put the hands up. <q>We are all in this together.</q>",
            "<center><b><i><q>Nobody does meaningful thing on their own</q></i></b></center>",
            "<center><b><i><q>Comfort is the death of joy</q></i></b></center>",
            "<center><b><i>-Rob Morris</i></b></center>",
            "Receiving all these encouragements and knowing that if I don't make a change, my participation will be worthless and I will waste this opportunity. With this in mind, I had my first experience of raising my virtual hand! It felt nice and gave me more confidence in being more proactive and engage in discussions.",
            "Apart from that, I had the chance to learn many new concepts from different guest speakers. Here, I would like to thank all of them for their sharing of their own experiences and their expertise. There were many areas that I wasn't familiar with, but now I can say that I have some general idea and have more tools in my pocket to create different projects with a stronger state of mind. Everything was all so inspiring. The idea that people out there just try out different stuffs that they are passionate about and they are not afraid to fail. They keep following their heart and improve their ideas and get the final product up and running through multiple failures and tests. This just gives more reason to not abandon things easily.",
            "I consider myself to be patient, easy-going and determined. I know multiple languages and have the ability to learn things quickly and work as a team. Still, through this program, I have acquired and build on more essential skills. From this program, I can now confidently say that I have a stronger communication, interpersonal skills and teamwork by working in my team as someone who provides ideas, facilitates communication among team members and use my knowledge in video editing to bring the project together. I can now problem solve and look at it in different aspects.",
            "Finally, we have almost reached the end of the program. I am really grateful to my team members. I am so glad to have such great team members where we can learn from each other. I had learned to compromise when it comes to working together and meeting each other providing the different time zones we are in. We were all so understanding and formed the solution at high-efficiency. Everyone just had each other's back.",
            "I hope that our product may be a reality in the future by helping the Earth gets better through more sustainable actions and behaviour. To also have a better living environment for us humans and animals. It is really satisfying to see all things work out and be presented in front of you. Whatever the result will be, the process is the most rewarding.",
            "Again, thank you so much to everyone. I believe I haven't covered on every details that I encountered. Though, they will be an asset to me in my future endeavour. Thank you!"
        ],
        author: "Ying Ying Ida Xiao, University of Sydney",
        brand: "By Ying Ying Ida Xiao, University of Sydney",
        authorImage: require("assets/img/blogs/15/ying.jpg"),
        backgroundImgUrl: require("assets/img/blogs/15/background.jpg")
    }
};
