import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPadComments from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-comments.jpg";
import presentationiPadTable from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-table.jpg";
import ipad from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem md={4}>
                        <div className={classes.sectionDescription}>
                            <h3 className={classes.title}>
                                Technology Enabled Solutions
                            </h3>
                            <h6 className={classes.description}>
                                Entrepreneurship is A Mindset
                            </h6>
                            <h5 className={classes.description}>
                                Student ingenuity and creativity will be
                                unleashed to solve real-world problems that are
                                responsive, tested and technology enabled.
                            </h5>
                        </div>
                    </GridItem>
                    <GridItem md={7} className={classes.mlAuto}>
                        <div className={classes.imageContainer}>
                            <div className={classes.animeAreaImg}>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <img
                                        src={presentationiPadComments}
                                        alt="iPad comments"
                                        className={classes.areaImg}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className={classes.animeInfoImg}>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <img
                                        src={presentationiPadTable}
                                        alt="iPad table"
                                        className={classes.infoImg}
                                    />
                                </ScrollAnimation>
                            </div>
                            <img
                                className={classes.ipadImg}
                                src={ipad}
                                alt="iPad"
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
