import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import SectionText from "components/Blog/SectionText/SectionText.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import { BlogHeader } from "components/Blog/BlogHeader/BlogHeader.js";
import { BlogFooter } from "components/Blog/BlogFooter/BlogFooter";
import { blogpostsdata } from "../BlogPostsData/BlogPostsData.js";
import PropTypes from "prop-types";

const useStyles = makeStyles(blogPostPageStyle);

export default function BlogPostPage(props) {
    const possibleBlogIds = Object.keys(blogpostsdata);
    const blogId = possibleBlogIds.includes(props.match.params.blogId)
        ? props.match.params.blogId
        : 1;
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    const {
        title,
        subtitle,
        subheading,
        paragraphs,
        quote,
        author,
        backgroundImgUrl,
        brand,
        authorImage
    } = blogpostsdata[blogId];
    return (
        <div>
            <Header
                brand={brand}
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "info"
                }}
            />
            <BlogHeader
                title={title}
                subtitle={subtitle}
                backgroundImgUrl={backgroundImgUrl}
            />
            <div className={classes.main}>
                <div className={classes.container}>
                    <SectionText
                        title={subheading}
                        paragraphs={paragraphs}
                        quote={quote}
                        author={author}
                        authorImage={authorImage}
                    />
                </div>
            </div>
            <BlogFooter />
        </div>
    );
}

BlogPostPage.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    paragraphs: PropTypes.arrayOf(PropTypes.string),
    quote: PropTypes.string,
    author: PropTypes.string,
    backgroundImgUrl: PropTypes.any.isRequired,
    brand: PropTypes.string.isRequired,
    authorImage: PropTypes.any.isRequired
};
